%ul_as_ui_base {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
}

%ul_marquee_base {
  @extend %ul_as_ui_base;
  height: 100%;
  // overflow: hidden;

  @include themify(
    (dark: (
      color: $white-09
    )),
    (light: (
      color: $black-09
    ))
  );

  li {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin: 0 0 2rem;
    font-size: 1.6rem;
    font-weight: 100;
    line-height: 2.5rem;
    opacity: 0;

    @include transitions(top, opacity);
    @include no-select();

    &.active {
      top: 0%;
      opacity: 1;
    }
  }
  @include mq(tablet) {
    height: 20rem;
  }
}

%ul_options_base {
  position: relative;
  z-index: 99;

  @extend %ul_as_ui_base;
  @include no-select();
  pointer-events: none;

  > li {
    display: inline-block;
    vertical-align: top;

    &:nth-child(1) {
      padding-right: 1rem;

      @include icon-button {
        display: inline-block;
        height: 2rem;
        font-size: 1.1rem;
        font-weight: 200;

        pointer-events: all;

        &:before {
          margin: 0 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;
        }
      }
    }
    &:nth-child(2) {
      padding-left: 1rem;
      pointer-events: all;
    }
  }

  &:not(.active) {

    > li:nth-child(1) button {
      @include themify(
        (dark: (
          color: $white-06
        )),
        (light: (
          color: $black-06
        ))
      );
      &:before {
        content: '+';
      }
      &:hover {
        @include themify(
          (dark: (
            color: $white-1
          )),
          (light: (
            color: $black-1
          ))
        );
      }
    }
  }

  &.active {
    > li:nth-child(1) > button {
      color: $ux_success_color;
      &:before {
        content: '-';
      }
    }
  }

  @include y-scale-selector('> li:nth-child(2)');
}

ul {
  &.marquee {
    @extend %ul_marquee_base;
  }
  &.options {
    @extend %ul_options_base;
  }
}

%main_section_article_ul {
  ul {
    margin: 1.4rem 0 1rem 1.2rem;
    padding: 0;
    > li {
      margin: 0 0 1rem;
      font-size: $site_base_font_size;
      font-weight: 300;
      @include themify(
        (dark: (
          color: $white-09
        )),
        (light: (
          color: $black-09
        ))
      );
    }
  }
}
