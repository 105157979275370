@import 'div';
@import 'label';

calendar {
  position: relative;
  z-index: 0;
  display: inline-block;

  @extend %calendar_label;
  @extend %calendar_main;

  @include mq(tablet) {
    margin: 0 2rem;
  }
}

form.inline calendar {
  > label {
    margin-left: 0;
  }
  > div {
    margin-top: 0.8rem;
    margin-left: 0.5rem;
  }
}
