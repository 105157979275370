%label_contextual_menu_expanded {
  &:before {
    @include themify(
      (dark: (
        color: $white-08
      )),
      (light: (
        color: $black-08
      ))
    );
  }
  > menu {
    opacity: 1;
    @include transform(scaleY(1));
  }
}

label {
  &.contextual-menu {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    min-width: 10rem;
    min-height: 3.3rem;
    overflow: visible !important;

    &:before {
      @extend .icon;
      @extend %icon-ellipsis-v;
      display: block;
      margin: 0.8rem 1rem 0 0;
      font-size: 1.4rem;
      text-align: right;

      @include themify(
        (dark: (
          color: $white-05
        )),
        (light: (
          color: $black-05
        ))
      );
    }

    > menu {
      position: absolute;
      top: 80%;
      right: 0;
      z-index: 1;
      opacity: 0;
      @include transform-origin(top);
      @include transform(scaleY(0));
      @include transitions(transform, opacity);
    }

    &.active {
      @extend %label_contextual_menu_expanded;
    }
  }
}
