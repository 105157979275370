$input_min_width: 18rem !default;

// base input
input {
  position: relative;
  height: $form_element_size;

  &:not([type="checkbox"]):not([type="radio"]):not([type="color"]):not([type="range"]):not([type="file"]):not([type="hidden"]) {
    @extend %elements_base;
    min-width: $input_min_width;
    padding: 0 0.7rem 0 1rem;
  }
}

@include input-placeholder {
  font-family: $site_font_family;
  font-size: 1.2rem;
  @include themify(
    (dark: (
      color: $ux_form_control_placeholder_text_color
    )),
    (light: (
      color: $black-05
    ))
  );
}

input[type=radio] {
  @extend %checkbox_radio_elements;
}

input[type=checkbox] {
  @extend %checkbox_radio_elements;
}

input[type=color] {
  cursor: pointer;
  width: $form_element_size;
  margin: 0;
  padding: 0;
  padding-inline: 0 !important;
  padding-block: 0 !important;
  @include border(1px solid, false);
  @include border-radius($form_element_border_radius);
  @extend %elements_themed;
}

input[type=file] {
  cursor: pointer;
  width: $form_element_min_width;
  margin: 0;
  padding: 0;
  padding-inline: 0 !important;
  @include appearance(none);

  background-repeat: no-repeat;
  background-position-x: $form_element_icon_background_position_x;
  background-position-y: $form_element_icon_background_position_y;

  @include themify(
    (dark: (
      color: $ux_form_control_text_color
    )),
    (light: (
      color: $black-08
    ))
  );

  @include themify(
    (dark: (
      background-image: url(svg-encode(svg-factory($svg-upload, $white-03)))
    )),
    (light: (
      background-image: url(svg-encode(svg-factory($svg-upload, $black-03)))
    ))
  );

  &:not([value=""]) {
    @include themify(
      (dark: (
        background-image: url(svg-encode(svg-factory($svg-upload, $white-08)))
      )),
      (light: (
        background-image: url(svg-encode(svg-factory($svg-upload, $black-08)))
      ))
    );
  }

  &:hover {
    @include themify(
      (dark: (
        background-image: url(svg-encode(svg-factory($svg-upload, $white-05)))
      )),
      (light: (
        background-image: url(svg-encode(svg-factory($svg-upload, $black-05)))
      ))
    );
  }
}

input[type=range] {
  @extend %elements_base;
  @include appearance(none);
  cursor: pointer;
  padding-top: 1.6rem !important;
}

input[type=button],
input[type=reset],
input[type=submit] {
  display: inline-block !important;
  vertical-align: middle;
  width: unset !important;
  padding-top: 0 !important;
  padding-right: 1.4rem !important;
  padding-left: 1.4rem !important;
  line-height: normal;
}

input {
  &:not([type=checkbox]):not([type=radio]):not([type=color]):not([type=file]) {
    @include input-element-widths();
  }
}

@include ApplyInputIcon($svg-calendar, date);
@include ApplyInputIcon($svg-calendar, datetime-local);
@include ApplyInputIcon($svg-envelope, email);
@include ApplyInputIcon($svg-calendar, month);
@include ApplyInputIcon($svg-search, search);
@include ApplyInputIcon($svg-phone, tel);
@include ApplyInputIcon($svg-clock, time);
@include ApplyInputIcon($svg-globe, url);
@include ApplyInputIcon($svg-calendar, week);
@include ApplyInputIcon($svg-lock, password);
@include ApplyInputIcon($svg-spinner, number);
@include ApplyInputIcon($svg-spinner, range);

