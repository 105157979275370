menu {
  display: inline-block;
  margin: 0.5rem;
  padding: 0;
  overflow: hidden;

  @include border-radius($form_element_border_radius);

  @include box-shadow-themed(
    0 0 0.2rem 0.1rem $black-02,
    0 0 0.2rem 0.1rem $white-02
  );

  menuitem {
    cursor: pointer;
    display: block;
    white-space: nowrap;
    border-top-width: 0.1rem;
    border-top-style: solid;

    &:first-child {
      border-top-width: 0;
    }

    color: $black-1;
    background-color: $white-1;
    border-top-color: #666;

    &:before, &:after {
      display: inline-block;
      vertical-align: middle;
    }

    &:before {
      @extend .icon;
      content: '';
      width: 3.5rem;
      text-align: center;

      @include themify(
        (dark: (
          color: $black-05
        )),
        (light: (
          color: $white-05
        ))
      );
    }

    &:after {
      content: attr(label);
      margin: 1.3rem 2rem 1.2rem 0;
      padding: 0;
      font-size: 1.4rem;
      font-weight: 300;
      text-transform: capitalize;
    }

    &[icon="copy"]:before { content: $icon-copy; }
    &[icon="edit"]:before { content: $icon-edit; }
    &[icon="delete"]:before { content: $icon-trash; }
    &[icon="view_access"]:before { content: $icon-eye; }

  }

}
