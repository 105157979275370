%header_hgroup {
  margin: 0.5rem 0 0 1.5rem;
  @include no-select();

  h1 {
    a {
      position: relative;
    }
  }
  h2 {
    line-height: normal;
    margin: 0;
    @include no-select();
    pointer-events: none;

    @include themify(
      (dark: (
        color: $white-1
      )),
      (light: (
        color: $black-1
      ))
    );
  }

  @include mq(desktop, laptop) {
    $h_height: 8rem;
    $h_height_half: 4rem;

    h1, h2 {
      display: inline-block;
      vertical-align: middle;
    }
    h1 {
      margin-right: 1.4rem;
      > a {
        display: inline-block;
        vertical-align: middle;
        height: $h_height;
        padding: 1rem 0 0;
        img.logo {
          height: $h_height_half;
        }
      }
    }

    > h2 {
      height: $h_height;
      font-size: 2rem;
      font-weight: 500;
      padding: 0.75rem 0;
    }
  }

  @include mq(tablet) {
    $h_height: 2.7rem;

    h1 {
      margin-top: 0.6rem;
      margin-right: 0.8rem;
      a {
        height: $h_height;
        img.logo {
          height: $h_height;
        }
      }
    }

    h2 {
      margin: 0;
      font-size: 1.6rem;
      padding: 0.8rem 0;
    }
  }
}
