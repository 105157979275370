$flags_path: '../../../flags/';

$iso_2_codes: (
  AD: 'AD',
  AE: 'AE',
  AF: 'AF',
  AG: 'AG',
  AI: 'AI',
  AL: 'AL',
  AM: 'AM',
  AN: 'AN',
  AO: 'AO',
  AR: 'AR',
  AS: 'AS',
  AT: 'AT',
  AU: 'AU',
  AW: 'AW',
  AX: 'AX',
  AZ: 'AZ',
  BA: 'BA',
  BB: 'BB',
  BD: 'BD',
  BE: 'BE',
  BF: 'BF',
  BG: 'BG',
  BH: 'BH',
  BI: 'BI',
  BJ: 'BJ',
  BL: 'BL',
  BM: 'BM',
  BN: 'BN',
  BO: 'BO',
  BR: 'BR',
  BS: 'BS',
  BT: 'BT',
  BV: 'BV',
  BW: 'BW',
  BY: 'BY',
  BZ: 'BZ',
  CA: 'CA',
  CC: 'CC',
  CD: 'CD',
  CF: 'CF',
  CG: 'CG',
  CH: 'CH',
  CI: 'CI',
  CK: 'CK',
  CL: 'CL',
  CM: 'CM',
  CN: 'CN',
  CO: 'CO',
  CR: 'CR',
  CU: 'CU',
  CV: 'CV',
  CX: 'CX',
  CY: 'CY',
  CZ: 'CZ',
  DE: 'DE',
  DJ: 'DJ',
  DK: 'DK',
  DM: 'DM',
  DO: 'DO',
  DZ: 'DZ',
  EC: 'EC',
  EE: 'EE',
  EG: 'EG',
  EH: 'EH',
  ER: 'ER',
  ES: 'ES',
  ET: 'ET',
  FI: 'FI',
  FJ: 'FJ',
  FK: 'FK',
  FM: 'FM',
  FO: 'FO',
  FR: 'FR',
  GA: 'GA',
  GB: 'GB',
  GD: 'GD',
  GE: 'GE',
  GF: 'GF',
  GG: 'GG',
  GH: 'GH',
  GI: 'GI',
  GL: 'GL',
  GM: 'GM',
  GN: 'GN',
  GP: 'GP',
  GQ: 'GQ',
  GR: 'GR',
  GS: 'GS',
  GT: 'GT',
  GU: 'GU',
  GW: 'GW',
  GY: 'GY',
  HK: 'HK',
  HM: 'HM',
  HN: 'HN',
  HR: 'HR',
  HT: 'HT',
  HU: 'HU',
  ID: 'ID',
  IE: 'IE',
  IL: 'IL',
  IM: 'IM',
  IN: 'IN',
  IO: 'IO',
  IQ: 'IQ',
  IR: 'IR',
  IS: 'IS',
  IT: 'IT',
  JE: 'JE',
  JM: 'JM',
  JO: 'JO',
  JP: 'JP',
  KE: 'KE',
  KG: 'KG',
  KH: 'KH',
  KI: 'KI',
  KM: 'KM',
  KN: 'KN',
  KP: 'KP',
  KR: 'KR',
  KW: 'KW',
  KY: 'KY',
  KZ: 'KZ',
  LA: 'LA',
  LB: 'LB',
  LC: 'LC',
  LI: 'LI',
  LK: 'LK',
  LR: 'LR',
  LS: 'LS',
  LT: 'LT',
  LU: 'LU',
  LV: 'LV',
  LY: 'LY',
  MA: 'MA',
  MC: 'MC',
  MD: 'MD',
  ME: 'ME',
  MF: 'MF',
  MG: 'MG',
  MH: 'MH',
  MK: 'MK',
  ML: 'ML',
  MM: 'MM',
  MN: 'MN',
  MO: 'MO',
  MP: 'MP',
  MQ: 'MQ',
  MR: 'MR',
  MS: 'MS',
  MT: 'MT',
  MU: 'MU',
  MV: 'MV',
  MW: 'MW',
  MX: 'MX',
  MY: 'MY',
  MZ: 'MZ',
  NA: 'NA',
  NC: 'NC',
  NE: 'NE',
  NF: 'NF',
  NG: 'NG',
  NI: 'NI',
  NL: 'NL',
  NO: 'NO',
  NP: 'NP',
  NR: 'NR',
  NU: 'NU',
  NZ: 'NZ',
  OM: 'OM',
  PA: 'PA',
  PE: 'PE',
  PF: 'PF',
  PG: 'PG',
  PH: 'PH',
  PK: 'PK',
  PL: 'PL',
  PM: 'PM',
  PN: 'PN',
  PR: 'PR',
  PS: 'PS',
  PT: 'PT',
  PW: 'PW',
  PY: 'PY',
  QA: 'QA',
  RE: 'RE',
  RO: 'RO',
  RS: 'RS',
  RU: 'RU',
  RW: 'RW',
  SA: 'SA',
  SB: 'SB',
  SC: 'SC',
  SD: 'SD',
  SE: 'SE',
  SG: 'SG',
  SH: 'SH',
  SI: 'SI',
  SJ: 'SJ',
  SK: 'SK',
  SL: 'SL',
  SM: 'SM',
  SN: 'SN',
  SO: 'SO',
  SR: 'SR',
  SS: 'SS',
  ST: 'ST',
  SV: 'SV',
  SY: 'SY',
  SZ: 'SZ',
  TC: 'TC',
  TD: 'TD',
  TF: 'TF',
  TG: 'TG',
  TH: 'TH',
  TJ: 'TJ',
  TK: 'TK',
  TL: 'TL',
  TM: 'TM',
  TN: 'TN',
  TO: 'TO',
  TP: 'TP',
  TR: 'TR',
  TT: 'TT',
  TV: 'TV',
  TW: 'TW',
  TZ: 'TZ',
  UA: 'UA',
  UG: 'UG',
  UM: 'UM',
  US: 'US',
  UY: 'UY',
  UZ: 'UZ',
  VA: 'VA',
  VC: 'VC',
  VE: 'VE',
  VG: 'VG',
  VI: 'VI',
  VN: 'VN',
  VU: 'VU',
  WF: 'WF',
  WS: 'WS',
  XK: 'XK',
  YE: 'YE',
  YT: 'YT',
  ZA: 'ZA',
  ZM: 'ZM',
  ZW: 'ZW'
);

$iso_3_codes: (
	ARE: 'AE',
	AFG: 'AF',
	AGO: 'AO',
	ALB: 'AL',
	ARG: 'AR',
	ARM: 'AM',
	ATF: 'TF',
	AUS: 'AU',
	AUT: 'AT',
	AZE: 'AZ',
	BDI: 'BI',
	BEL: 'BE',
	BEN: 'BJ',
	BFA: 'BF',
	BGD: 'BD',
	BGR: 'BG',
	BHS: 'BS',
	BIH: 'BA',
	BLR: 'BY',
	BLZ: 'BZ',
	BOL: 'BO',
	BRA: 'BR',
	BRN: 'BN',
	BTN: 'BT',
	BWA: 'BW',
	CAF: 'CF',
	CAN: 'CA',
	CHE: 'CH',
	CHL: 'CL',
	CIV: 'CI',
	CN: 'CN',
	CMR: 'CM',
	COD: 'CD',
	COG: 'CG',
	COL: 'CO',
	CRI: 'CR',
	CUB: 'CU',
	CYP: 'CY',
	CZE: 'CZ',
	DEU: 'DE',
	DJI: 'DJ',
	DNK: 'DK',
	DOM: 'DO',
	DZA: 'DZ',
	ECU: 'EC',
	EGY: 'EG',
	ERI: 'ER',
	ESP: 'ES',
	EST: 'EE',
	ETH: 'ET',
	FIN: 'FI',
	FJI: 'FJ',
	FLK: 'FK',
	FRA: 'FR',
	GAB: 'GA',
	GBR: 'GB',
	GEO: 'GE',
	GHA: 'GH',
	GIN: 'GN',
	GMB: 'GM',
	GNB: 'GW',
	GNQ: 'GQ',
	GRC: 'GR',
	GRL: 'GL',
	GTM: 'GT',
	GUY: 'GY',
	HND: 'HN',
	HRV: 'HR',
	HTI: 'HT',
	HUN: 'HU',
	IDN: 'ID',
	IND: 'IN',
	IRL: 'IE',
	IRN: 'IR',
	IRQ: 'IQ',
	ISL: 'IS',
	ISR: 'IL',
	ITA: 'IT',
	JAM: 'JM',
	JOR: 'JO',
	JPN: 'JP',
	KEN: 'KE',
	KGZ: 'KG',
	KHM: 'KH',
	KWT: 'KW',
	KZ: 'KZ',
	LAO: 'LA',
	LBN: 'LB',
	LBR: 'LR',
	LBY: 'LY',
	LKA: 'LK',
	LSO: 'LS',
	LTU: 'LT',
	LUX: 'LU',
	LVA: 'LV',
	MAR: 'MA',
	MDA: 'MD',
	MDG: 'MG',
	MEX: 'MX',
	MKD: 'MK',
	MLI: 'ML',
	MMR: 'MM',
	MNE: 'ME',
	MNG: 'MN',
	MOZ: 'MZ',
	MRT: 'MR',
	MWI: 'MW',
	MYS: 'MY',
	NAM: 'NA',
	NCL: 'NC',
	NER: 'NE',
	NGA: 'NG',
	NIC: 'NI',
	NLD: 'NL',
	NOR: 'NO',
	NPL: 'NP',
	NZL: 'NZ',
	OMN: 'OM',
	PAK: 'PK',
	PAN: 'PA',
	PER: 'PE',
	PHL: 'PH',
	PNG: 'PG',
	PRI: 'PR',
	PRT: 'PT',
	PRY: 'PY',
	PSE: 'PS',
	QAT: 'QA',
	ROU: 'RO',
	RUS: 'RU',
	RWA: 'RW',
	SAU: 'SA',
	SDN: 'SD',
	SE: 'SE',
	SGP: 'SG',
	SEN: 'SN',
	SLB: 'SB',
	SLE: 'SL',
	SLV: 'SV',
	SOM: 'SO',
	SR: 'SR',
	SRB: 'RS',
	SVK: 'SK',
	SVN: 'SI',
	SWZ: 'SZ',
	SYR: 'SY',
	TCD: 'TD',
	TGO: 'TG',
	THA: 'TH',
	TJK: 'TJ',
	TKM: 'TM',
	TLS: 'TL',
	TTO: 'TT',
	TUN: 'TN',
	TUR: 'TR',
	TWN: 'TW',
	TZA: 'TZ',
	UY: 'UY',
	UGA: 'UG',
	USA: 'US',
	UZB: 'UZ',
	VEN: 'VE',
	VNM: 'VN',
	VUT: 'VU',
  XXK: 'XK',
	YEM: 'YE',
	ZAF: 'ZA',
	ZMB: 'ZM',
	ZWE: 'ZW'
);

@mixin make-flags($parent) {
  @each $code, $file in $iso_3_codes {
    #{$parent} {
      &.#{$code} {
        cursor: url('#{$flags_path}#{$file}.png'), help;
      }
    }
  }
}

@mixin make-flag-backgrounds($parent) {
  %country_background_images {
    background-repeat: no-repeat;
    background-position: 1rem;
    background-size: 1.6rem 1.1rem;
  }
  @each $code, $file in $iso_2_codes {
    #{$parent} {
      &.#{$code} {
        @extend %country_background_images;
        background-image: url('#{$flags_path}#{$file}.png');
      }
    }
  }
}

@mixin make-data-flag-backgrounds($parent) {
  %country_background_images {
    background-repeat: no-repeat;
    background-position: left 0.8rem top 1.2rem;
    background-size: 1.6rem 1.1rem;
  }
  @each $code, $file in $iso_2_codes {
    #{$parent} {
      &[data-value="#{$code}"] {
        @extend %country_background_images;
        background-image: url('#{$flags_path}#{$file}.png');
      }
    }
  }
}
