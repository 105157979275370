@mixin root {
  #root {
    @content;
  }
}

@mixin zindex-children($base: 0, $length: 9, $inverse: false) {
  @for $i from 1 through $length {
    $j: if($inverse, ($length + 1) - $i, ($i - 1));
    &:nth-child(#{$i}) {
      z-index: #{$base + $j};
    }
  }
}

@mixin direct-container($selector) {
  > #{$selector},
  > * > #{$selector} {
    @content;
  }
}

@mixin input-element-widths($selector: false, $minwidth: unset) {
  @if $selector {
    &[class*="width-"] {
      > #{$selector} {
        min-width: $minwidth !important;
      }
    }
    &.width-8 { > #{$selector} { width: 8rem !important; }}
    &.width-9 { > #{$selector} { width: 9rem !important; }}
    &.width-10 { > #{$selector} { width: 10rem !important; }}
    &.width-11 { > #{$selector} { width: 11rem !important; }}
    &.width-12 { > #{$selector} { width: 12rem !important; }}
    &.width-13 { > #{$selector} { width: 13rem !important; }}
    &.width-14 { > #{$selector} { width: 14rem !important; }}
    &.width-15 { > #{$selector} { width: 15rem !important; }}
    &.width-16 { > #{$selector} { width: 16rem !important; }}
    &.width-17 { > #{$selector} { width: 17rem !important; }}
    &.width-18 { > #{$selector} { width: 18rem !important; }}
    &.width-19 { > #{$selector} { width: 19rem !important; }}
    &.width-20 { > #{$selector} { width: 20rem !important; }}
    &.full-width { > #{$selector} { width: 100% !important;min-width: $minwidth !important; }}
    &.auto-width { > #{$selector} { width: auto !important;min-width: $minwidth !important; }}
  } @else {
    &[class*="width-"] {
      min-width: $minwidth !important;
    }
    &.width-8 { width: 8rem !important; }
    &.width-9 { width: 9rem !important; }
    &.width-10 { width: 10rem !important; }
    &.width-11 { width: 11rem !important; }
    &.width-12 { width: 12rem !important; }
    &.width-13 { width: 13rem !important; }
    &.width-14 { width: 14rem !important; }
    &.width-15 { width: 15rem !important; }
    &.width-16 { width: 16rem !important; }
    &.width-17 { width: 17rem !important; }
    &.width-18 { width: 18rem !important; }
    &.width-19 { width: 19rem !important; }
    &.width-20 { width: 20rem !important; }
    &.full-width { width: 100% !important;min-width: $minwidth !important; }
    &.auto-width { width: auto !important;min-width: $minwidth !important; }
  }
}
