@import '~highlight.js/scss/androidstudio';
$hjls-font-size: 1.4rem;
.hljs {
  font-size: $hjls-font-size;
  .hljs-tag {
    font-size: inherit;
    span {
      font-size: inherit;
    }
  }
}

pre {
  > code {
    display: block;
    padding: 2rem !important;
    font-size: 1.2rem;
    overflow: hidden !important;;
  }
}
