@mixin animation($params...) {
  -webkit-animation: $params;
     -moz-animation: $params;
          animation: $params;
}

@mixin transitions($params...) {
  @for $i from 1 through length($params) {
    $param: str-split(nth($params, $i), ' ');
    $changed: false;
    @if length($param) == 1 {
      $changed: true;
      $param: append($param, $default_transition_duration);
    }
    @if length($param) == 2 {
      $changed: true;
      $param: append($param, $default_transition_timing_function);
    }
    @if length($param) == 3 {
      $changed: true;
      $param: append($param, $default_transition_delay);
    }
    @if $changed {
      $params: set-nth($params, $i, $param);
    }
  }

  -webkit-transition: $params;
     -moz-transition: $params;
      -ms-transition: $params;
       -o-transition: $params;
          transition: $params;
}

@mixin transition-property($properties...) {
  -webkit-transition-property: $properties;
     -moz-transition-property: $properties;
      -ms-transition-property: $properties;
       -o-transition-property: $properties;
          transition-property: $properties;
}

@mixin transition-duration($duration...) {
  @if length($duration) == 0 {
    $duration: $default_transition_duration;
  }
  -webkit-transition-duration: $duration;
     -moz-transition-duration: $duration;
      -ms-transition-duration: $duration;
       -o-transition-duration: $duration;
          transition-duration: $duration;
}

@mixin transition-delay($delay...) {
  @if length($delay) == 0 {
    $delay: $default_transition_delay;
  }
  -webkit-transition-delay: $delay;
     -moz-transition-delay: $delay;
      -ms-transition-delay: $delay;
       -o-transition-delay: $delay;
          transition-delay: $delay;
}

@mixin transition-timing-function($timing...) {
  @if length($timing) == 0 {
    $timing: $default_transition_timing_function;
  }
  -webkit-transition-timing-function: $timing;
     -moz-transition-timing-function: $timing;
      -ms-transition-timing-function: $timing;
       -o-transition-timing-function: $timing;
          transition-timing-function: $timing;
}

@mixin transform($params...) {
  -webkit-transform: $params;
     -moz-transform: $params;
      -ms-transform: $params;
       -o-transform: $params;
          transform: $params;
}

@mixin transform-origin($params...) {
  -webkit-transform-origin: $params;
     -moz-transform-origin: $params;
      -ms-transform-origin: $params;
       -o-transform-origin: $params;
          transform-origin: $params;
}

@mixin backface-visibility($val) {
  -webkit-backface-visibility: $val;
      -moz-backface-visiblity: $val;
      -ms-backface-visibility: $val;
          backface-visibility: $val;
}

@mixin box-sizing($type) {
  -webkit-box-sizing: $type;
     -moz-box-sizing: $type;
          box-sizing: $type;
}

@mixin box-shadow($params...) {
	-webkit-box-shadow: $params;
		 -moz-box-shadow: $params;
					box-shadow: $params;
}

@mixin boxshadow($params...) {
	-webkit-box-shadow: $params;
		 -moz-box-shadow: $params;
					box-shadow: $params;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}


@mixin no-select {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
       -ms-touch-action: none;
            user-select: none;
}

@mixin auto-select($important: null) {
  -webkit-touch-callout: default $important;
    -webkit-user-select: auto $important;
     -khtml-user-select: auto $important;
       -moz-user-select: auto $important;
        -ms-user-select: auto $important;
       -ms-touch-action: auto $important;
            user-select: auto $important;
}

@mixin all-select($important: null) {
  -webkit-touch-callout: default $important;
    -webkit-user-select: text $important;
    -khtml-user-select: all $important;
      -moz-user-select: text $important;
        -ms-user-select: text $important;
      -ms-touch-action: auto $important;
            user-select: text $important;
}

@mixin blur($radius) {
  -webkit-filter: blur($radius);
    -moz-filter: blur($radius);
      -ms-filter: blur($radius);
      -o-filter: blur($radius);
          filter: blur($radius);
}

@mixin transform-scale3d($x:0, $y:0, $z:0) {
  -webkit-transform: scale3d($x,$y,$z);
     -moz-transform: scale3d($x,$y,$z);
     -ms-transition: scale3d($x,$y,$z);
      -o-transition: scale3d($x,$y,$z);
          transform: scale3d($x,$y,$z);
}

@mixin transform-translate3d($x:0, $y:0, $z:0) {
  -webkit-transform: translate3d($x,$y,$z);
     -moz-transform: translate3d($x,$y,$z);
     -ms-transition: translate3d($x,$y,$z);
      -o-transition: translate3d($x,$y,$z);
          transform: translate3d($x,$y,$z);
}


@mixin linear-gradient($fromColor, $toColor) {
  background-color: $toColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor));
  background-image: -webkit-linear-gradient(top, $fromColor, $toColor);
  background-image:    -moz-linear-gradient(top, $fromColor, $toColor);
  background-image:     -ms-linear-gradient(top, $fromColor, $toColor);
  background-image:      -o-linear-gradient(top, $fromColor, $toColor);
  background-image:         linear-gradient(top, $fromColor, $toColor);
}

@mixin appearance($param) {
  -webkit-appearance: $param;
  -moz-appearance: $param;
  appearance: $param;
}
