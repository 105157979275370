/*
  Defaults
*/
$site_font_size: 10px !default;
$site_font_family: 'Inter', "Open Sans", sans-serif;
$site_header_height: 6.2rem;
$site_header_icon_width: 6.2rem;
$site_main_nav_width: 25rem !default;
$site_footer_height: 5rem;
$site_footer_height_tablet: 8rem;
$site_grid_buffer: 1rem;
$site_vertical_buffer: 2rem;
$site_laptop_main_nav_width: 5.5rem;
$site_base_font-size: 1.4rem;

$site_nav_anchor_height: 5rem;
$site_nav_anchor_padding: 1.6rem;


/*
  Transitions
*/
$default_transition_duration: 500ms;
$default_transition_delay: 0s;
$default_transition_timing_function: ease;


/*
  Forms
*/
$form_fieldset_title_buffer: 30rem;
$form_fieldset_input_width: 40rem;
$form_element_size: 3.3rem;
$form_element_size_half: 1.65rem;
$form_element_indent: 2.8rem;
$form_element_buffer: 2rem;
$form_element_border_radius: 0.2rem;
$form_element_icon_background_position_x: left -0.2rem;
$form_element_icon_background_position_y: center;

/*
  Media Queries
*/
$l0: "only screen and (min-width : 769px) and (max-width : 1280px)";
$l1: "screen and (min-device-width : 1024px) and (max-device-width : 1280px) and (-webkit-min-device-pixel-ratio : 1)";
$l2: "screen and (min-device-width : 1024px) and (max-device-width : 1280px) and (-webkit-min-device-pixel-ratio : 2) and (min-resolution: 192dpi)";

$t0: "only screen and (max-width : 768px)";
$t1: "screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio : 1)";
$t2: "screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio : 1)";
$t3: "screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio : 1)";
$t4: "screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio : 2)";
$t5: "screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio : 2)";
$t6: "screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio : 2)";
$t7: "screen and (min-device-width : 834px) and (max-device-width : 1112px) and (-webkit-min-device-pixel-ratio : 2)";
$t8: "screen and (min-device-width : 834px) and (max-device-width : 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio : 2)";
$t9: "screen and (min-device-width : 1112px) and (max-device-width : 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio : 2)";
$t10: "screen and (min-device-width : 1024px) and (max-device-width : 1266px) and (-webkit-min-device-pixel-ratio : 2)";
$t11: "screen and (min-device-width : 1024px) and (max-device-width : 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio : 2)";
$t12: "screen and (min-device-width : 1266px) and (max-device-width : 1266px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio : 2)";

$media-query-desktop: "only screen and (min-width : 1281px)";
$media-query-laptop : $l0, $l1, $l2;
$media-query-tablet : $t0, $t1, $t2, $t3, $t4, $t5, $t6, $t7, $t8, $t9, $t10, $t11, $t12;
