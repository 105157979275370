$form_group_well_default_padding: 1.2rem 1.8rem 1.6rem !default;
$form_group_well_tight_padding: 1rem 1rem 0 !default;
$form_group_well_tighter_padding: 0.6rem 0.6rem 0 !default;

%form_group_base {
  position: relative;
  @include zindex-children(100, 20, true);
  overflow: visible;

  > .input-label {
    button, li, span {
      @include no-select();
    }
    > samp {
      display: block;
      margin: 1rem 0 0;
      font-family: $site_font_family;
      font-size: 1.2rem;
      font-weight: 300;
      @include themify(
        (dark: (
          color: $white-06
        )),
        (light: (
          color: $black-06
        ))
      );
    }
  }

  > h3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
  }
  > h4 { margin-bottom: 0.3rem !important;padding-bottom: 0 !important; }
  p {
    margin-top: 0.5rem;
  }
  > .row {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  > header {
    > h3 {
      margin: 0 !important;
      padding: 0 !important;
    }
    > h4 {
      margin: 0 !important;
      padding: 0 !important;
    }
    > h5 {
      margin: 1rem 0 2rem !important;
      padding: 0 !important;
    }
    margin-bottom: 1rem;
  }
}

%form_save_group {
  text-align: right;
  > .input-label {
    display: inline-block;
    vertical-align: middle;
    min-width: unset !important;
    margin: 0 0 0 1.2rem;
    padding: 0;
  }
}

%form_group_well {
  padding: $form_group_well_default_padding;
  overflow: visible;
  @include no-select();
  @include box-shadow-themed(none !important, none !important);

  @include themify(
    (dark: (
      background-color: $white-001
    )),
    (light: (
      background-color: $black-001
    ))
  );
}

%form_group_tight {
  margin-bottom: 1rem;
  padding: $form_group_well_tight_padding;
  > label.input-label {
    margin-bottom: 0.8rem;
  }
}

%form_group_tighter {
  @extend %form_group_tight;
  margin-bottom: 0.6rem;
  padding: $form_group_well_tighter_padding;
  > label.input-label {
    margin-bottom: 0.6rem;
  }
}

%form_group_well_tight_tighter {
  > label.input-label {
    &:first-child {
      padding-top: 0;
    }
  }
}

%form_group_full_width {
  @include mq(desktop, laptop) {
    width: 100%;
  }
}

%form_group_additions {
  &.well {
    @extend %form_group_well;
  }

  &.tight {
    @extend %form_group_tight;
  }

  &.tighter {
    @extend %form_group_tighter;
  }

  &.full-width {
    @extend %form_group_full_width;
  }

  &.well.tight {
    @extend %form_group_well_tight_tighter;
  }

  &.well.tighter {
    @extend %form_group_well_tight_tighter;
  }

  &.save-group {
    @extend %form_save_group;
  }
}

%form_group_input_label {
  > label.input-label {
    &:not(.labelled) {
      > i.tooltip {
        top: -0.5rem;
      }
    }
    &.labelled {
      > i.tooltip {
        top: 1.4rem;
      }
    }
  }
}

%form_group_input_nowrap {
  &.nowrap {
    white-space: nowrap;
    > * {
      white-space: normal;
    }
  }
}

/**
 * Form Group
 */
.form-group {
  @extend %form_group_base;
  @extend %form_group_additions;
  @extend %form_group_input_nowrap;
  @extend %form_group_input_label;
}

/**
 * Form Group Order
 */
.form-group-order {
  @include zindex-children(100, 20, true);
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1.4rem 5rem 0.6rem 2.5rem;
  background-color: $black-01;

  @include box-shadow-themed(
    0 0 0.3rem 0 $black-05 inset,
    0 0 0.3rem $black-02 inset,
    0 0 0 0.1rem $white-03 inset,
    0 0 0 0.1rem $black-03 inset,
  );

  @include border-radius($form_element_border_radius);

  > .half-btn-label {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    z-index: 1;
    width: $form_element_size;
    margin: 0;
    padding: 0;
  }

  > kbd {
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 1;
    display: block;
    margin: 0;
    padding: 0.3rem 0.4rem 0.1rem 0.5rem;
    font-size: 1.1rem;
    color: $white-05;
    line-height: normal;
    text-align: center;
    border-width: 0 0.1rem 0.1rem 0;
    border-style: none solid solid none;
    background-color: transparent;
    border-color: transparent $white-02 $white-02 transparent;
    @include border-radius(0 0 $form_element_border_radius 0);
    @include boxshadow(0 0 0.3rem 0 $black-05 inset);
  }

  > .builder-buttons-label {
    position: absolute;
    top: 1rem;
    right: 5rem;
    z-index: 1;
    display: block !important;

    + .form-group {
      margin-right: 8rem;
    }
  }
}

/**
 * Form Group Inline
 */
.form-group-inline {
  @extend %form_group_base;
  @extend %form_group_additions;
  @extend %form_group_input_nowrap;

  &:not(.well):not(.tight):not(.tighter) {
    > .form-group {
      padding-left: 0;
      > label.input-label {
        &:not(.labelled) {
          padding-top: 0;
        }
      }
    }
  }

  > .form-group {
    display: inline-block;
    vertical-align: top;
    margin-right: $form_element_buffer;

    &.float-right {
      margin-right: 0;

      &:not(.labelled) {
        margin-top: 0;
      }

      &.labelled {
        margin-top: 2.6rem;
      }
    }

  }

  > .input-label {
    margin-left: 1rem;
  }

  &.valign-top {
    > .form-group {
      vertical-align: top;
    }
  }
  &.valign-bottom {
    > .form-group {
      vertical-align: bottom;
    }
  }
}

/**
 * Form Group Row
 */
.form-group-row {
  @extend %form_group_base;
  @extend %form_group_additions;
  @extend %form_group_input_nowrap;

  padding-right: 0;
  padding-left: 0;
  @include mq(desktop, laptop) {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
  @include mq(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

  > label.input-label {
    @include mq(desktop, laptop) {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -moz-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-box-align: stretch;
      -moz-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin: 0;
      padding: 0;
      > dfn {
        @include flex-wrap-element(auto, 30%);
        padding: 0.8rem 1rem 0 1rem;
        text-align: right;
        > span.help {
          margin-right: 0;
          margin-left: 1rem;
        }
      }
      > input {
        @include flex-wrap-element(auto, 20%, 30%);
      }
      > samp {
        @include flex-wrap-element(auto, 20%, 30%);
        margin: 0 3rem 0 1rem;
      }
      > output {
        top: 100%;
        left: 20%;
      }
      > kbd {
        margin-top: 0;
        margin-right: 0.5rem;
      }

      > span.help {
        margin-top: 0 !important;
      }

      > i.tooltip {
        top: 0;
        right: 0rem;
      }
    }

    @include mq(tablet) {
      display: block;
      > kbd {
        top: 3rem !important;
      }
    }
  }
}

 .form-group.row {
  @extend .row;

  > .form-group {
    @extend .col;
    @extend .col-auto;
  }
}

form {
  > .form-group {
    margin: $form_element_buffer 0;
  }
}

.form-group {
  .row {
    > .col,
    > [class*="col-"] {
      padding-left: 0;

      > .form-group {
        > label.input-label {
          width: 100%;
        }
      }
    }
  }
}


.form-group.debugger {
  dl {
    @include all-select(!important);

    * {
      @include all-select(!important);
    }

    dt, dd {
      font-size: 1.4rem !important;
      line-height: normal;
      span {
        font-size: inherit;
      }
    }

    dt.dim {
      @include themify(
        (dark: (
          color: $white-05
        )),
        (light: (
          color: $black-05
        ))
      );
    }

    &:not(.table) {
      display: block;
      margin: 0;
      dt, dd {
        margin: 0;
      }
      dt {
        padding: 0;

      }
      dd {
        padding: 0;

        pre {
          margin: 0;
          padding: 0;
          font-size: 1.2rem;
          font-weight: 100;
        }
      }
    }
  }

  .form-group-inline {
    .form-group {
      vertical-align: top;
    }
    padding-bottom: 1rem !important;
  }
  .form-group {
    padding-bottom: 1rem !important;

    &.html {

      .hljs {
        display: inline-block;
        -webkit-text-size-adjust: none;
      }
    }
  }
}
