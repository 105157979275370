%main_aside_laptop_base {
  @include mq(laptop) {

    > nav {
      width: 5.8rem;
      overflow: visible;

      > mask,
      > div.mask {
        display: none;
      }

      > ul {
        > li {
          > a {
            overflow: visible;
            > span {
              position: absolute;
              top: 50%;
              left: 115%;
              z-index: 1000;
              min-width: 10rem;
              height: 2.4rem;
              margin: -1.2rem 0 0;
              padding: 0.4rem 1.5rem 0.6rem;
              font-size: 1.1rem;
              font-weight: 200;
              opacity: 0;
              pointer-events: none;

              @include no-select();
              @include transitions(opacity);
              @include border-radius(0.2rem);

              @include themify(
                (dark: (
                  color: $white-05,
                  background-color: #404040
                )),
                (light: (
                  color: $black-05,
                  background-color: #9f9f9f
                ))
              );

              &:before {
                content: '';
                position: absolute;
                top: 0.475rem;
                left: -0.6rem;
                display: block;
                width: 0;
                height: 0;
                border-width: 0.7rem 0.7rem 0.7rem 0;
                border-style: solid solid solid none;
                border-color: transparent;
                @include themify(
                  (dark: (
                    border-right-color: #404040
                  )),
                  (light: (
                    border-right-color: #9f9f9f
                  ))
                );
              }
            }
            &:hover {
              > span {
                opacity: 1;
              }
            }
          }
        }
      }

      li {
        position: relative;
        > ol {
          position: absolute;
          top: 0;
          left: 100%;
          z-index: 1;
          min-width: 15rem;
          opacity: 0;
          pointer-events: none;
          @include no-select();
          @include transitions(opacity);
        }

        &:hover {
          > ol {
            opacity: 1;
            pointer-events: auto;
            @include auto-select();
            @include box-shadow-themed(
              0 0 0 0.1rem $black-02 inset,
              0 0 0 0.1rem $black-01 inset,
              0.2rem 0.2rem 0.3rem 0 $black-03,
              0.2rem 0.2rem 0.3rem 0 $black-01
            );
          }
        }

        > a {
          &.expanded {
            border-left-color: $ux_primary_color;
            @include themify(
              (dark: (
                color: $white-08,
                background-color: $ux_accent_base_color
              )),
              (light: (
                color: $black-08,
                background-color: #dfdfdf
              ))
            );
          }
        }
      }

      ol {
        @include boxshadow(0 0 0.2rem 0.1rem $black-01);

        @include themify(
          (dark: (
            background-color: $ux_accent_base_color
          )),
          (light: (
            background-color: #dfdfdf
          ))
        );

        > li {
          > a {
            padding-top: $site_nav_anchor_padding;
            padding-right: 2rem;
            padding-bottom: $site_nav_anchor_padding;
            padding-left: 2rem;

            &.expandable {
              &:after {
                @include iconz();
                content: $icon-angle-right;
                position: absolute;
                top: $site_nav_anchor_padding + 0.3rem;
                right: 2rem;
              }
            }
            &:hover {
              @include themify(
                (dark: (
                  background-color: $dark-form-input-background-color
                )),
                (light: (
                  background-color: $light-form-input-background-color
                ))
              );
            }
          }
        }
      }
    }

    + section {
      text-align: left;

      > header {
        max-width: unset;
        margin-right: 0;
        margin-left: 0;
      }

      > *:not(header) {
        display: block;
        margin-right: 3rem !important;
        margin-left: 3rem !important;
      }
    }
  }
}
