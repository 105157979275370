@import 'anchor';
@import 'blockquote';
@import 'button';
@import 'code';
@import 'details';
@import 'div';
@import 'dl';
@import 'em';
@import 'fieldset';
@import 'hN';
@import 'hgroup';
@import 'hr';
@import 'i';
@import 'label';
@import 'menu';
@import 'nav';
@import 'ol';
@import 'p';
@import 'span';
@import 'ul';

h1, th, summary {
  @include no-select();
}

abbr, acronym, article, b, bdo, big, cite, code, details, div, dl, dt, dd, dfn, em, h1, h2, h3, h4, i, img, kbd, map, mark, output, object, p, q, samp, small, span, strong, sub, sup, summary, td, th, time, tt, var {
  cursor: default;
}

output, var, mark {
  font-weight: 300;
  font-style: normal;
  color: inherit;
  background-color: unset;
}

mark {
  line-height: normal;
}

small {
  font-size: 1rem;
  font-weight: 200;
}

img {
  max-width: 100%;
  height: auto;
}
