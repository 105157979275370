$grid-gutter-width: 0;
$grid-row-margin: 0 !default;

$grid-col-padding: $site_grid_buffer !default;
$grid-breakpoints: (
  xs: 0,
  sm: 240px,
  md: 480px,
  lg: 1000px
);
$container-max-widths: (
  sm: 240px,
  md: 480px,
  lg: 1000px
);

$button_default_padding: 0 1rem !default;
$button_default_font_size: 1.4rem !default;

%button_base {
  line-height: normal;
  @include border-radius(0.3rem);

  &[disabled] {
    @extend %button_disabled;
  }
}

%button_disabled {
  cursor: not-allowed;
  color: $white-1;
  border-color: $ux_dim_text_color;
  background-color: $ux_dim_text_color;
}

.well {
  margin: 0;
  padding: 0.8rem 1.5rem;
  @include border(1px solid, false);
  @include border-radius(0.5rem);
  @include box-shadow-themed(
    0 0 0.4rem 0 $white-01 inset, 0 0 0.4rem 0 $black-01 inset,
    0 0 0 0.1rem $white-01, 0 0 0 0.1rem $black-01
  );

  @include themify(
    (dark: (
      border-color: $ux_border_color
    )),
    (light: (
      border-color: $black-01
    ))
  );
}

.row {
  margin-left: $grid-row-margin;
  margin-right: $grid-row-margin !important;
}

.row {
  .col, [class*="col-"] {
    padding-left: $grid-col-padding !important;
    padding-right: $grid-col-padding !important;
  }
}

.btn {
  @extend %button_base;
  vertical-align: unset;

  &:focus {
    @include box-shadow-themed(0 0 0.3rem 0 $white-01, 0 0 0.3rem 0 $black-01);
  }

  &:not(.btn-sm):not(.btn-md) {
    height: $form_element_size;
  }

  &:not(.btn-sm):not(.btn-md):not(.up):not(.down) {
    font-size: $button_default_font_size !important;
    padding: $button_default_padding !important;
  }

  &.btn-outline-light {
    background-color: transparent;
  }

  &.btn-sm {
    font-size: 1.1rem !important;
    font-weight: 400;
    padding-left: 0.6rem !important;
    padding-right: 0.6rem !important;
  }

  &.btn-md {
    height: 2.8rem;
    font-size: 1.2rem !important;
    font-weight: 400;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
  }

  &.btn-primary:not([disabled]) {
    @extend %palette_primary;

    &:hover {
      background-color: $ux_dark_brand_color;
      border-color: $ux_dark_brand_color;
    }
  }
  
  &.btn-success:not([disabled]) {
    @extend %palette_primary;

    &:hover {
      background-color: $ux_dark_brand_color;
      border-color: $ux_dark_brand_color;
    }
  }

  &.btn-default {
    @include themify(
      (dark: (
        color: $white-08,
        border-color: $ux_border_color
      )),
      (light: (
        color: $black-08,
        border-color: $black-03
      ))
    );

    &:not([disabled]):hover {
      @include themify(
        (dark: (
          border-color: $ux_border_color
        )),
        (light: (
          border-color: $black-05
        ))
      );
    }
  }

  &:not([disabled]) {
    &:active {
      @include box-shadow-themed(0 0 0.3rem 0 $white-05, 0 0 0.3rem 0 $black-05);
    }
  }

  &[disabled] {
    cursor: not-allowed !important;
  }
}

.btn:disabled { pointer-events: auto !important; border-color: $ux_dim_text_color; }
.btn.disabled { pointer-events: auto !important; border-color: $ux_dim_text_color; }
fieldset:disabled .btn { pointer-events: auto !important; border-color: $ux_dim_text_color; }

label + .btn-group {
  vertical-align: unset;
}

.pull-right {
  text-align: right;
}

.pull-md-right {
  @extend .pull-right;
  @include mq(tablet) {
    text-align: left !important;
  }
}

.btn-outline-default {
  @include themify(
    (dark: (
      color: $white-08,
      border-color: $ux_border_color
    )),
    (light: (
      color: $black-08,
      border-color: $black-04
    ))
  );
  &:not(:disabled) {
    &:hover {
      @include themify(
        (dark: (
          color: $white-1,
          border-color: $ux_border_color,
          background-color: $ux_accent_base_color,
        )),
        (light: (
          color: $black-1,
          border-color: $black-05,
          background-color: $black-005
        ))
      );
    }
  }
}

.badge {
  font-size: 1.1rem;
  font-weight: 600;

  &:not(.badge-outline) {
    &.badge-primary { color: $white-1; background-color: $ux_primary_color !important; }
    &.badge-success { color: $ux_page_base_color; background-color: $ux_success_color !important; }
    &.badge-danger { color: $white-1; background-color: $ux_danger_color !important; }
    &.badge-warning { color: $ux_page_base_color; background-color: $ux_warning_color !important; }
    &.badge-info { color: $ux_page_base_color; background-color: $ux_info_color !important; }
    &.badge-trivial { color: $ux_page_base_color; background-color: $ux_neutral_color !important; }
  }
}

.badge-disabled { font-weight: 400;opacity: 0.6; }

.badge-default {
  background-color: transparent;
  @include themify(
    (dark: (
      color: $white-05,
    )),
    (light: (
      color: $black-05
    ))
  );
}

.badge-outline {
  background-color: transparent;
  border-width: 1px !important;
  border-style: solid !important;

  &.badge-primary { 
    @include themify(
      (dark: (
        color: $ux_primary_color
      )),
      (light: (
        color: $ux_primary_medium_color
      ))
    );
  }
  &.badge-success { 
    @include themify(
      (dark: (
        color: $ux_success_color
      )),
      (light: (
        color: tint($ux_success_color, 50%)
      ))
    );
  }
  &.badge-danger { 
    @include themify(
      (dark: (
        color: $ux_warning_text_color,
        border-color: $ux_warning_text_color
      )),
      (light: (
        color: $ux_danger_color
      ))
    );
  }
  &.badge-warning { 
    @include themify(
      (dark: (
        color: $ux_warning_color
      )),
      (light: (
        color: tint($ux_warning_color, 50%)
      ))
    );
  }
  &.badge-info { 
    @include themify(
      (dark: (
        color: $ux_info_color
      )),
      (light: (
        color: tint($ux_info_color, 50%)
      ))
    );
   }
  &.badge-trivial { 
    @include themify(
      (dark: (
        color: $ux_neutral_color
      )),
      (light: (
        color: tint($ux_neutral_color, 50%)
      ))
    );
  }
}

.form-group {
  header.row {
    margin-top: 2rem;
  }
}

@include root {
  > main > section > form > article,
  > main > section > article {
    header.row {
      padding-bottom: 0;
      > h3.col {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
    &.row {
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      > header.col {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      > .col {
        &:first-child {
          padding-left: 0.5rem;
        }
        &:last-child {
          padding-right: 0.5rem;
        }
      }
    }
  }
}

table.striped {
  caption {

    span {
      &.badge {
        display: inline-block;
        vertical-align: middle;
        height: $form_element_size;
        margin: 0.5rem 0 0.5rem 0.8rem;
        padding: 0.6rem 0.2rem 0;
        font-size: 1.4rem;
        @include border-radius(0.5rem);

        &.badge-outline {
          > var, > mark {
            display: inline-block;
            vertical-align: top;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;
            line-height: normal;
          }

          > var {
            margin-left: 0.8rem;
            font-weight: 400;
          }

          > mark {
            height: $form_element_size - 2 * 0.7rem;
            min-width: 2.2rem;
            margin-left: 0.8rem;
            margin-right: 0.6rem;
            font-weight: 500;
            text-align: center;
            @include border-radius(0.3rem);
          }

          &.badge-default { > mark {
            color: $white-1;
            @include themify(
              (dark: (
                background-color: $white-02
              )),
              (light: (
                background-color: $black-02
              ))
            );
          }}

          &.badge-primary { > mark { color: $white-1; background-color: $ux_primary_color; }}
          &.badge-success { > mark { color: $white-1; background-color: $ux_success_color; }}
          &.badge-danger { > mark { color: $white-1; background-color: $ux_danger_color; }}
          &.badge-warning { > mark { color: $black-1; background-color: $ux_warning_color; }}
          &.badge-info { > mark { color: $white-1; background-color: $ux_info_color; }}
          &.badge-trivial { > mark { color: $white-1; background-color: $ux_neutral_color; }}
        }
      }
    }
  }
}

.card {
  @include themify(
    (dark: (
      background-color: rgba(128, 128, 128, 0.1)
    )),
    (light: (
      background-color: rgba(128, 128, 128, 0.1)
    ))
  );
}

.breadcrumb {
  color: $ux_primary_color;
  font-weight: bolder;
  font-size: 150%;

  a {
    @include themify(
      (
        dark: (
          color: $ux_primary_color,
        ),
      ),
      (
        light: (
          color: $ux_primary_color,
        ),
      )
    );
  }

  @include themify(
    (
      dark: (
        background-color: rgba(128, 128, 128, 0),
      ),
    ),
    (
      light: (
        background-color: rgba(128, 128, 128, 0),
      ),
    )
  );
}
