%builder_buttons_base {
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 0.5rem;
  padding: 0;

  > button {
    display: inline-block;
    vertical-align: middle;
    width: $form_element_size;
    height: $form_element_size;
    margin: 0 0 0 0.5rem;
    padding: 0;
    @include border(1px solid, false);
    @include border-radius($form_element_border_radius);
    @include themify(
      (dark: (
        color: $white-05,
        border-color: $ux_border_color
      )),
      (light: (
        color: $black-05,
        border-color: $black-03
      ))
    );

    &:before {
      @extend .icon;
      font-size: 1.2rem;
      font-weight: 100;
      color: inherit;
      text-align: center;
    }

    &.plus {
      &:before {
        content: $icon-plus;
      }
    }
    &.minus {
      &:before {
        content: $icon-minus;
      }
    }
  }
}

label {
  &.builder-buttons-label {
    @extend %builder_buttons_base;
  }

  &.half-btn-label {
    display: inline-block;
    vertical-align: top;
    width: $form_element_size;
    height: $form_element_size_half !important;
    background-color: $black-005;

    @include border-radius($form_element_border_radius);
    @include boxshadow(0 0 0.3rem 0 $black-05 inset);

    > button {
      position: relative;
      display: block;
      width: $form_element_size;
      height: 1.6rem !important;
      margin: 0;
      padding: 0 !important;

      &:before {
        @extend .icon;
        position: absolute;
        top: 0.2rem;
        display: block;
        width: 100%;
        text-align: center;
      }

      &.up:before { @extend %icon-angle-up; }
      &.down:before { @extend %icon-angle-down; }

      &:hover {
        color: $white-1;
        background-color: $ux_info_color;
      }
    }

    > button + button {
      margin-top: 0.1rem;
    }
  }
}

button {
  > span {
    font-size: inherit !important;
  }
}

span.crements {
  position: relative;
  display: block;

  > button {
    cursor: pointer;
    position: absolute;
    left: 0.1rem;
    z-index: 1;
    width: 2.4rem;
    height: 1.55rem;
    margin: 0;
    padding: 0;
    display: inline;
    @include border(0 none transparent, false);
    background-color: transparent;
    &.increment {
      top: 0.1rem;
      @include border-radius(0.3rem 0 0 0);
    }
    &.decrement {
      bottom: 0.1rem;
      @include border-radius(0 0 0 0.3rem);
    }
  }

}


// button.crements {
//   position: absolute;
//   left: 0.1rem;
//   z-index: 1;
//   width: 2.4rem;
//   height: 1.5rem;
//   margin: 0;
//   padding: 0;
//   display: inline-block;
//   @include border(0 none transparent, false);
//   background-color: rgba(204, 0, 0, 0.5);

//   &.increment {
//     top: 0.7rem;
//     @include border-radius(0.2rem 0 0 0);
//   }
//   &.decrement {
//     top: 2.3rem;
//     @include border-radius(0 0 0 0.2rem);
//   }
// }
