%auth0_lock {
  #auth_lock_container {
    .auth0-lock-tabs-container {
      display: none;
    }

    .auth0-lock-confirmation {
      background-color: $ux_page_base_color;
    }
    .auth0-lock-view-content,
    .auth0-lock-content-wrapper {
      overflow: hidden;
      text-align: left;
    }

    .auth0-lock-cred-pane {
      @include mq(desktop) {
        max-width: 40rem;
      }
      @include mq(laptop) {
        max-width: 34rem;
      }
      @include mq(tablet) {
        max-width: unset;
      }

      @include themify(
        (dark: (
          color: $black-08,
          background-color: $white-01
        )),
        (light: (
          color: $black-08,
          background-color: $black-01
        ))
      );
    }

    .auth0-lock-name,
    .auth0-lock-alternative {
      line-height: normal;
      font-size: 2.2rem !important;
      text-align: center !important;
      @include no-select();
    }

    .auth0-lock-error-invalid-hint {
      font-size: 1rem;
    }

    .auth0-lock-cred-pane-internal-wrapper {
      @include mq(tablet) {
        height: unset;
      }
    }

    .auth0-lock-icon-box {
      top: 0.9rem !important;
      bottom: unset !important;
      left: 1.3rem !important;
      background-color: $black-01;
    }

    .auth0-lock-input-wrap {
      display: block;
      height: 3.3rem;
      padding: 0 0 0 3.8rem;
      background-image: none;
      border-width: 1px;
      border-style: solid;
      @include border-radius(0.2rem);

      @include themify(
        (dark: (
          background-color: $black-01,
          border-color: $ux_border_color
        )),
        (light: (
          background-color: $black-01,
          border-color: $black-02
        ))
      );
    }

    .auth0-lock-input {
      right: unset !important;
      height: 3.3rem;
      width: 100% !important;
      padding: 0 0 0 1rem !important;
      font-size: $site_base_font_size;
      line-height: normal;
      border-width: 0 0 0 1px;
      border-style: none none none solid;
      background: none !important;
      box-shadow: none !important;
      @include border-radius(0 $form_element_border_radius $form_element_border_radius 0);
      @include themify(
        (dark: (
          color: $white-08,
          border-color: $ux_border_color,
          background-color: $white-01
        )),
        (light: (
          color: $black-08,
          border-color: $black-01,
          background-color: $black-005
        ))
      );

      &:focus {
        @include themify(
          (dark: (
            color: $white-1,
            background-color: $white-01,
          )),
          (light: (
            color: $black-1,
            background-color: $black-005,
          ))
        );
      }
    }

    .auth0-loading {
      border-color: $ux_primary_color_40 $ux_primary_color_40 $ux_primary_color_20 $ux_primary_color_20;
    }

    .auth0-lock-blur-support {
      display: none;
    }

    .auth0-lock-header-logo {
      display: none;
    }

    .auth0-lock.auth0-lock .auth0-lock-header {
      @include box-sizing(content-box !important);
    }

    .auth0-lock-header {
      margin-bottom: 2rem;
      @include themify(
        (dark: (
          color: $white-1,
          background-color: $white-02
        )),
        (light: (
          color: $black-1,
          background-color: $black-01
        ))
      );
    }

    .auth0-lock-center {
      @include mq(dektop, laptop) {
        padding-top: 0 !important;
      }
    }

    .auth0-lock {
      font-family: inherit;
    }

    .auth0-lock {
      .auth0-lock-widget {
        width: 100%;
      }
    }

    .auth0-lock-header-welcome {
      text-align: center;
    }

    .auth0-lock.auth0-lock.auth0-lock-opened-in-frame {
      width: 100%;
    }

    .auth0-lock-submit {
      height: 8rem;
      .auth0-label-submit {
        height: unset;
        font-size: 1.6rem;
        line-height: normal;
      }
    }

    a.auth0-lock-alternative-link {
      @include themify(
        (dark: (
          color: $white-08
        )),
        (light: (
          color: $black-08
        ))
      );
    }

    .auth0-lock-form {
      padding-top: 0;
      p {
        color: #ddd;
        text-align: left;
      }
    }

    .auth0-sso-notice {
      padding-left: 0.5rem;
      @include themify(
        (dark: (
          color: $white-08
        )),
        (light: (
          color: $black-08
        ))
      );
    }

    .auth0-sso-notice-container {
      margin: 0 !important;
      .auth0-lock-icon-box {
        top: unset !important;
        left: unset !important;
      }
    }
  }
}
