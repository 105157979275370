%scrollable_ordered_list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 20rem;

  li {
    padding: 0;
    text-align: left;
    border-width: 0 0 0.1rem;
    border-style: none none solid;

    @include themify(
      (dark: (
        color: #8c8c8c,
        border-color: transparent transparent $white-01
      )),
      (light: (
        color: #8c8c8c,
        border-color: transparent transparent $black-01
      ))
    );

    > a, > span {
      display: block;
      font-size: 1.3rem;
    }

    > span {
      padding: 1.4rem 1rem 1.6rem;
      text-align: center;
    }

    > a {
      position: relative;
      margin: 0;
      padding: 1.4rem 1.4rem 1.6rem 5rem;
      text-align: left;
      white-space: normal;
      text-transform: none;

      h5, p {
        cursor: pointer;
      }

      h5 {
        margin-top: 0;
        font-size: $site_base_font_size;
      }
      p, time {
        margin-bottom: 0;
        font-size: inherit;
      }
      p {
        @include themify(
          (dark: (
            color: $white-06
          )),
          (light: (
            color: $black-06
          ))
        );
      }
      time {
        @include themify(
          (dark: (
            color: $white-04
          )),
          (light: (
            color: $black-04
          ))
        );
      }

      &.note {
        margin-left: 2rem;
        &:before {
          @include iconz();
          content: "";
          position: absolute;
          top: 1rem;
          left: 0;
          width: 3rem;
          height: 3rem;
          text-align: center;
        }

        &.error { &:before {
          content: $icon-exclamation-triangle;
          font-size: 3rem;
          color: $ux_danger_color;
        }}
        &.success { &:before {
          content: $icon-check;
          line-height: 3rem;
          background-color: $ux_success_color;
          @include border-radius(1.6rem);
        }}
        &.warning { &:before {
          content: $icon-exclamation-triangle;
          font-size: 2.4rem;
          color: $ux_warning_color;
        }}
        &.info { &:before {
          content: $icon-info-circle;
          font-size: 2.4rem;
          color: $ux_info_color;
        }}
      }
    }
  }
}
%scrollable_list {
  ol {
    @extend %scrollable_ordered_list;
  }
}

%widget_ordered_list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: list-item;
    margin: 0.5rem 0 1rem;
    padding: 0;

    &.has-selector {
      max-height: 7rem !important;
    }
    &.inline {
      > label {
        vertical-align: top !important;
      }
    }

    &.vignette {
      padding: 0 2rem;
      @include border(1px solid transparent, false);
      @include border-radius(0.3rem);
      @include themify(
        (dark: (
          background-color: $white-001,
          border-color: $ux_border_color
        )),
        (light: (
          background-color: $black-001,
          border-color: $black-01
        ))
      );
    }
  }
}

ol {
  &.scrollable-list {
    @extend %scrollable_ordered_list;
  }
  &.widget-list {
    @extend %widget_ordered_list;
  }
}

%main_section_article_ol {
  ol {
    margin: 1.4rem 0 1rem 1.8rem;
    padding: 0;
    > li {
      font-size: 1.3rem;
      font-weight: 300;

      padding-bottom: 0.5rem;
      padding-left: 1rem;
      @include themify(
        (dark: (
          color: $white-09
        )),
        (light: (
          color: $black-09
        ))
      );
      > * {
        font-size: inherit;
      }
    }
  }
}

