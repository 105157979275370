%header_nav_menu_notifications {
  font-size: 1.2rem !important;
  > a {
    @extend %notifications_icon;
    text-transform: capitalize;
  }
  p {
    margin: 0 !important;
  }
  > span {
    &.counter-badge {
      position: absolute;
      top: 0.5rem;
      left: 3rem;
      z-index: 1;
      min-width: 1.6rem;
      height: 1.6rem;
      padding: 0.1rem 0.6rem 0;
      font-size: 1.1rem;
      font-weight: 400;
      color: $white-1;
      line-height: normal;
      text-indent: 0;
      text-align: center;
      background-color: $ux_info_color;
      @include border-radius(0.9rem);

      @include mq(tablet) {
        top: 1.8rem;
        left: 12rem;
      }
    }
  }
  @extend %definition_list_notifications;

  &:not(.active) {
    dl > dt > a { height: 0; }
  }
}

%header_nav_menu_divider {
  @extend %definition_list_base;
  display: none;
  @include mq(tablet) {
    display: block;
  }
}

%header_nav_menu_user { > a { @extend %user_anchor; }}


%header_nav_menu_tools {
  font-size: 1.2rem !important;
  > a {
    @extend %tools_icon;
    text-transform: capitalize;
  }

  > div {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 35rem;
    max-width: 42rem;
    margin: 0;
    padding: 0;

    border-width: 0 0.1rem 0.1rem;
    border-style: none solid solid;

    @include border-radius(0 0 $form_element_border_radius $form_element_border_radius);
    @include boxshadow(0.5rem 0 1rem $black-01);

    @include themify(
      (dark: (
        background-color: $dark-header-nav-background-color,
        border-color: transparent $white-01 $white-01
      )),
      (light: (
        background-color: $light-header-nav-background-color,
        border-color: transparent $black-01 $black-01
      ))
    );

    @include mq(tablet) {
      position: relative;
      top: unset;
      right: unset;
      z-index: unset;
    }

    > p.warning {
      margin: 0;
      padding: 1rem;
      color: $ux_warning_color;
      white-space: normal;
      line-height: 2;
      @include border(1px solid $ux_warning_color, false);
      @include border-radius($form_element_border_radius);
    }
  }
}
