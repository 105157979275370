@import 'control';

%main_section_gallery_header {
  > header {
    margin: 0 !important;

    h2, h3, h4 {
      margin-top: 0;
      padding: 0;
      text-align: center;
      white-space: nowrap;
      > * {
        white-space: normal;
        text-align: left;
      }
    }

    h2 {
      font-size: 1.6rem;
      font-weight: 300;
      margin-bottom: 0.3rem;
    }

    h3 {
      font-size: 1.4rem;
      font-weight: 300;
      margin-bottom: 0.3rem;
    }

    h4 {
      font-size: 1rem;
      font-weight: 200;
    }
  }
}

%main_section_gallery_blockquote {
  > blockquote {
    margin: 0 !important;
    padding: 0.5rem !important;
    border: 0 solid transparent !important;

    > div {
      min-height: 15rem;
      overflow: hidden;

      &.bg {
        @include themify(
          (dark: (
            background-color: $black-08
          )),
          (light: (
            background-color: $white-08
          ))
        );
      }
    }
  }
}

%main_section_gallery_overrides {
  &.packets-total {
    @include gallery-overrides($packets-total);
  }
  &.packets-clean {
    @include gallery-overrides($packets-clean);
  }
  &.bits-aggregate {
    @include gallery-overrides($bits-aggregate);
  }
  &.bits-mitigated {
    @include gallery-overrides($bits-mitigated);
  }
  &.bits-clean {
    @include gallery-overrides($bits-clean);
  }
  &.bits-egress {
    @include gallery-overrides($bits-egress);
  }
}

%main_section_gallery_base {
  max-width: unset !important;
  list-style: none;
  margin-top: 1rem;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: auto;
  padding: 0;

  @extend %flex-wrap-container;

  > li {
    margin: 0;
    padding: 0;

    @include transitions(width);
    @include flex-wrap-element(16%, unset, 16rem);

    > article {
      height: 18rem;
      margin: 0.5rem;
      padding: 0;

      svg {
        cursor: crosshair;
      }

      @include themify(
        (dark: (
          color: $white-08
        )),
        (light: (
          color: $black-08
        ))
      );

      @extend %main_section_gallery_header;
      @extend %main_section_gallery_blockquote;
      @extend %main_section_gallery_overrides;

      &:hover {
        @include themify(
          (dark: (
            color: $white-1
          )),
          (light: (
            color: $black-1
          ))
        );
      }
    }

    @include mq(laptop) {
      @include flex-wrap-element(16%, unset, 16rem);
    }

    @include mq(tablet) {
      @include flex-wrap-element(100%, 28rem, unset);
      > article {
        height: 22rem;
      }
    }
  }

  @extend %control_gallery;
}
