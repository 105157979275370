hr {
  @include flex-wrap-element(97%);

  @include border(0 none transparent, false);
  color: transparent;
  height: 1px;

  @include themify(
    (dark: (
      background-color: $white-01
    )),
    (light: (
      background-color: $black-01
    ))
  );

  @include mq(laptop) {
    @include flex-wrap-element(94%);
  }
  @include mq(tablet) {
    @include flex-wrap-element(94%);
  }
}
