@keyframes do-vertical-expand {
  0% {
    @include transform(scaleY(0));
  }
  100% {
    @include transform(scaleY(1));
  }
}

@keyframes do-vertical-collapse {
  0% {
    @include transform(scaleY(1));
  }
  100% {
    @include transform(scaleY(0));
  }
}

@keyframes do-horizontal-expand {
  0% {
    @include transform(scaleX(0));
  }
  100% {
    @include transform(scaleX(1));
  }
}

@keyframes do-horizontal-collapse {
  0% {
    @include transform(scaleX(1));
  }
  100% {
    @include transform(scaleX(0));
  }
}

.router-animation-container {
  position: relative;

  .login {
    @include wipe-element-rtl();
  }
  .forgot {
    @include wipe-element-ltr();
  }
}
