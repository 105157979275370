
fieldset {
  position: relative;
  z-index: 0;
  margin: 0 0 1rem;
  padding: 1rem 0.5rem;
  border-width: 1px 0 0;
  border-style: solid none none;
  @include themify(
    (dark: (
      border-top-color: $white-01
    )),
    (light: (
      border-top-color: $black-01
    ))
  );

  > legend {
    margin: 0 0 1rem 1rem;
    padding: 0 0.6rem;
    font-size: 1.6rem;
    font-weight: 400;
    @include themify(
      (dark: (
        color: $white-03
      )),
      (light: (
        color: $black-03
      ))
    );
  }

  &.fieldset-inline {
    @include zindex-children(200, 20, true);
    display: inline-block;
    vertical-align: top;
    margin-left: 1rem;
    margin-bottom: 0;
    white-space: nowrap;
    &:first-child {
      margin-left: 0;
    }

    > * {
      white-space: normal;
    }

    // > .form-group {
    //   &:not(.well) {
    //     padding-top: 1.4rem;
    //   }
    // }
  }

  &:not(.no-legend) {
    > legend {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.no-legend {
    > legend {
      display: none;
    }
    border-top: 0 none transparent;
  }
}

.form-group {
  > fieldset.fieldset-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.form-group.fieldset-inline-group {
  margin-top: 2rem;
  &:before {
    content: '';
    display: block;
    height: 2rem;
    pointer-events: none;
    border-width: 2px 0 0;
    border-style: dotted none none;
    @include themify(
      (dark: (
        border-color: $black-05
      )),
      (light: (
        border-color: $black-01
      ))
    );
  }
}

