@import 'font-awesome/variables';

.icon {
  @include iconz();
}

.iconz {
  &:before {
    @include iconz();
  }
}

%icon-px { &:before { content: $icon-px; }}
%icon-accessible-icon { &:before { content: $icon-accessible-icon; }}
%icon-accusoft { &:before { content: $icon-accusoft; }}
%icon-adn { &:before { content: $icon-adn; }}
%icon-adversal { &:before { content: $icon-adversal; }}
%icon-affiliatetheme { &:before { content: $icon-affiliatetheme; }}
%icon-algolia { &:before { content: $icon-algolia; }}
%icon-amazon-pay { &:before { content: $icon-amazon-pay; }}
%icon-amazon { &:before { content: $icon-amazon; }}
%icon-amilia { &:before { content: $icon-amilia; }}
%icon-android { &:before { content: $icon-android; }}
%icon-angellist { &:before { content: $icon-angellist; }}
%icon-angrycreative { &:before { content: $icon-angrycreative; }}
%icon-angular { &:before { content: $icon-angular; }}
%icon-app-store-ios { &:before { content: $icon-app-store-ios; }}
%icon-app-store { &:before { content: $icon-app-store; }}
%icon-apper { &:before { content: $icon-apper; }}
%icon-apple-pay { &:before { content: $icon-apple-pay; }}
%icon-apple { &:before { content: $icon-apple; }}
%icon-asymmetrik { &:before { content: $icon-asymmetrik; }}
%icon-audible { &:before { content: $icon-audible; }}
%icon-autoprefixer { &:before { content: $icon-autoprefixer; }}
%icon-avianex { &:before { content: $icon-avianex; }}
%icon-aviato { &:before { content: $icon-aviato; }}
%icon-aws { &:before { content: $icon-aws; }}
%icon-bandcamp { &:before { content: $icon-bandcamp; }}
%icon-behance-square { &:before { content: $icon-behance-square; }}
%icon-behance { &:before { content: $icon-behance; }}
%icon-bimobject { &:before { content: $icon-bimobject; }}
%icon-bitbucket { &:before { content: $icon-bitbucket; }}
%icon-bitcoin { &:before { content: $icon-bitcoin; }}
%icon-bity { &:before { content: $icon-bity; }}
%icon-black-tie { &:before { content: $icon-black-tie; }}
%icon-blackberry { &:before { content: $icon-blackberry; }}
%icon-blogger-b { &:before { content: $icon-blogger-b; }}
%icon-blogger { &:before { content: $icon-blogger; }}
%icon-bluetooth-b { &:before { content: $icon-bluetooth-b; }}
%icon-bluetooth { &:before { content: $icon-bluetooth; }}
%icon-btc { &:before { content: $icon-btc; }}
%icon-buromobelexperte { &:before { content: $icon-buromobelexperte; }}
%icon-buysellads { &:before { content: $icon-buysellads; }}
%icon-cc-amazon-pay { &:before { content: $icon-cc-amazon-pay; }}
%icon-cc-amex { &:before { content: $icon-cc-amex; }}
%icon-cc-apple-pay { &:before { content: $icon-cc-apple-pay; }}
%icon-cc-diners-club { &:before { content: $icon-cc-diners-club; }}
%icon-cc-discover { &:before { content: $icon-cc-discover; }}
%icon-cc-jcb { &:before { content: $icon-cc-jcb; }}
%icon-cc-mastercard { &:before { content: $icon-cc-mastercard; }}
%icon-cc-paypal { &:before { content: $icon-cc-paypal; }}
%icon-cc-stripe { &:before { content: $icon-cc-stripe; }}
%icon-cc-visa { &:before { content: $icon-cc-visa; }}
%icon-centercode { &:before { content: $icon-centercode; }}
%icon-chrome { &:before { content: $icon-chrome; }}
%icon-cloudscale { &:before { content: $icon-cloudscale; }}
%icon-cloudsmith { &:before { content: $icon-cloudsmith; }}
%icon-cloudversify { &:before { content: $icon-cloudversify; }}
%icon-codepen { &:before { content: $icon-codepen; }}
%icon-codiepie { &:before { content: $icon-codiepie; }}
%icon-connectdevelop { &:before { content: $icon-connectdevelop; }}
%icon-contao { &:before { content: $icon-contao; }}
%icon-cpanel { &:before { content: $icon-cpanel; }}
%icon-creative-commons { &:before { content: $icon-creative-commons; }}
%icon-css3-alt { &:before { content: $icon-css3-alt; }}
%icon-css3 { &:before { content: $icon-css3; }}
%icon-cuttlefish { &:before { content: $icon-cuttlefish; }}
%icon-d-and-d { &:before { content: $icon-d-and-d; }}
%icon-dashcube { &:before { content: $icon-dashcube; }}
%icon-delicious { &:before { content: $icon-delicious; }}
%icon-deploydog { &:before { content: $icon-deploydog; }}
%icon-deskpro { &:before { content: $icon-deskpro; }}
%icon-deviantart { &:before { content: $icon-deviantart; }}
%icon-digg { &:before { content: $icon-digg; }}
%icon-digital-ocean { &:before { content: $icon-digital-ocean; }}
%icon-discord { &:before { content: $icon-discord; }}
%icon-discourse { &:before { content: $icon-discourse; }}
%icon-dochub { &:before { content: $icon-dochub; }}
%icon-docker { &:before { content: $icon-docker; }}
%icon-draft2digital { &:before { content: $icon-draft2digital; }}
%icon-dribbble-square { &:before { content: $icon-dribbble-square; }}
%icon-dribbble { &:before { content: $icon-dribbble; }}
%icon-dropbox { &:before { content: $icon-dropbox; }}
%icon-drupal { &:before { content: $icon-drupal; }}
%icon-dyalog { &:before { content: $icon-dyalog; }}
%icon-earlybirds { &:before { content: $icon-earlybirds; }}
%icon-edge { &:before { content: $icon-edge; }}
%icon-elementor { &:before { content: $icon-elementor; }}
%icon-ember { &:before { content: $icon-ember; }}
%icon-empire { &:before { content: $icon-empire; }}
%icon-envira { &:before { content: $icon-envira; }}
%icon-erlang { &:before { content: $icon-erlang; }}
%icon-ethereum { &:before { content: $icon-ethereum; }}
%icon-etsy { &:before { content: $icon-etsy; }}
%icon-expeditedssl { &:before { content: $icon-expeditedssl; }}
%icon-facebook-f { &:before { content: $icon-facebook-f; }}
%icon-facebook-messenger { &:before { content: $icon-facebook-messenger; }}
%icon-facebook-square { &:before { content: $icon-facebook-square; }}
%icon-facebook { &:before { content: $icon-facebook; }}
%icon-firefox { &:before { content: $icon-firefox; }}
%icon-first-order { &:before { content: $icon-first-order; }}
%icon-firstdraft { &:before { content: $icon-firstdraft; }}
%icon-flickr { &:before { content: $icon-flickr; }}
%icon-flipboard { &:before { content: $icon-flipboard; }}
%icon-fly { &:before { content: $icon-fly; }}
%icon-font-awesome-alt { &:before { content: $icon-font-awesome-alt; }}
%icon-font-awesome-flag { &:before { content: $icon-font-awesome-flag; }}
%icon-font-awesome { &:before { content: $icon-font-awesome; }}
%icon-fonticons-fi { &:before { content: $icon-fonticons-fi; }}
%icon-fonticons { &:before { content: $icon-fonticons; }}
%icon-fort-awesome-alt { &:before { content: $icon-fort-awesome-alt; }}
%icon-fort-awesome { &:before { content: $icon-fort-awesome; }}
%icon-forumbee { &:before { content: $icon-forumbee; }}
%icon-foursquare { &:before { content: $icon-foursquare; }}
%icon-free-code-camp { &:before { content: $icon-free-code-camp; }}
%icon-freebsd { &:before { content: $icon-freebsd; }}
%icon-get-pocket { &:before { content: $icon-get-pocket; }}
%icon-gg-circle { &:before { content: $icon-gg-circle; }}
%icon-gg { &:before { content: $icon-gg; }}
%icon-git-square { &:before { content: $icon-git-square; }}
%icon-git { &:before { content: $icon-git; }}
%icon-github-alt { &:before { content: $icon-github-alt; }}
%icon-github-square { &:before { content: $icon-github-square; }}
%icon-github { &:before { content: $icon-github; }}
%icon-gitkraken { &:before { content: $icon-gitkraken; }}
%icon-gitlab { &:before { content: $icon-gitlab; }}
%icon-gitter { &:before { content: $icon-gitter; }}
%icon-glide-g { &:before { content: $icon-glide-g; }}
%icon-glide { &:before { content: $icon-glide; }}
%icon-gofore { &:before { content: $icon-gofore; }}
%icon-goodreads-g { &:before { content: $icon-goodreads-g; }}
%icon-goodreads { &:before { content: $icon-goodreads; }}
%icon-google-drive { &:before { content: $icon-google-drive; }}
%icon-google-play { &:before { content: $icon-google-play; }}
%icon-google-plus-g { &:before { content: $icon-google-plus-g; }}
%icon-google-plus-square { &:before { content: $icon-google-plus-square; }}
%icon-google-plus { &:before { content: $icon-google-plus; }}
%icon-google-wallet { &:before { content: $icon-google-wallet; }}
%icon-google { &:before { content: $icon-google; }}
%icon-gratipay { &:before { content: $icon-gratipay; }}
%icon-grav { &:before { content: $icon-grav; }}
%icon-gripfire { &:before { content: $icon-gripfire; }}
%icon-grunt { &:before { content: $icon-grunt; }}
%icon-gulp { &:before { content: $icon-gulp; }}
%icon-hacker-news-square { &:before { content: $icon-hacker-news-square; }}
%icon-hacker-news { &:before { content: $icon-hacker-news; }}
%icon-hips { &:before { content: $icon-hips; }}
%icon-hire-a-helper { &:before { content: $icon-hire-a-helper; }}
%icon-hooli { &:before { content: $icon-hooli; }}
%icon-hotjar { &:before { content: $icon-hotjar; }}
%icon-houzz { &:before { content: $icon-houzz; }}
%icon-html5 { &:before { content: $icon-html5; }}
%icon-hubspot { &:before { content: $icon-hubspot; }}
%icon-imdb { &:before { content: $icon-imdb; }}
%icon-instagram { &:before { content: $icon-instagram; }}
%icon-internet-explorer { &:before { content: $icon-internet-explorer; }}
%icon-ioxhost { &:before { content: $icon-ioxhost; }}
%icon-itunes-note { &:before { content: $icon-itunes-note; }}
%icon-itunes { &:before { content: $icon-itunes; }}
%icon-jenkins { &:before { content: $icon-jenkins; }}
%icon-joget { &:before { content: $icon-joget; }}
%icon-joomla { &:before { content: $icon-joomla; }}
%icon-js-square { &:before { content: $icon-js-square; }}
%icon-js { &:before { content: $icon-js; }}
%icon-jsfiddle { &:before { content: $icon-jsfiddle; }}
%icon-keycdn { &:before { content: $icon-keycdn; }}
%icon-kickstarter-k { &:before { content: $icon-kickstarter-k; }}
%icon-kickstarter { &:before { content: $icon-kickstarter; }}
%icon-korvue { &:before { content: $icon-korvue; }}
%icon-laravel { &:before { content: $icon-laravel; }}
%icon-lastfm-square { &:before { content: $icon-lastfm-square; }}
%icon-lastfm { &:before { content: $icon-lastfm; }}
%icon-leanpub { &:before { content: $icon-leanpub; }}
%icon-less { &:before { content: $icon-less; }}
%icon-line { &:before { content: $icon-line; }}
%icon-linkedin-in { &:before { content: $icon-linkedin-in; }}
%icon-linkedin { &:before { content: $icon-linkedin; }}
%icon-linode { &:before { content: $icon-linode; }}
%icon-linux { &:before { content: $icon-linux; }}
%icon-lyft { &:before { content: $icon-lyft; }}
%icon-magento { &:before { content: $icon-magento; }}
%icon-maxcdn { &:before { content: $icon-maxcdn; }}
%icon-medapps { &:before { content: $icon-medapps; }}
%icon-medium-m { &:before { content: $icon-medium-m; }}
%icon-medium { &:before { content: $icon-medium; }}
%icon-medrt { &:before { content: $icon-medrt; }}
%icon-meetup { &:before { content: $icon-meetup; }}
%icon-microsoft { &:before { content: $icon-microsoft; }}
%icon-mix { &:before { content: $icon-mix; }}
%icon-mixcloud { &:before { content: $icon-mixcloud; }}
%icon-mizuni { &:before { content: $icon-mizuni; }}
%icon-modx { &:before { content: $icon-modx; }}
%icon-monero { &:before { content: $icon-monero; }}
%icon-napster { &:before { content: $icon-napster; }}
%icon-nintendo-switch { &:before { content: $icon-nintendo-switch; }}
%icon-node-js { &:before { content: $icon-node-js; }}
%icon-node { &:before { content: $icon-node; }}
%icon-npm { &:before { content: $icon-npm; }}
%icon-ns8 { &:before { content: $icon-ns8; }}
%icon-nutritionix { &:before { content: $icon-nutritionix; }}
%icon-odnoklassniki-square { &:before { content: $icon-odnoklassniki-square; }}
%icon-odnoklassniki { &:before { content: $icon-odnoklassniki; }}
%icon-opencart { &:before { content: $icon-opencart; }}
%icon-openid { &:before { content: $icon-openid; }}
%icon-opera { &:before { content: $icon-opera; }}
%icon-optin-monster { &:before { content: $icon-optin-monster; }}
%icon-osi { &:before { content: $icon-osi; }}
%icon-page4 { &:before { content: $icon-page4; }}
%icon-pagelines { &:before { content: $icon-pagelines; }}
%icon-palfed { &:before { content: $icon-palfed; }}
%icon-patreon { &:before { content: $icon-patreon; }}
%icon-paypal { &:before { content: $icon-paypal; }}
%icon-periscope { &:before { content: $icon-periscope; }}
%icon-phabricator { &:before { content: $icon-phabricator; }}
%icon-phoenix-framework { &:before { content: $icon-phoenix-framework; }}
%icon-php { &:before { content: $icon-php; }}
%icon-pied-piper-alt { &:before { content: $icon-pied-piper-alt; }}
%icon-pied-piper-pp { &:before { content: $icon-pied-piper-pp; }}
%icon-pied-piper { &:before { content: $icon-pied-piper; }}
%icon-pinterest-p { &:before { content: $icon-pinterest-p; }}
%icon-pinterest-square { &:before { content: $icon-pinterest-square; }}
%icon-pinterest { &:before { content: $icon-pinterest; }}
%icon-playstation { &:before { content: $icon-playstation; }}
%icon-product-hunt { &:before { content: $icon-product-hunt; }}
%icon-pushed { &:before { content: $icon-pushed; }}
%icon-python { &:before { content: $icon-python; }}
%icon-qq { &:before { content: $icon-qq; }}
%icon-quinscape { &:before { content: $icon-quinscape; }}
%icon-quora { &:before { content: $icon-quora; }}
%icon-ravelry { &:before { content: $icon-ravelry; }}
%icon-react { &:before { content: $icon-react; }}
%icon-rebel { &:before { content: $icon-rebel; }}
%icon-red-river { &:before { content: $icon-red-river; }}
%icon-reddit-alien { &:before { content: $icon-reddit-alien; }}
%icon-reddit-square { &:before { content: $icon-reddit-square; }}
%icon-reddit { &:before { content: $icon-reddit; }}
%icon-rendact { &:before { content: $icon-rendact; }}
%icon-renren { &:before { content: $icon-renren; }}
%icon-replyd { &:before { content: $icon-replyd; }}
%icon-resolving { &:before { content: $icon-resolving; }}
%icon-rocketchat { &:before { content: $icon-rocketchat; }}
%icon-rockrms { &:before { content: $icon-rockrms; }}
%icon-safari { &:before { content: $icon-safari; }}
%icon-sass { &:before { content: $icon-sass; }}
%icon-schlix { &:before { content: $icon-schlix; }}
%icon-scribd { &:before { content: $icon-scribd; }}
%icon-searchengine { &:before { content: $icon-searchengine; }}
%icon-sellcast { &:before { content: $icon-sellcast; }}
%icon-sellsy { &:before { content: $icon-sellsy; }}
%icon-servicestack { &:before { content: $icon-servicestack; }}
%icon-shirtsinbulk { &:before { content: $icon-shirtsinbulk; }}
%icon-simplybuilt { &:before { content: $icon-simplybuilt; }}
%icon-sistrix { &:before { content: $icon-sistrix; }}
%icon-skyatlas { &:before { content: $icon-skyatlas; }}
%icon-skype { &:before { content: $icon-skype; }}
%icon-slack-hash { &:before { content: $icon-slack-hash; }}
%icon-slack { &:before { content: $icon-slack; }}
%icon-slideshare { &:before { content: $icon-slideshare; }}
%icon-snapchat-ghost { &:before { content: $icon-snapchat-ghost; }}
%icon-snapchat-square { &:before { content: $icon-snapchat-square; }}
%icon-snapchat { &:before { content: $icon-snapchat; }}
%icon-soundcloud { &:before { content: $icon-soundcloud; }}
%icon-speakap { &:before { content: $icon-speakap; }}
%icon-spotify { &:before { content: $icon-spotify; }}
%icon-stack-exchange { &:before { content: $icon-stack-exchange; }}
%icon-stack-overflow { &:before { content: $icon-stack-overflow; }}
%icon-staylinked { &:before { content: $icon-staylinked; }}
%icon-steam-square { &:before { content: $icon-steam-square; }}
%icon-steam-symbol { &:before { content: $icon-steam-symbol; }}
%icon-steam { &:before { content: $icon-steam; }}
%icon-sticker-mule { &:before { content: $icon-sticker-mule; }}
%icon-strava { &:before { content: $icon-strava; }}
%icon-stripe-s { &:before { content: $icon-stripe-s; }}
%icon-stripe { &:before { content: $icon-stripe; }}
%icon-studiovinari { &:before { content: $icon-studiovinari; }}
%icon-stumbleupon-circle { &:before { content: $icon-stumbleupon-circle; }}
%icon-stumbleupon { &:before { content: $icon-stumbleupon; }}
%icon-superpowers { &:before { content: $icon-superpowers; }}
%icon-supple { &:before { content: $icon-supple; }}
%icon-telegram-plane { &:before { content: $icon-telegram-plane; }}
%icon-telegram { &:before { content: $icon-telegram; }}
%icon-tencent-weibo { &:before { content: $icon-tencent-weibo; }}
%icon-themeisle { &:before { content: $icon-themeisle; }}
%icon-trello { &:before { content: $icon-trello; }}
%icon-tripadvisor { &:before { content: $icon-tripadvisor; }}
%icon-tumblr-square { &:before { content: $icon-tumblr-square; }}
%icon-tumblr { &:before { content: $icon-tumblr; }}
%icon-twitch { &:before { content: $icon-twitch; }}
%icon-twitter-square { &:before { content: $icon-twitter-square; }}
%icon-twitter { &:before { content: $icon-twitter; }}
%icon-typo3 { &:before { content: $icon-typo3; }}
%icon-uber { &:before { content: $icon-uber; }}
%icon-uikit { &:before { content: $icon-uikit; }}
%icon-uniregistry { &:before { content: $icon-uniregistry; }}
%icon-untappd { &:before { content: $icon-untappd; }}
%icon-usb { &:before { content: $icon-usb; }}
%icon-ussunnah { &:before { content: $icon-ussunnah; }}
%icon-vaadin { &:before { content: $icon-vaadin; }}
%icon-viacoin { &:before { content: $icon-viacoin; }}
%icon-viadeo-square { &:before { content: $icon-viadeo-square; }}
%icon-viadeo { &:before { content: $icon-viadeo; }}
%icon-viber { &:before { content: $icon-viber; }}
%icon-vimeo-square { &:before { content: $icon-vimeo-square; }}
%icon-vimeo-v { &:before { content: $icon-vimeo-v; }}
%icon-vimeo { &:before { content: $icon-vimeo; }}
%icon-vine { &:before { content: $icon-vine; }}
%icon-vk { &:before { content: $icon-vk; }}
%icon-vnv { &:before { content: $icon-vnv; }}
%icon-vuejs { &:before { content: $icon-vuejs; }}
%icon-weibo { &:before { content: $icon-weibo; }}
%icon-weixin { &:before { content: $icon-weixin; }}
%icon-whatsapp-square { &:before { content: $icon-whatsapp-square; }}
%icon-whatsapp { &:before { content: $icon-whatsapp; }}
%icon-whmcs { &:before { content: $icon-whmcs; }}
%icon-wikipedia-w { &:before { content: $icon-wikipedia-w; }}
%icon-windows { &:before { content: $icon-windows; }}
%icon-wordpress-simple { &:before { content: $icon-wordpress-simple; }}
%icon-wordpress { &:before { content: $icon-wordpress; }}
%icon-wpbeginner { &:before { content: $icon-wpbeginner; }}
%icon-wpexplorer { &:before { content: $icon-wpexplorer; }}
%icon-wpforms { &:before { content: $icon-wpforms; }}
%icon-xbox { &:before { content: $icon-xbox; }}
%icon-xing-square { &:before { content: $icon-xing-square; }}
%icon-xing { &:before { content: $icon-xing; }}
%icon-y-combinator { &:before { content: $icon-y-combinator; }}
%icon-yahoo { &:before { content: $icon-yahoo; }}
%icon-yandex-international { &:before { content: $icon-yandex-international; }}
%icon-yandex { &:before { content: $icon-yandex; }}
%icon-yelp { &:before { content: $icon-yelp; }}
%icon-yoast { &:before { content: $icon-yoast; }}
%icon-youtube-square { &:before { content: $icon-youtube-square; }}
%icon-youtube { &:before { content: $icon-youtube; }}
%icon-address-book { &:before { content: $icon-address-book; }}
%icon-address-card { &:before { content: $icon-address-card; }}
%icon-adjust { &:before { content: $icon-adjust; }}
%icon-align-center { &:before { content: $icon-align-center; }}
%icon-align-justify { &:before { content: $icon-align-justify; }}
%icon-align-left { &:before { content: $icon-align-left; }}
%icon-align-right { &:before { content: $icon-align-right; }}
%icon-ambulance { &:before { content: $icon-ambulance; }}
%icon-american-sign-language-interpreting { &:before { content: $icon-american-sign-language-interpreting; }}
%icon-anchor { &:before { content: $icon-anchor; }}
%icon-angle-double-down { &:before { content: $icon-angle-double-down; }}
%icon-angle-double-left { &:before { content: $icon-angle-double-left; }}
%icon-angle-double-right { &:before { content: $icon-angle-double-right; }}
%icon-angle-double-up { &:before { content: $icon-angle-double-up; }}
%icon-angle-down { &:before { content: $icon-angle-down; }}
%icon-angle-left { &:before { content: $icon-angle-left; }}
%icon-angle-right { &:before { content: $icon-angle-right; }}
%icon-angle-up { &:before { content: $icon-angle-up; }}
%icon-archive { &:before { content: $icon-archive; }}
%icon-arrow-alt-circle-down { &:before { content: $icon-arrow-alt-circle-down; }}
%icon-arrow-alt-circle-left { &:before { content: $icon-arrow-alt-circle-left; }}
%icon-arrow-alt-circle-right { &:before { content: $icon-arrow-alt-circle-right; }}
%icon-arrow-alt-circle-up { &:before { content: $icon-arrow-alt-circle-up; }}
%icon-arrow-circle-down { &:before { content: $icon-arrow-circle-down; }}
%icon-arrow-circle-left { &:before { content: $icon-arrow-circle-left; }}
%icon-arrow-circle-right { &:before { content: $icon-arrow-circle-right; }}
%icon-arrow-circle-up { &:before { content: $icon-arrow-circle-up; }}
%icon-arrow-down { &:before { content: $icon-arrow-down; }}
%icon-arrow-left { &:before { content: $icon-arrow-left; }}
%icon-arrow-right { &:before { content: $icon-arrow-right; }}
%icon-arrow-up { &:before { content: $icon-arrow-up; }}
%icon-arrows-alt-h { &:before { content: $icon-arrows-alt-h; }}
%icon-arrows-alt-v { &:before { content: $icon-arrows-alt-v; }}
%icon-arrows-alt { &:before { content: $icon-arrows-alt; }}
%icon-assistive-listening-systems { &:before { content: $icon-assistive-listening-systems; }}
%icon-asterisk { &:before { content: $icon-asterisk; }}
%icon-at { &:before { content: $icon-at; }}
%icon-audio-description { &:before { content: $icon-audio-description; }}
%icon-backward { &:before { content: $icon-backward; }}
%icon-balance-scale { &:before { content: $icon-balance-scale; }}
%icon-ban { &:before { content: $icon-ban; }}
%icon-barcode { &:before { content: $icon-barcode; }}
%icon-bars { &:before { content: $icon-bars; }}
%icon-baseball-ball { &:before { content: $icon-baseball-ball; }}
%icon-basketball-ball { &:before { content: $icon-basketball-ball; }}
%icon-bath { &:before { content: $icon-bath; }}
%icon-battery-empty { &:before { content: $icon-battery-empty; }}
%icon-battery-full { &:before { content: $icon-battery-full; }}
%icon-battery-half { &:before { content: $icon-battery-half; }}
%icon-battery-quarter { &:before { content: $icon-battery-quarter; }}
%icon-battery-three-quarters { &:before { content: $icon-battery-three-quarters; }}
%icon-bed { &:before { content: $icon-bed; }}
%icon-beer { &:before { content: $icon-beer; }}
%icon-bell-slash { &:before { content: $icon-bell-slash; }}
%icon-bell { &:before { content: $icon-bell; }}
%icon-bicycle { &:before { content: $icon-bicycle; }}
%icon-binoculars { &:before { content: $icon-binoculars; }}
%icon-birthday-cake { &:before { content: $icon-birthday-cake; }}
%icon-blind { &:before { content: $icon-blind; }}
%icon-bold { &:before { content: $icon-bold; }}
%icon-bolt { &:before { content: $icon-bolt; }}
%icon-bomb { &:before { content: $icon-bomb; }}
%icon-book { &:before { content: $icon-book; }}
%icon-bookmark { &:before { content: $icon-bookmark; }}
%icon-bowling-ball { &:before { content: $icon-bowling-ball; }}
%icon-braille { &:before { content: $icon-braille; }}
%icon-briefcase { &:before { content: $icon-briefcase; }}
%icon-bug { &:before { content: $icon-bug; }}
%icon-building { &:before { content: $icon-building; }}
%icon-bullhorn { &:before { content: $icon-bullhorn; }}
%icon-bullseye { &:before { content: $icon-bullseye; }}
%icon-bus { &:before { content: $icon-bus; }}
%icon-calculator { &:before { content: $icon-calculator; }}
%icon-calendar-alt { &:before { content: $icon-calendar-alt; }}
%icon-calendar-check { &:before { content: $icon-calendar-check; }}
%icon-calendar-minus { &:before { content: $icon-calendar-minus; }}
%icon-calendar-plus { &:before { content: $icon-calendar-plus; }}
%icon-calendar-times { &:before { content: $icon-calendar-times; }}
%icon-calendar { &:before { content: $icon-calendar; }}
%icon-camera-retro { &:before { content: $icon-camera-retro; }}
%icon-camera { &:before { content: $icon-camera; }}
%icon-car { &:before { content: $icon-car; }}
%icon-caret-down { &:before { content: $icon-caret-down; }}
%icon-caret-left { &:before { content: $icon-caret-left; }}
%icon-caret-right { &:before { content: $icon-caret-right; }}
%icon-caret-square-down { &:before { content: $icon-caret-square-down; }}
%icon-caret-square-left { &:before { content: $icon-caret-square-left; }}
%icon-caret-square-right { &:before { content: $icon-caret-square-right; }}
%icon-caret-square-up { &:before { content: $icon-caret-square-up; }}
%icon-caret-up { &:before { content: $icon-caret-up; }}
%icon-cart-arrow-down { &:before { content: $icon-cart-arrow-down; }}
%icon-cart-plus { &:before { content: $icon-cart-plus; }}
%icon-certificate { &:before { content: $icon-certificate; }}
%icon-chart-area { &:before { content: $icon-chart-area; }}
%icon-chart-bar { &:before { content: $icon-chart-bar; }}
%icon-chart-line { &:before { content: $icon-chart-line; }}
%icon-chart-pie { &:before { content: $icon-chart-pie; }}
%icon-check-circle { &:before { content: $icon-check-circle; }}
%icon-check-square { &:before { content: $icon-check-square; }}
%icon-check { &:before { content: $icon-check; }}
%icon-chess-bishop { &:before { content: $icon-chess-bishop; }}
%icon-chess-board { &:before { content: $icon-chess-board; }}
%icon-chess-king { &:before { content: $icon-chess-king; }}
%icon-chess-knight { &:before { content: $icon-chess-knight; }}
%icon-chess-pawn { &:before { content: $icon-chess-pawn; }}
%icon-chess-queen { &:before { content: $icon-chess-queen; }}
%icon-chess-rook { &:before { content: $icon-chess-rook; }}
%icon-chess { &:before { content: $icon-chess; }}
%icon-chevron-circle-down { &:before { content: $icon-chevron-circle-down; }}
%icon-chevron-circle-left { &:before { content: $icon-chevron-circle-left; }}
%icon-chevron-circle-right { &:before { content: $icon-chevron-circle-right; }}
%icon-chevron-circle-up { &:before { content: $icon-chevron-circle-up; }}
%icon-chevron-down { &:before { content: $icon-chevron-down; }}
%icon-chevron-left { &:before { content: $icon-chevron-left; }}
%icon-chevron-right { &:before { content: $icon-chevron-right; }}
%icon-chevron-up { &:before { content: $icon-chevron-up; }}
%icon-child { &:before { content: $icon-child; }}
%icon-circle-notch { &:before { content: $icon-circle-notch; }}
%icon-circle { &:before { content: $icon-circle; }}
%icon-clipboard { &:before { content: $icon-clipboard; }}
%icon-clock { &:before { content: $icon-clock; }}
%icon-clone { &:before { content: $icon-clone; }}
%icon-closed-captioning { &:before { content: $icon-closed-captioning; }}
%icon-cloud-download-alt { &:before { content: $icon-cloud-download-alt; }}
%icon-cloud-upload-alt { &:before { content: $icon-cloud-upload-alt; }}
%icon-cloud { &:before { content: $icon-cloud; }}
%icon-code-branch { &:before { content: $icon-code-branch; }}
%icon-code { &:before { content: $icon-code; }}
%icon-coffee { &:before { content: $icon-coffee; }}
%icon-cog { &:before { content: $icon-cog; }}
%icon-cogs { &:before { content: $icon-cogs; }}
%icon-columns { &:before { content: $icon-columns; }}
%icon-comment-alt { &:before { content: $icon-comment-alt; }}
%icon-comment { &:before { content: $icon-comment; }}
%icon-comments { &:before { content: $icon-comments; }}
%icon-compass { &:before { content: $icon-compass; }}
%icon-compress { &:before { content: $icon-compress; }}
%icon-copy { &:before { content: $icon-copy; }}
%icon-copyright { &:before { content: $icon-copyright; }}
%icon-credit-card { &:before { content: $icon-credit-card; }}
%icon-crop { &:before { content: $icon-crop; }}
%icon-crosshairs { &:before { content: $icon-crosshairs; }}
%icon-cube { &:before { content: $icon-cube; }}
%icon-cubes { &:before { content: $icon-cubes; }}
%icon-cut { &:before { content: $icon-cut; }}
%icon-database { &:before { content: $icon-database; }}
%icon-deaf { &:before { content: $icon-deaf; }}
%icon-desktop { &:before { content: $icon-desktop; }}
%icon-dollar-sign { &:before { content: $icon-dollar-sign; }}
%icon-dot-circle { &:before { content: $icon-dot-circle; }}
%icon-download { &:before { content: $icon-download; }}
%icon-edit { &:before { content: $icon-edit; }}
%icon-eject { &:before { content: $icon-eject; }}
%icon-ellipsis-h { &:before { content: $icon-ellipsis-h; }}
%icon-ellipsis-v { &:before { content: $icon-ellipsis-v; }}
%icon-envelope-open { &:before { content: $icon-envelope-open; }}
%icon-envelope-square { &:before { content: $icon-envelope-square; }}
%icon-envelope { &:before { content: $icon-envelope; }}
%icon-eraser { &:before { content: $icon-eraser; }}
%icon-euro-sign { &:before { content: $icon-euro-sign; }}
%icon-exchange-alt { &:before { content: $icon-exchange-alt; }}
%icon-exclamation-circle { &:before { content: $icon-exclamation-circle; }}
%icon-exclamation-triangle { &:before { content: $icon-exclamation-triangle; }}
%icon-exclamation { &:before { content: $icon-exclamation; }}
%icon-expand-arrows-alt { &:before { content: $icon-expand-arrows-alt; }}
%icon-expand { &:before { content: $icon-expand; }}
%icon-external-link-alt { &:before { content: $icon-external-link-alt; }}
%icon-external-link-square-alt { &:before { content: $icon-external-link-square-alt; }}
%icon-eye-dropper { &:before { content: $icon-eye-dropper; }}
%icon-eye-slash { &:before { content: $icon-eye-slash; }}
%icon-eye { &:before { content: $icon-eye; }}
%icon-fast-backward { &:before { content: $icon-fast-backward; }}
%icon-fast-forward { &:before { content: $icon-fast-forward; }}
%icon-fax { &:before { content: $icon-fax; }}
%icon-female { &:before { content: $icon-female; }}
%icon-fighter-jet { &:before { content: $icon-fighter-jet; }}
%icon-file-alt { &:before { content: $icon-file-alt; }}
%icon-file-archive { &:before { content: $icon-file-archive; }}
%icon-file-audio { &:before { content: $icon-file-audio; }}
%icon-file-code { &:before { content: $icon-file-code; }}
%icon-file-excel { &:before { content: $icon-file-excel; }}
%icon-file-image { &:before { content: $icon-file-image; }}
%icon-file-pdf { &:before { content: $icon-file-pdf; }}
%icon-file-powerpoint { &:before { content: $icon-file-powerpoint; }}
%icon-file-video { &:before { content: $icon-file-video; }}
%icon-file-word { &:before { content: $icon-file-word; }}
%icon-file { &:before { content: $icon-file; }}
%icon-film { &:before { content: $icon-film; }}
%icon-filter { &:before { content: $icon-filter; }}
%icon-fire-extinguisher { &:before { content: $icon-fire-extinguisher; }}
%icon-fire { &:before { content: $icon-fire; }}
%icon-flag-checkered { &:before { content: $icon-flag-checkered; }}
%icon-flag { &:before { content: $icon-flag; }}
%icon-flask { &:before { content: $icon-flask; }}
%icon-folder-open { &:before { content: $icon-folder-open; }}
%icon-folder { &:before { content: $icon-folder; }}
%icon-font { &:before { content: $icon-font; }}
%icon-football-ball { &:before { content: $icon-football-ball; }}
%icon-forward { &:before { content: $icon-forward; }}
%icon-frown { &:before { content: $icon-frown; }}
%icon-futbol { &:before { content: $icon-futbol; }}
%icon-gamepad { &:before { content: $icon-gamepad; }}
%icon-gavel { &:before { content: $icon-gavel; }}
%icon-gem { &:before { content: $icon-gem; }}
%icon-genderless { &:before { content: $icon-genderless; }}
%icon-gift { &:before { content: $icon-gift; }}
%icon-glass-martini { &:before { content: $icon-glass-martini; }}
%icon-globe { &:before { content: $icon-globe; }}
%icon-golf-ball { &:before { content: $icon-golf-ball; }}
%icon-graduation-cap { &:before { content: $icon-graduation-cap; }}
%icon-h-square { &:before { content: $icon-h-square; }}
%icon-hand-lizard { &:before { content: $icon-hand-lizard; }}
%icon-hand-paper { &:before { content: $icon-hand-paper; }}
%icon-hand-peace { &:before { content: $icon-hand-peace; }}
%icon-hand-point-down { &:before { content: $icon-hand-point-down; }}
%icon-hand-point-left { &:before { content: $icon-hand-point-left; }}
%icon-hand-point-right { &:before { content: $icon-hand-point-right; }}
%icon-hand-point-up { &:before { content: $icon-hand-point-up; }}
%icon-hand-pointer { &:before { content: $icon-hand-pointer; }}
%icon-hand-rock { &:before { content: $icon-hand-rock; }}
%icon-hand-scissors { &:before { content: $icon-hand-scissors; }}
%icon-hand-spock { &:before { content: $icon-hand-spock; }}
%icon-handshake { &:before { content: $icon-handshake; }}
%icon-hashtag { &:before { content: $icon-hashtag; }}
%icon-hdd { &:before { content: $icon-hdd; }}
%icon-heading { &:before { content: $icon-heading; }}
%icon-headphones { &:before { content: $icon-headphones; }}
%icon-heart { &:before { content: $icon-heart; }}
%icon-heartbeat { &:before { content: $icon-heartbeat; }}
%icon-history { &:before { content: $icon-history; }}
%icon-hockey-puck { &:before { content: $icon-hockey-puck; }}
%icon-home { &:before { content: $icon-home; }}
%icon-hospital { &:before { content: $icon-hospital; }}
%icon-hourglass-end { &:before { content: $icon-hourglass-end; }}
%icon-hourglass-half { &:before { content: $icon-hourglass-half; }}
%icon-hourglass-start { &:before { content: $icon-hourglass-start; }}
%icon-hourglass { &:before { content: $icon-hourglass; }}
%icon-i-cursor { &:before { content: $icon-i-cursor; }}
%icon-id-badge { &:before { content: $icon-id-badge; }}
%icon-id-card { &:before { content: $icon-id-card; }}
%icon-image { &:before { content: $icon-image; }}
%icon-images { &:before { content: $icon-images; }}
%icon-inbox { &:before { content: $icon-inbox; }}
%icon-indent { &:before { content: $icon-indent; }}
%icon-industry { &:before { content: $icon-industry; }}
%icon-info-circle { &:before { content: $icon-info-circle; }}
%icon-info { &:before { content: $icon-info; }}
%icon-italic { &:before { content: $icon-italic; }}
%icon-key { &:before { content: $icon-key; }}
%icon-keyboard { &:before { content: $icon-keyboard; }}
%icon-language { &:before { content: $icon-language; }}
%icon-laptop { &:before { content: $icon-laptop; }}
%icon-leaf { &:before { content: $icon-leaf; }}
%icon-lemon { &:before { content: $icon-lemon; }}
%icon-level-down-alt { &:before { content: $icon-level-down-alt; }}
%icon-level-up-alt { &:before { content: $icon-level-up-alt; }}
%icon-life-ring { &:before { content: $icon-life-ring; }}
%icon-lightbulb { &:before { content: $icon-lightbulb; }}
%icon-link { &:before { content: $icon-link; }}
%icon-lira-sign { &:before { content: $icon-lira-sign; }}
%icon-list-alt { &:before { content: $icon-list-alt; }}
%icon-list-ol { &:before { content: $icon-list-ol; }}
%icon-list-ul { &:before { content: $icon-list-ul; }}
%icon-list { &:before { content: $icon-list; }}
%icon-location-arrow { &:before { content: $icon-location-arrow; }}
%icon-lock-open { &:before { content: $icon-lock-open; }}
%icon-lock { &:before { content: $icon-lock; }}
%icon-long-arrow-alt-down { &:before { content: $icon-long-arrow-alt-down; }}
%icon-long-arrow-alt-left { &:before { content: $icon-long-arrow-alt-left; }}
%icon-long-arrow-alt-right { &:before { content: $icon-long-arrow-alt-right; }}
%icon-long-arrow-alt-up { &:before { content: $icon-long-arrow-alt-up; }}
%icon-low-vision { &:before { content: $icon-low-vision; }}
%icon-magic { &:before { content: $icon-magic; }}
%icon-magnet { &:before { content: $icon-magnet; }}
%icon-male { &:before { content: $icon-male; }}
%icon-map-marker-alt { &:before { content: $icon-map-marker-alt; }}
%icon-map-marker { &:before { content: $icon-map-marker; }}
%icon-map-pin { &:before { content: $icon-map-pin; }}
%icon-map-signs { &:before { content: $icon-map-signs; }}
%icon-map { &:before { content: $icon-map; }}
%icon-mars-double { &:before { content: $icon-mars-double; }}
%icon-mars-stroke-h { &:before { content: $icon-mars-stroke-h; }}
%icon-mars-stroke-v { &:before { content: $icon-mars-stroke-v; }}
%icon-mars-stroke { &:before { content: $icon-mars-stroke; }}
%icon-mars { &:before { content: $icon-mars; }}
%icon-medkit { &:before { content: $icon-medkit; }}
%icon-meh { &:before { content: $icon-meh; }}
%icon-mercury { &:before { content: $icon-mercury; }}
%icon-microchip { &:before { content: $icon-microchip; }}
%icon-microphone-slash { &:before { content: $icon-microphone-slash; }}
%icon-microphone { &:before { content: $icon-microphone; }}
%icon-minus-circle { &:before { content: $icon-minus-circle; }}
%icon-minus-square { &:before { content: $icon-minus-square; }}
%icon-minus { &:before { content: $icon-minus; }}
%icon-mobile-alt { &:before { content: $icon-mobile-alt; }}
%icon-mobile { &:before { content: $icon-mobile; }}
%icon-money-bill-alt { &:before { content: $icon-money-bill-alt; }}
%icon-moon { &:before { content: $icon-moon; }}
%icon-motorcycle { &:before { content: $icon-motorcycle; }}
%icon-mouse-pointer { &:before { content: $icon-mouse-pointer; }}
%icon-music { &:before { content: $icon-music; }}
%icon-neuter { &:before { content: $icon-neuter; }}
%icon-newspaper { &:before { content: $icon-newspaper; }}
%icon-object-group { &:before { content: $icon-object-group; }}
%icon-object-ungroup { &:before { content: $icon-object-ungroup; }}
%icon-outdent { &:before { content: $icon-outdent; }}
%icon-paint-brush { &:before { content: $icon-paint-brush; }}
%icon-paper-plane { &:before { content: $icon-paper-plane; }}
%icon-paperclip { &:before { content: $icon-paperclip; }}
%icon-paragraph { &:before { content: $icon-paragraph; }}
%icon-paste { &:before { content: $icon-paste; }}
%icon-pause-circle { &:before { content: $icon-pause-circle; }}
%icon-pause { &:before { content: $icon-pause; }}
%icon-paw { &:before { content: $icon-paw; }}
%icon-pen-square { &:before { content: $icon-pen-square; }}
%icon-pencil-alt { &:before { content: $icon-pencil-alt; }}
%icon-percent { &:before { content: $icon-percent; }}
%icon-phone-square { &:before { content: $icon-phone-square; }}
%icon-phone-volume { &:before { content: $icon-phone-volume; }}
%icon-phone { &:before { content: $icon-phone; }}
%icon-plane { &:before { content: $icon-plane; }}
%icon-play-circle { &:before { content: $icon-play-circle; }}
%icon-play { &:before { content: $icon-play; }}
%icon-plug { &:before { content: $icon-plug; }}
%icon-plus-circle { &:before { content: $icon-plus-circle; }}
%icon-plus-square { &:before { content: $icon-plus-square; }}
%icon-plus { &:before { content: $icon-plus; }}
%icon-podcast { &:before { content: $icon-podcast; }}
%icon-pound-sign { &:before { content: $icon-pound-sign; }}
%icon-power-off { &:before { content: $icon-power-off; }}
%icon-print { &:before { content: $icon-print; }}
%icon-puzzle-piece { &:before { content: $icon-puzzle-piece; }}
%icon-qrcode { &:before { content: $icon-qrcode; }}
%icon-question-circle { &:before { content: $icon-question-circle; }}
%icon-question { &:before { content: $icon-question; }}
%icon-quidditch { &:before { content: $icon-quidditch; }}
%icon-quote-left { &:before { content: $icon-quote-left; }}
%icon-quote-right { &:before { content: $icon-quote-right; }}
%icon-random { &:before { content: $icon-random; }}
%icon-recycle { &:before { content: $icon-recycle; }}
%icon-redo-alt { &:before { content: $icon-redo-alt; }}
%icon-redo { &:before { content: $icon-redo; }}
%icon-registered { &:before { content: $icon-registered; }}
%icon-reply-all { &:before { content: $icon-reply-all; }}
%icon-reply { &:before { content: $icon-reply; }}
%icon-retweet { &:before { content: $icon-retweet; }}
%icon-road { &:before { content: $icon-road; }}
%icon-rocket { &:before { content: $icon-rocket; }}
%icon-rss-square { &:before { content: $icon-rss-square; }}
%icon-rss { &:before { content: $icon-rss; }}
%icon-ruble-sign { &:before { content: $icon-ruble-sign; }}
%icon-rupee-sign { &:before { content: $icon-rupee-sign; }}
%icon-save { &:before { content: $icon-save; }}
%icon-search-minus { &:before { content: $icon-search-minus; }}
%icon-search-plus { &:before { content: $icon-search-plus; }}
%icon-search { &:before { content: $icon-search; }}
%icon-server { &:before { content: $icon-server; }}
%icon-share-alt-square { &:before { content: $icon-share-alt-square; }}
%icon-share-alt { &:before { content: $icon-share-alt; }}
%icon-share-square { &:before { content: $icon-share-square; }}
%icon-share { &:before { content: $icon-share; }}
%icon-shekel-sign { &:before { content: $icon-shekel-sign; }}
%icon-shield-alt { &:before { content: $icon-shield-alt; }}
%icon-ship { &:before { content: $icon-ship; }}
%icon-shopping-bag { &:before { content: $icon-shopping-bag; }}
%icon-shopping-basket { &:before { content: $icon-shopping-basket; }}
%icon-shopping-cart { &:before { content: $icon-shopping-cart; }}
%icon-shower { &:before { content: $icon-shower; }}
%icon-sign-in-alt { &:before { content: $icon-sign-in-alt; }}
%icon-sign-language { &:before { content: $icon-sign-language; }}
%icon-sign-out-alt { &:before { content: $icon-sign-out-alt; }}
%icon-signal { &:before { content: $icon-signal; }}
%icon-sitemap { &:before { content: $icon-sitemap; }}
%icon-sliders-h { &:before { content: $icon-sliders-h; }}
%icon-smile { &:before { content: $icon-smile; }}
%icon-snowflake { &:before { content: $icon-snowflake; }}
%icon-sort-alpha-down { &:before { content: $icon-sort-alpha-down; }}
%icon-sort-alpha-up { &:before { content: $icon-sort-alpha-up; }}
%icon-sort-amount-down { &:before { content: $icon-sort-amount-down; }}
%icon-sort-amount-up { &:before { content: $icon-sort-amount-up; }}
%icon-sort-down { &:before { content: $icon-sort-down; }}
%icon-sort-numeric-down { &:before { content: $icon-sort-numeric-down; }}
%icon-sort-numeric-up { &:before { content: $icon-sort-numeric-up; }}
%icon-sort-up { &:before { content: $icon-sort-up; }}
%icon-sort { &:before { content: $icon-sort; }}
%icon-space-shuttle { &:before { content: $icon-space-shuttle; }}
%icon-spinner { &:before { content: $icon-spinner; }}
%icon-square-full { &:before { content: $icon-square-full; }}
%icon-square { &:before { content: $icon-square; }}
%icon-star-half { &:before { content: $icon-star-half; }}
%icon-star { &:before { content: $icon-star; }}
%icon-step-backward { &:before { content: $icon-step-backward; }}
%icon-step-forward { &:before { content: $icon-step-forward; }}
%icon-stethoscope { &:before { content: $icon-stethoscope; }}
%icon-sticky-note { &:before { content: $icon-sticky-note; }}
%icon-stop-circle { &:before { content: $icon-stop-circle; }}
%icon-stop { &:before { content: $icon-stop; }}
%icon-stopwatch { &:before { content: $icon-stopwatch; }}
%icon-street-view { &:before { content: $icon-street-view; }}
%icon-strikethrough { &:before { content: $icon-strikethrough; }}
%icon-subscript { &:before { content: $icon-subscript; }}
%icon-subway { &:before { content: $icon-subway; }}
%icon-suitcase { &:before { content: $icon-suitcase; }}
%icon-sun { &:before { content: $icon-sun; }}
%icon-superscript { &:before { content: $icon-superscript; }}
%icon-sync-alt { &:before { content: $icon-sync-alt; }}
%icon-sync { &:before { content: $icon-sync; }}
%icon-table-tennis { &:before { content: $icon-table-tennis; }}
%icon-table { &:before { content: $icon-table; }}
%icon-tablet-alt { &:before { content: $icon-tablet-alt; }}
%icon-tablet { &:before { content: $icon-tablet; }}
%icon-tachometer-alt { &:before { content: $icon-tachometer-alt; }}
%icon-tag { &:before { content: $icon-tag; }}
%icon-tags { &:before { content: $icon-tags; }}
%icon-tasks { &:before { content: $icon-tasks; }}
%icon-taxi { &:before { content: $icon-taxi; }}
%icon-terminal { &:before { content: $icon-terminal; }}
%icon-text-height { &:before { content: $icon-text-height; }}
%icon-text-width { &:before { content: $icon-text-width; }}
%icon-th-large { &:before { content: $icon-th-large; }}
%icon-th-list { &:before { content: $icon-th-list; }}
%icon-th { &:before { content: $icon-th; }}
%icon-thermometer-empty { &:before { content: $icon-thermometer-empty; }}
%icon-thermometer-full { &:before { content: $icon-thermometer-full; }}
%icon-thermometer-half { &:before { content: $icon-thermometer-half; }}
%icon-thermometer-quarter { &:before { content: $icon-thermometer-quarter; }}
%icon-thermometer-three-quarters { &:before { content: $icon-thermometer-three-quarters; }}
%icon-thumbs-down { &:before { content: $icon-thumbs-down; }}
%icon-thumbs-up { &:before { content: $icon-thumbs-up; }}
%icon-thumbtack { &:before { content: $icon-thumbtack; }}
%icon-ticket-alt { &:before { content: $icon-ticket-alt; }}
%icon-times-circle { &:before { content: $icon-times-circle; }}
%icon-times { &:before { content: $icon-times; }}
%icon-tint { &:before { content: $icon-tint; }}
%icon-toggle-off { &:before { content: $icon-toggle-off; }}
%icon-toggle-on { &:before { content: $icon-toggle-on; }}
%icon-trademark { &:before { content: $icon-trademark; }}
%icon-train { &:before { content: $icon-train; }}
%icon-transgender-alt { &:before { content: $icon-transgender-alt; }}
%icon-transgender { &:before { content: $icon-transgender; }}
%icon-trash-alt { &:before { content: $icon-trash-alt; }}
%icon-trash { &:before { content: $icon-trash; }}
%icon-tree { &:before { content: $icon-tree; }}
%icon-trophy { &:before { content: $icon-trophy; }}
%icon-truck { &:before { content: $icon-truck; }}
%icon-tty { &:before { content: $icon-tty; }}
%icon-tv { &:before { content: $icon-tv; }}
%icon-umbrella { &:before { content: $icon-umbrella; }}
%icon-underline { &:before { content: $icon-underline; }}
%icon-undo-alt { &:before { content: $icon-undo-alt; }}
%icon-undo { &:before { content: $icon-undo; }}
%icon-universal-access { &:before { content: $icon-universal-access; }}
%icon-university { &:before { content: $icon-university; }}
%icon-unlink { &:before { content: $icon-unlink; }}
%icon-unlock-alt { &:before { content: $icon-unlock-alt; }}
%icon-unlock { &:before { content: $icon-unlock; }}
%icon-upload { &:before { content: $icon-upload; }}
%icon-user-circle { &:before { content: $icon-user-circle; }}
%icon-user-md { &:before { content: $icon-user-md; }}
%icon-user-plus { &:before { content: $icon-user-plus; }}
%icon-user-secret { &:before { content: $icon-user-secret; }}
%icon-user-times { &:before { content: $icon-user-times; }}
%icon-user { &:before { content: $icon-user; }}
%icon-users { &:before { content: $icon-users; }}
%icon-utensil-spoon { &:before { content: $icon-utensil-spoon; }}
%icon-utensils { &:before { content: $icon-utensils; }}
%icon-venus-double { &:before { content: $icon-venus-double; }}
%icon-venus-mars { &:before { content: $icon-venus-mars; }}
%icon-venus { &:before { content: $icon-venus; }}
%icon-video { &:before { content: $icon-video; }}
%icon-volleyball-ball { &:before { content: $icon-volleyball-ball; }}
%icon-volume-down { &:before { content: $icon-volume-down; }}
%icon-volume-off { &:before { content: $icon-volume-off; }}
%icon-volume-up { &:before { content: $icon-volume-up; }}
%icon-wheelchair { &:before { content: $icon-wheelchair; }}
%icon-wifi { &:before { content: $icon-wifi; }}
%icon-window-close { &:before { content: $icon-window-close; }}
%icon-window-maximize { &:before { content: $icon-window-maximize; }}
%icon-window-minimize { &:before { content: $icon-window-minimize; }}
%icon-window-restore { &:before { content: $icon-window-restore; }}
%icon-won-sign { &:before { content: $icon-won-sign; }}
%icon-wrench { &:before { content: $icon-wrench; }}
%icon-yen-sign { &:before { content: $icon-yen-sign; }}
%icon-address-book2 { &:before { content: $icon-address-book2; }}
%icon-address-card2 { &:before { content: $icon-address-card2; }}
%icon-arrow-alt-circle-down2 { &:before { content: $icon-arrow-alt-circle-down2; }}
%icon-arrow-alt-circle-left2 { &:before { content: $icon-arrow-alt-circle-left2; }}
%icon-arrow-alt-circle-right2 { &:before { content: $icon-arrow-alt-circle-right2; }}
%icon-arrow-alt-circle-up2 { &:before { content: $icon-arrow-alt-circle-up2; }}
%icon-bell-slash2 { &:before { content: $icon-bell-slash2; }}
%icon-bell2 { &:before { content: $icon-bell2; }}
%icon-bookmark2 { &:before { content: $icon-bookmark2; }}
%icon-building2 { &:before { content: $icon-building2; }}
%icon-calendar-alt2 { &:before { content: $icon-calendar-alt2; }}
%icon-calendar-check2 { &:before { content: $icon-calendar-check2; }}
%icon-calendar-minus2 { &:before { content: $icon-calendar-minus2; }}
%icon-calendar-plus2 { &:before { content: $icon-calendar-plus2; }}
%icon-calendar-times2 { &:before { content: $icon-calendar-times2; }}
%icon-calendar2 { &:before { content: $icon-calendar2; }}
%icon-caret-square-down2 { &:before { content: $icon-caret-square-down2; }}
%icon-caret-square-left2 { &:before { content: $icon-caret-square-left2; }}
%icon-caret-square-right2 { &:before { content: $icon-caret-square-right2; }}
%icon-caret-square-up2 { &:before { content: $icon-caret-square-up2; }}
%icon-chart-bar2 { &:before { content: $icon-chart-bar2; }}
%icon-check-circle2 { &:before { content: $icon-check-circle2; }}
%icon-check-square2 { &:before { content: $icon-check-square2; }}
%icon-circle2 { &:before { content: $icon-circle2; }}
%icon-clipboard2 { &:before { content: $icon-clipboard2; }}
%icon-clock2 { &:before { content: $icon-clock2; }}
%icon-clone2 { &:before { content: $icon-clone2; }}
%icon-closed-captioning2 { &:before { content: $icon-closed-captioning2; }}
%icon-comment-alt2 { &:before { content: $icon-comment-alt2; }}
%icon-comment2 { &:before { content: $icon-comment2; }}
%icon-comments2 { &:before { content: $icon-comments2; }}
%icon-compass2 { &:before { content: $icon-compass2; }}
%icon-copy2 { &:before { content: $icon-copy2; }}
%icon-copyright2 { &:before { content: $icon-copyright2; }}
%icon-credit-card2 { &:before { content: $icon-credit-card2; }}
%icon-dot-circle2 { &:before { content: $icon-dot-circle2; }}
%icon-edit2 { &:before { content: $icon-edit2; }}
%icon-envelope-open2 { &:before { content: $icon-envelope-open2; }}
%icon-envelope2 { &:before { content: $icon-envelope2; }}
%icon-eye-slash2 { &:before { content: $icon-eye-slash2; }}
%icon-file-alt2 { &:before { content: $icon-file-alt2; }}
%icon-file-archive2 { &:before { content: $icon-file-archive2; }}
%icon-file-audio2 { &:before { content: $icon-file-audio2; }}
%icon-file-code2 { &:before { content: $icon-file-code2; }}
%icon-file-excel2 { &:before { content: $icon-file-excel2; }}
%icon-file-image2 { &:before { content: $icon-file-image2; }}
%icon-file-pdf2 { &:before { content: $icon-file-pdf2; }}
%icon-file-powerpoint2 { &:before { content: $icon-file-powerpoint2; }}
%icon-file-video2 { &:before { content: $icon-file-video2; }}
%icon-file-word2 { &:before { content: $icon-file-word2; }}
%icon-file2 { &:before { content: $icon-file2; }}
%icon-flag2 { &:before { content: $icon-flag2; }}
%icon-folder-open2 { &:before { content: $icon-folder-open2; }}
%icon-folder2 { &:before { content: $icon-folder2; }}
%icon-frown2 { &:before { content: $icon-frown2; }}
%icon-futbol2 { &:before { content: $icon-futbol2; }}
%icon-gem2 { &:before { content: $icon-gem2; }}
%icon-hand-lizard2 { &:before { content: $icon-hand-lizard2; }}
%icon-hand-paper2 { &:before { content: $icon-hand-paper2; }}
%icon-hand-peace2 { &:before { content: $icon-hand-peace2; }}
%icon-hand-point-down2 { &:before { content: $icon-hand-point-down2; }}
%icon-hand-point-left2 { &:before { content: $icon-hand-point-left2; }}
%icon-hand-point-right2 { &:before { content: $icon-hand-point-right2; }}
%icon-hand-point-up2 { &:before { content: $icon-hand-point-up2; }}
%icon-hand-pointer2 { &:before { content: $icon-hand-pointer2; }}
%icon-hand-rock2 { &:before { content: $icon-hand-rock2; }}
%icon-hand-scissors2 { &:before { content: $icon-hand-scissors2; }}
%icon-hand-spock2 { &:before { content: $icon-hand-spock2; }}
%icon-handshake2 { &:before { content: $icon-handshake2; }}
%icon-hdd2 { &:before { content: $icon-hdd2; }}
%icon-heart2 { &:before { content: $icon-heart2; }}
%icon-hospital2 { &:before { content: $icon-hospital2; }}
%icon-hourglass2 { &:before { content: $icon-hourglass2; }}
%icon-id-badge2 { &:before { content: $icon-id-badge2; }}
%icon-id-card2 { &:before { content: $icon-id-card2; }}
%icon-image2 { &:before { content: $icon-image2; }}
%icon-images2 { &:before { content: $icon-images2; }}
%icon-keyboard2 { &:before { content: $icon-keyboard2; }}
%icon-lemon2 { &:before { content: $icon-lemon2; }}
%icon-life-ring2 { &:before { content: $icon-life-ring2; }}
%icon-lightbulb2 { &:before { content: $icon-lightbulb2; }}
%icon-list-alt2 { &:before { content: $icon-list-alt2; }}
%icon-map2 { &:before { content: $icon-map2; }}
%icon-meh2 { &:before { content: $icon-meh2; }}
%icon-minus-square2 { &:before { content: $icon-minus-square2; }}
%icon-money-bill-alt2 { &:before { content: $icon-money-bill-alt2; }}
%icon-moon2 { &:before { content: $icon-moon2; }}
%icon-newspaper2 { &:before { content: $icon-newspaper2; }}
%icon-object-group2 { &:before { content: $icon-object-group2; }}
%icon-object-ungroup2 { &:before { content: $icon-object-ungroup2; }}
%icon-paper-plane2 { &:before { content: $icon-paper-plane2; }}
%icon-pause-circle2 { &:before { content: $icon-pause-circle2; }}
%icon-play-circle2 { &:before { content: $icon-play-circle2; }}
%icon-plus-square2 { &:before { content: $icon-plus-square2; }}
%icon-question-circle2 { &:before { content: $icon-question-circle2; }}
%icon-registered2 { &:before { content: $icon-registered2; }}
%icon-save2 { &:before { content: $icon-save2; }}
%icon-share-square2 { &:before { content: $icon-share-square2; }}
%icon-smile2 { &:before { content: $icon-smile2; }}
%icon-snowflake2 { &:before { content: $icon-snowflake2; }}
%icon-square2 { &:before { content: $icon-square2; }}
%icon-star-half2 { &:before { content: $icon-star-half2; }}
%icon-star2 { &:before { content: $icon-star2; }}
%icon-sticky-note2 { &:before { content: $icon-sticky-note2; }}
%icon-stop-circle2 { &:before { content: $icon-stop-circle2; }}
%icon-sun2 { &:before { content: $icon-sun2; }}
%icon-thumbs-down2 { &:before { content: $icon-thumbs-down2; }}
%icon-thumbs-up2 { &:before { content: $icon-thumbs-up2; }}
%icon-times-circle2 { &:before { content: $icon-times-circle2; }}
%icon-trash-alt2 { &:before { content: $icon-trash-alt2; }}
%icon-user-circle2 { &:before { content: $icon-user-circle2; }}
%icon-user2 { &:before { content: $icon-user2; }}
%icon-window-close2 { &:before { content: $icon-window-close2; }}
%icon-window-maximize2 { &:before { content: $icon-window-maximize2; }}
%icon-window-minimize2 { &:before { content: $icon-window-minimize2; }}
%icon-window-restore2 { &:before { content: $icon-window-restore2; }}
