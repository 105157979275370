$badge_padding: 1.2rem;

%control_gallery_header_hover {
  &:hover {
    > header {
      @include themify(
        (dark: (
          border-color: $ux_border_color
        )),
        (light: (
          border-color: $black-01
        ))
      );
    }
  }
}

%control_gallery_header {
  > header {
    h2, h3, h4 {
      text-align: left;
    }

    padding: $badge_padding $badge_padding 0;
    border-bottom: 0.1rem solid;

    @include themify(
      (dark: (
        border-color: $black-02
      )),
      (light: (
        border-color: $ux_border_color
      ))
    );

    > img {
      float: left;
      clear: both;
      max-height: 3rem;
      ~ h3, ~ h4 {
        padding-left: 4rem;
      }
    }

    &.icons {
      position: relative;
      padding-left: $badge_padding + 4rem;

      &:before {
        position: absolute;
        top: $badge_padding;
        left: $badge_padding;
        @extend .icon;
        font-size: 2.8rem;
        color: $ux_brand_link_color;
      }
    }

    &.calendar { @extend %icon-calendar-alt2; }
    &.news { @extend %icon-newspaper2; }
    &.blog { @extend %icon-rss-square; }

    &.linked {
      cursor: pointer;
      * { cursor: pointer; }
    }
  }
}

%control_gallery_blockquote {
  > blockquote {
    padding: $badge_padding;
    > div {
      height: 13rem;
    }
  }
}

%control_gallery_footer {
  > footer {
    white-space: normal;
    &.list {
      height: 31rem;
      padding: 0 0 0 $badge_padding !important;
    }
    &:not(.list) {
      padding: 0 $badge_padding $badge_padding !important;
    }
  }
}

%control_gallery_footer_dl {
  > footer {
    dl {
      display: block;
      margin: 1rem 0 2.5rem;
      padding: 0;

      dt {
        display: block;
        margin: 0 0 1rem;
        font-size: 1.2rem;
        font-weight: 300;

        @include themify(
          (dark: (
            color: $white-03
          )),
          (light: (
            color: $black-03
          ))
        );
      }
      dd {
        display: inline-block;
        vertical-align: middle;
        margin: 0 1rem 0 0;
        font-size: $site_base_font_size;
        font-weight: 200;

        @include themify(
          (dark: (
            color: $white-05
          )),
          (light: (
            color: $black-05
          ))
        );
        > var {
          display: inline-block;
          margin-left: 0.6rem;
        }
      }

      &:last-child {
        margin-bottom: 1rem;
      }

      &.stack {
        display: table;
        width: 100%;
        dd {
          display: table-cell;

          label, var {
            display: block;
            margin-left: 0;
            white-space: nowrap;
          }

          var {
            margin-top: 0.6rem;
            font-size: larger;
          }
        }
      }
    }
  }
}

%control_gallery_footer_form {
  > footer {
    form {
      white-space: nowrap;
      > label {

        &[title] {
          &:before {
            display: block;
            width: 100%;
            padding: 0.2rem 0 1rem 0;
            font-size: $site_base_font_size;
            font-weight: 200;
            text-align: left;

            @include themify(
              (dark: (
                color: $white-05
              )),
              (light: (
                color: $black-05
              ))
            );
          }
        }
        > input {
          &[type="text"],
          &[type="submit"] {
            vertical-align: middle;
          }

          &[type="text"] {
            display: inline-block;
            width: 70% !important;
            @include border-radius(0.2rem 0 0 0.2rem);
            border-right-width: 0;
          }
          &[type="submit"] {
            display: inline-block;
            width: 30%;
            @include border-radius(0 0.2rem 0.2rem 0);
            @extend .success;
          }
        }
      }
    }
  }
}

%control_gallery_footer_table {
  > footer {
    > table {
      @extend .well;
      @extend .fixed-height;
      @extend .fixed-height-20;
    }
  }
}

%control_gallery_footer_ul {
  > footer {
    &.list {
      > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        > li {
          padding: 1.6rem 1.6rem 1.6rem 0;
          h5 {
            margin: 0 0 0.8rem;
            padding: 0;
            font-size: 1.2rem;
            font-weight: 300;

            > a {
              color: $ux_brand_link_color;
              &:hover {
                color: mix(white, $ux_brand_link_color, 20%);
              }
            }
          }
          div {
            font-size: 1.1rem;
            font-weight: 200;
            @include themify(
              (dark: (
                color: $white-06
              )),
              (light: (
                color: $black-06
              ))
            );
          }
        }
      }
    }
  }
}

%control_gallery_iframe {
  > iframe {
    height: 32rem !important;
    overflow-y: auto;
  }
}

%control_gallery {
  &.control-gallery {
    > li {
      @include flex-wrap-element(33%, unset, 35rem);

      > article {
        height: unset !important;
        min-height: 37rem !important;
        max-height: 40rem !important;
        text-align: left !important;
        white-space: nowrap;

        @include no-select();

        @include border(1px solid transparent, false);
        @include border-radius(0.5rem);

        @include themify(
          (dark: (
            background-color: $white-003
          )),
          (light: (
            background-color: $black-005
          ))
        );

        @extend %control_gallery_header;
        @extend %control_gallery_header_hover;
        @extend %control_gallery_blockquote;
        @extend %control_gallery_footer;
        @extend %control_gallery_footer_dl;
        @extend %control_gallery_footer_form;
        @extend %control_gallery_footer_table;
        @extend %control_gallery_footer_ul;
        @extend %control_gallery_iframe;


        &:hover {
          @include themify(
            (dark: (
              color: $white-1,
              background-color: $white-01,
              border-color: $ux_border_color
            )),
            (light: (
              color: $black-1,
              background-color: $black-01,
              border-color: $black-01
            ))
          );
        }
      }

      @include mq(laptop) {
        @include flex-wrap-element(33%, unset, 35rem);
      }

      @include mq(tablet) {
        @include flex-wrap-element(100%, 28rem, unset);
      }
    }
  }
}

