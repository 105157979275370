$header_margin_top_default: 2rem !default;
$header_margin_bottom_default: 1rem !default;

$h2_font_size: 2.8rem !default;
$h3_font_size: 2.2rem !default;
$h4_font_size: 1.8rem !default;
$h5_font_size: 1.5rem !default;

h1 {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 100;
  color: transparent;
  overflow: hidden;

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
    font-size: inherit;
    font-weight: inherit;
    color: inherit !important;
  }
}

%main_section_article_headlines {
  h2, h3, h4, h5 {
    margin-top: $header_margin_top_default;
    &:not(.col) {
      margin-bottom: $header_margin_bottom_default;
    }
    &.col {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: $h2_font_size;
    font-weight: 300;
    @include themify(
      (dark: (
        color: $white-08
      )),
      (light: (
        color: $black-08
      ))
    );
  }

  h3 {
    font-size: $h3_font_size;
    font-weight: 200;
    @include themify(
      (dark: (
        color: $white-08
      )),
      (light: (
        color: $black-08
      ))
    );
  }

  h4 {
    font-size: $h4_font_size;
    font-weight: 200;
    @include themify(
      (dark: (
        color: $white-08
      )),
      (light: (
        color: $black-08
      ))
    );
  }

  h5 {
    font-size: $h5_font_size;
    font-weight: 300;
    margin-bottom: 0.5rem;
    @include themify(
      (dark: (
        color: $white-08
      )),
      (light: (
        color: $black-08
      ))
    );

    + small {
      display: block;
      margin: 0 0 1rem;
    }
  }

  h6 {
    font-size: $site_base_font_size;
    font-weight: 300;
    @include themify(
      (dark: (
        color: $white-08
      )),
      (light: (
        color: $black-08
      ))
    );
  }
}
