@mixin y-scale-element() {
  @include transform-origin(top);
  @include transitions(transform, height);
  > * {
    @include transform-origin(top);
    @include transitions(transform, opacity);
  }

  &:not(.active) {
    height: 0;
    @include transform(scaleY(0));
    @include animation(do-vertical-collapse 500ms linear forwards);
    > * {
      opacity: 0;
      @include transform(scaleY(0));
      @include animation(do-vertical-collapse 500ms linear forwards);
    }
  }

  &.active {
    @include transform(scaleY(1));
    @include animation(do-vertical-expand 500ms linear forwards);
    > * {
      opacity: 1;
      @include transform(scaleY(1));
      @include animation(do-vertical-expand 500ms linear forwards);
    }
  }
}

@mixin y-scale-selector($selector, $speed: 500ms) {
  #{$selector} {
    @include transform-origin(top);
    @include transitions(transform, opacity, height);
  }
  &:not(.active) {
    #{$selector} {
      height: 0;
      opacity: 0;
      @include transform(scaleY(0));
      @include animation(do-vertical-collapse $speed linear forwards);
    }
  }
  &.active {
    #{$selector} {
      opacity: 1;
      @include transform(scaleY(1));
      @include animation(do-vertical-expand $speed linear forwards);
    }
  }
}

@mixin y-scale-selector-opposite-hover($selector, $speed: 500ms) {
  #{$selector} {
    @include transform-origin(top);
    @include transitions(transform, opacity, height);
  }

  &:hover {
    &:not(.active) {
      #{$selector} {
        opacity: 1;
        @include transform(scaleY(1));
        @include animation(do-vertical-expand $speed linear forwards);
      }
    }
  }
  &:not(:hover) {
    &:not(.active) {
      #{$selector} {
        height: 0;
        opacity: 0;
        @include transform(scaleY(0));
        @include animation(do-vertical-collapse $speed linear forwards);
      }
    }
  }

  &.active {
    #{$selector} {
      height: 0;
      opacity: 0;
      @include transform(scaleY(0));
      @include animation(do-vertical-collapse $speed linear forwards);
    }
  }
}

@mixin x-scale-selector($selector) {
  #{$selector} {
    @include transform-origin(left);
    @include transitions(transform, opacity, width);
  }
  &:not(.active) {
    #{$selector} {
      width: 0;
      opacity: 0;
      @include transform(scaleX(0));
      @include animation(do-horizontal-collapse 500ms linear forwards);
    }
  }
  &.active {
    #{$selector} {
      opacity: 1;
      @include transform(scaleX(1));
      @include animation(do-horizontal-expand 500ms linear forwards);
    }
  }
}

@mixin wipe-element-rtl() {
  @include transitions(transform, width);
  > * {
    @include transitions(transform, opacity);
  }

  &:not(.exit) {
    @include transform-origin(left);
    @include animation(do-horizontal-expand 250ms linear forwards);
    > * {
      opacity: 1;
      @include transform-origin(left);
      @include animation(do-horizontal-expand 250ms linear forwards);
    }
  }

  &.exit {
    @include transform-origin(left);
    @include animation(do-horizontal-collapse 250ms linear forwards);
    > * {
      opacity: 0;
      white-space: nowrap;
      @include transform-origin(left);
      @include animation(do-horizontal-collapse 250ms linear forwards);
    }
  }
}

@mixin wipe-element-ltr() {
  @include transitions(transform, width);
  > * {
    @include transitions(transform, opacity);
  }

  &:not(.exit) {
    @include transform-origin(right);
    @include animation(do-horizontal-expand 250ms linear forwards);
    > * {
      opacity: 1;
      @include transform-origin(right);
      @include animation(do-horizontal-expand 250ms linear forwards);
    }
  }

  &.exit {
    @include transform-origin(right);
    @include animation(do-horizontal-collapse 250ms linear forwards);
    > * {
      opacity: 0;
      white-space: nowrap;
      @include transform-origin(right);
      @include animation(do-horizontal-collapse 250ms linear forwards);
    }
  }
}

@mixin vertical-reveal($from: top, $duration: 500ms, $timing-function: ease) {
  @include transform-origin($from);
  &:not(.active):not(.inactive) {
    @include transform(scaleY(0));
  }
  &.active {
    @include animation(do-vertical-expand $duration $timing-function forwards);
  }
  &.inactive {
    @include animation(do-vertical-collapse $duration $timing-function forwards);
  }
}

@mixin fade-toggle() {
  @include transform-origin(top);
  @include transitions(opacity, height);
  > * {
    @include transform-origin(top);
    @include transitions(height, margin, padding);
  }
  &:not(.active) {
    position: fixed;
    height: 0;
    opacity: 0;
    @include transform(scaleY(0));
    > * {
      height: 0;
      margin: 0;
      padding: 0;
      @include transform(scaleY(0));
    }
  }
  &.active {
    height: auto;
    opacity: 1;
    @include transform(scaleY(1));
  }
}

@mixin progress-bar($background-color: transparent) {
  &::-webkit-progress-value {
    background-color: $background-color;
  }
  &::-moz-progress-bar {
    background-color: $background-color;
  }
  &::-o-progress-bar {
    background-color: $background-color;
  }
  &::progress-bar {
    background-color: $background-color;
  }
}

@mixin progress-bars-percent($min, $max) {
  $grad: null;
  $color: null;
  @for $i from 1 through 100 {
    $grad: $i / 100;
    &[value="#{$grad}"] {
      $color: mix($min, $max, 100% * $i/100);
      @if $i > 1 and $i < 100 {
        $color: saturate($color, 50);
      }
      @include progress-bar($color);
    }
  }
}
