%div_tables_cells {
  display: table-cell;
  padding: 0.2rem 0.5rem 0.2rem 0;
  line-height: normal;
  white-space: nowrap;

  @include mq(tablet) {
    display: inline-block;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;

    &.empty {
      display: none;
    }

    &:not([role]) {
      display: inline-block;
      vertical-align: middle;
      padding-left: 10.5rem;
      + div:not([role]) {
        padding-left: 1rem;
      }
    }

    &[role] {
      display: block;
      white-space: nowrap;

      &:before {
        content: attr(role);
        display: inline-block;
        vertical-align: middle;
        min-width: 10rem;
        margin: 0.1rem 0.3rem 0 0;
        padding: 0.1rem 0.4rem 0.2rem 1rem;
        font-size: 1rem;
        font-weight: 300;
        text-transform: capitalize;

        @include themify(
          (dark: (
            color: $black-1,
            background-color: $white-03
          )),
          (light: (
            color: $white-1,
            background-color: $black-03
          ))
        );
      }
    }
    &:first-child {
      &[role] {
        &:before {
          margin-top: 0;
        }
      }
    }
  }
}

%div_tables_header_cells {
  font-size: 1.2rem;
  font-weight: 300;
  text-transform: capitalize;
  @include themify(
    (dark: (
      color: $white-05
    )),
    (light: (
      color: $black-05
    ))
  );
}

%div_tables_body_cells {
  font-size: 1.1rem;
  font-weight: 300;
}

%div_tables_head {
  display: table-header-group;
  > div {
    @extend %div_tables_cells;
    @extend %div_tables_header_cells;
  }
  @include mq(tablet) {
    display: none;
  }
}

%div_tables_rows {
  display: table-row;
  > div {
    @extend %div_tables_cells;
    @extend %div_tables_body_cells;
  }
  @include mq(tablet) {
    display: block;
    margin-bottom: 0.4rem;
    border-width: 1px;
    border-style: solid;

    @include themify(
      (dark: (
        border-color: $ux_border_color
      )),
      (light: (
        border-color: $black-03
      ))
    );
  }
}

%div_tables_body {
  display: table-row-group;
  > div {
    @extend %div_tables_rows;
  }
  @include mq(tablet) {
    display: block;
  }
}

%div_tables_caption {
  display: table-caption;
  padding: 1.5rem 0 0 1rem;
  font-size: 1.4rem;
  text-align: left;
  @include border-radius(0.3rem 0.3rem 0 0);
  @include box-shadow-themed(0 0 0.3rem $white-01, 0 0 0.3rem $black-01);
  @include mq(tablet) {
    display: block;
    padding: 0 0 1rem;
  }
}

%div_tables {
  display: table;
  margin: 1rem 0;
  padding: 1rem;

  @include border-radius(0 0 0.3rem 0.3rem);
  @include box-shadow-themed(0 0 0.3rem $white-01, 0 0 0.3rem $black-01);

  @include themify(
    (dark: (
      background-color: $black-08
    )),
    (light: (
      background-color: $black-005
    ))
  );

  > div {
    &.caption { @extend %div_tables_caption; }
    &.head { @extend %div_tables_head; }
    &.body { @extend %div_tables_body; }

    @include themify(
      (dark: (
        color: $white-05,
        background-color: $black-08
      )),
      (light: (
        color: $black-05,
        background-color: $black-005
      ))
    );
  }

  @include mq(tablet) {
    display: block;
    width: 96%;
    margin-right: 2%;
    margin-left: 2%;
  }
}

%circle_icons {
  position: relative;
  width: 3.2rem;
  height: 3.2rem;
  color: $white-1;
  @include border-radius(1.7rem);

  &:before {
    @extend .icon;
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    padding-top: 0.8rem;
    text-align: center;
  }

  &.complete {
    background-color: $ux_primary_color;
    @extend %icon-check;
  }

  &.inprogress {
    background-color: $ux_info_color;
    @extend %icon-hourglass-start;
  }

  &.failed {
    background-color: $ux_danger_color;
    @extend %icon-exclamation-triangle;
    &:before {
      padding-top: 0.6rem;
    }
  }
}

div {
  &.tables {
    @extend %div_tables;
  }

  &.circle-icon {
    @extend %circle_icons;
  }
  &.inverse-z {
    > * {
      @include zindex-children(0, 9, true);
    }
  }
  &.legend {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 1.6rem;
    &:first-child {
      margin-left: 0;
    }

    &:before {
      content: attr(legend);
      font-size: 1.6rem;
      font-weight: 400;
      border-width: 1px 0 0;
      border-style: solid none none;

      @include themify(
        (dark: (
          color: $white-03,
          border-top-color: $white-01
        )),
        (light: (
          color: $black-03,
          border-top-color: $black-005
        ))
      );
    }
  }
}
