@import './fusion-auth0-lock';
@import './fusion-wrapper';
@import './auth0-lock';
@import './wrapper';

main {
  section {
    &.auth {
      @extend %flex-container-vertical;

      > header {

        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-box-flex: 0;
        -moz-box-flex: 0;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;

        padding: 0;

        @include mq(desktop) {
          -webkit-flex: 0 0 15rem;
          -ms-flex: 0 0 15rem;
          flex: 0 0 15rem;
        }

        @include mq(laptop) {
          -webkit-flex: 0 0 10rem;
          -ms-flex: 0 0 10rem;
          flex: 0 0 10rem;
        }

        @include mq(tablet) {
          -webkit-flex: 0 0 5rem;
          -ms-flex: 0 0 5rem;
          flex: 0 0 5rem;
        }

        > h1 {
          @include transitions(width, margin);
          @include mq(desktop) {
            max-width: 100rem;
            margin: 10rem auto 2rem;
          }
          @include mq(laptop) {
            max-width: 75rem;
            margin: 5rem auto 1rem;
          }
          @include mq(tablet) {
            max-width: 28rem;
            margin: 1rem 0 0.8rem 2rem;
          }
        }

      }
      > article {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;

        margin: 0;
        padding: 0;

        @include mq(desktop, laptop) {
          -webkit-box-ordinal-group: 2;
          -moz-box-ordinal-group: 2;
          -webkit-order: 1;
          -ms-flex-order: 1;
          order: 1;
        }

        @include mq(tablet) {
          -webkit-box-ordinal-group: 1;
          -moz-box-ordinal-group: 1;
          -webkit-order: 0;
          -ms-flex-order: 0;
          order: 0;
        }

        > div {
          @extend %flex-container-horizontal;

          @include transitions(width, margin);
          @include mq(desktop) {
            max-width: 100rem;
            margin: 10rem auto 2rem;
          }
          @include mq(laptop) {
            max-width: 75rem;
            margin: 5rem auto 1rem;
          }

          > div {
            -webkit-align-self: stretch;
            -ms-flex-item-align: stretch;
            align-self: stretch;

            &.auth-main {
              h2 {
                margin-top: 0;
                font-size: 5rem;
                font-weight: 200;
                @include no-select();

                @include themify(
                  (dark: (
                    color: $white-09
                  )),
                  (light: (
                    color: $black-09
                  ))
                );
              }

              li {
                font-size: 2.2rem;
                line-height: 1.5;
              }

              padding-right: 3rem;

              @include mq(tablet) {
                display: none;
              }
              @include mq(desktop, laptop) {
                -webkit-box-ordinal-group: 1;
                -moz-box-ordinal-group: 1;
                -webkit-order: 0;
                -ms-flex-order: 0;
                order: 0;
                -webkit-box-flex: 1;
                -moz-box-flex: 1;
                -webkit-flex: 1 1 50%;
                -ms-flex: 1 1 50%;
                flex: 1 1 50%;
              }
            }

            &.auth-form {
              -webkit-box-ordinal-group: 2;
              -moz-box-ordinal-group: 2;
              -webkit-order: 1;
              -ms-flex-order: 1;
              order: 1;
              -webkit-box-flex: 1;
              -moz-box-flex: 1;
              -webkit-flex: 1 1 auto;
              -ms-flex: 1 1 auto;
              flex: 1 1 auto;

              @extend %auth0_lock;
              @extend %auth_wrapper;
            }
          }
        }
      }
    }
  }

  .fusion-auth-form {
    min-height: 26.8rem;
    background-color: $ux_page_base_color;
    @include border-radius(1.6rem);
    overflow: hidden;
    color: #fff;

    @extend %fusion-auth0_lock;
    @extend %fusion_auth_wrapper;
  }  
}
