$checkable_size: $form_element_size !default;
$checkable_box_shadow_1: 0 0 0.3rem 0.1rem $black-03 inset !default;
$checkable_box_shadow_2: 0 0 0.2rem 0.1rem $black-01 inset !default;
$checkable_box_shadow_invalid_1: 0 0 0.3rem 0.1rem $ux_danger_color inset !default;
$checkable_box_shadow_invalid_2: 0 0 0.3rem 0.1rem $ux_danger_color inset !default;

%checkable_base {
  background-size: cover;
  background-position: center;
  background-size: 2.4rem;
  overflow: hidden;
  @include border(1px solid, false);
  @include border-radius(0.2rem);
  @include transitions(background-color);
  @include themify(
    (dark: (
      border-color: $dark-form-input-border-color,
      background-color: $dark-form-input-background-color
    )),
    (light: (
      border-color: $light-form-input-border-color,
      background-color: $light-form-input-background-color
    ))
  );
}

%checkable_disabled {
  cursor: not-allowed;
}

%checkable_disabled_background {
  @include themify(
    (dark: (
      background-color: $white-02
    )),
    (light: (
      background-color: $black-01
    ))
  );
}

%checkable_disabled_checked_background {
  background-image: url(svg-encode(svg-factory($svg-check, $white-03)));
}

%checkable_not_disabled_checked {
  background-color: $ux_primary_color !important;
  background-image: url(svg-encode(svg-factory($svg-check, $white-1)));
}

%checkable_not_disabled_not_checked_hover {
  background-color: $ux_primary_color;
  background-image: url(svg-encode(svg-factory($svg-check, $white-03)));
  @include themify(
    (dark: (
      border-color: $dark-form-input-border-color,
      background-color: $white-02
    )),
    (light: (
      border-color: $light-form-input-border-color,
      background-color: $black-02
    ))
  );
}
