
%main_section_blockquote {
  blockquote {
    position: relative;
    min-height: 3rem;
    margin: 1rem 0;
    padding: 1rem 1rem 1rem 2rem;
    font-size: 1.4rem;
    font-weight: 200;
    line-height: normal;

    @include border(1px solid transparent, false);

    border-left-width: 0.3rem;

    @include themify(
      (dark: (
        color: $white-06,
        border-color: $ux_border_color,
        background-color: $black-005
      )),
      (light: (
        color: $black-06,
        border-color: $black-01,
        background-color: $black-001
      ))
    );

    > p {
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
    }

    > ul {
      padding: 0 0 0 2rem;
      > li {
        font-size: 1.2rem;
        font-weight: 200;

        @include themify(
          (dark: (
            color: $white-08,
          )),
          (light: (
            color: $black-08,
          ))
        );
      }
    }

    &.success { border-left-color: $ux_success_color !important; }
    &.info { border-left-color: $ux_info_color !important; }
    &.warning { border-left-color: $ux_warning_color !important; }
    &.danger { border-left-color: $ux_danger_color !important; }
    &.accent { border-left-color: $ux_accent_base_color !important; }
    &.trivial { border-left-color: $ux_neutral_color !important; }

    &.iconz {
      padding-left: 4rem;
      &:before {
        position: absolute;
        top: 1.8rem;
        left: 1.5rem;
      }

      &.info { @extend %icon-info-circle;color: $ux_info_color; }
      &.warning { @extend %icon-exclamation-triangle;color: $ux_info_color; }
      &.danger { @extend %icon-bolt;color: $ux_info_color; }
    }

    > h2, > h3, > h4, > h5 {
      &:first-child {
        margin-top: 0.5rem;
      }
    }
    > header {
      margin: 0 0 1.8rem;
    }

    span {
      &.bright {
        @include themify(
          (dark: (
            color: $white-09,
          )),
          (light: (
            color: $black-09,
          ))
        );
      }
    }
  }
}
