%article_header_row {
  @extend .row;
  > h2 {
    @extend .col;
  }

  > div {
    @extend .col;
    @extend .col-auto;
    @extend .pull-right;

    padding-right: 0;

    > div {
      display: inline-block;
      vertical-align: middle;
      margin-left: 0;
      > form {
        margin-left: 1rem;
      }
      .input-label {
        padding-top: 0 !important;
        margin-bottom: 0 !important;
        margin-right: 0 !important;
        &.button {
          margin-left: 1rem;
        }
        &.radio {
          > label {
            margin: 0 0 0 1rem;
          }
        }
      }
      .form-group {
        margin-bottom: 0;
        .input-label {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
}

%main_section_article {
  > article {
    position: relative;
    @include zindex-children(300, 10, true);

    > .row {
      > .col {
        &:first-child {
          padding-left: 0 !important;
        }
      }
    }
  }

  article {
    @extend %main_section_article_dl;
    @extend %main_section_article_headlines;
    @extend %main_section_article_paragraphs;
    @extend %main_section_article_ul;
    @extend %main_section_article_ol;
    @extend %main_section_article_anchors;
    @extend %main_section_article_code;
    @extend %main_section_article_span;

    @include themify(
      (dark: (
        color: $white-1
      )),
      (light: (
        color: $black-1
      ))
    );

    > p {
      line-height: 2.2rem;
    }

    &.buffer {
      padding-top: $site_vertical_buffer;
      padding-bottom: $site_vertical_buffer;
    }

    &.header-buffer {
      padding-bottom: $site_vertical_buffer;
    }

    > header {
      position: relative;
      margin-top: 2rem;
      margin-bottom: 0;
      padding-right: 0;
      padding-left: 0;

      > h2, > h3, > h4, > h5 {
        margin-bottom: 0;
      }
      > h2, > h3 {
        margin-top: 0;
      }

      > h4, > h5 {
        margin-top: 0.8rem;
      }

      @include mq(tablet) {
        margin-top: 1.5rem;
      }

      &.article-header-row {
        @extend %article_header_row;
      }
    }

    &:last-child {
      margin-bottom: $site_vertical_buffer * 2;
    }

    > div {
      div {
        h2, h3, h4 {
          margin-top: 1.4rem;
          margin-bottom: 0;
        }
      }
    }
  }
}

