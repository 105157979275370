%dl_table_base {
  margin: 0;
  padding: 0;
  > dt, > dd {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 0;
  }

  dt {
    font-size: 85%;
    line-height: 1.5;
    text-align: right;
    white-space: nowrap;

    &:after {
      content: ':';
      display: inline-block;
      vertical-align: top;
      margin: 0 0.3rem;
    }
  }

  @include mq(tablet) {
    > dt, > dd {
      display: block;
    }


    dt {
      text-align: left;

      &:after {
        content: '';
        margin: 0;
      }
    }
    dd {
      margin-top: 0.2rem;
      padding-top: 0.3rem;
      border-top: 0.1rem solid;

      @include themify(
        (dark: (
          border-color: $ux_border_color
        )),
        (light: (
          border-color: $black-005
        ))
      );
    }
  }
}

%definition_list_detail {
  vertical-align: top;
  min-width: 14rem;
  margin: 0 2rem 2rem 0;
  padding: 0;

  dt, dd {
    display: block;
    margin: 0 0 0.5rem;
  }

  dt {
    font-size: 1.3rem;
    font-weight: 400;
    @include themify(
      (dark: (
        color: $white-1
      )),
      (light: (
        color: $black-1
      ))
    );
  }

  dd {
    padding: 0.5rem 0 1.5rem;
    font-size: 1.3rem;
    font-weight: 300;

    @include themify(
      (dark: (
        color: $white-08
      )),
      (light: (
        color: $black-08
      ))
    );
  }
}

%main_section_article_dl {
  dl {
    &.detail {
      @extend %definition_list_detail;
    }
  }
}

%definition_list_base {
  dl {
    margin: 0;
    padding: 0;

    dt, dd {
      display: list-item;
      margin: 0;
    }

    dt {
      padding: 1.5rem;
      font-size: 1rem;
      font-weight: 400;
      text-transform: uppercase;

      @include themify(
        (dark: (
          color: #8c8c8c
        )),
        (light: (
          color: #8c8c8c
        ))
      );

      > a {
        &.float-right {
          float: right;
          text-transform: none;
          @include themify(
            (dark: (
              color: $white-03
            )),
            (light: (
              color: $black-03
            ))
          );
          &:hover {
            @include themify(
              (dark: (
                color: $white-06
              )),
              (light: (
                color: $black-06
              ))
            );
          }
        }
      }
    }

    dd {
      font-size: 1.4rem;
      font-weight: 300;
      text-transform: capitalize;

      &.divider {
        border-width: 1px 0 0;
        border-style: solid none none;

        @include themify(
          (dark: (
            border-color: $ux_border_color transparent transparent
          )),
          (light: (
            border-color: $black-005 transparent transparent
          ))
        );
      }

      @include themify(
        (dark: (
          color: #8c8c8c
        )),
        (light: (
          color: #8c8c8c
        ))
      );

      > span, > a {
        display: block;
        padding: 1.5rem 0;
      }

      > a {
        font-size: 1.2rem;
        font-weight: 400;

        @include themify(
          (dark: (
            color: $ux_alternate_brand_link_color
          )),
          (light: (
            color: #8c8c8c
          ))
        );

        &:hover {
          @include themify(
            (dark: (
              color: #fff,
              background-color: $white-01
            )),
            (light: (
              color: #333,
              background-color: $black-01
            ))
          );
        }
      }
    }
  }
}

%definition_list_sub_nav {

  @extend %definition_list_base;

  dl {
    position: absolute;
    top: 100%;
    left: 0;
    overflow: hidden;

    border-width: 0 0.1rem 0.1rem;
    border-style: none solid solid;

    @include border-radius(0 0 $form_element_border_radius $form_element_border_radius);
    @include boxshadow(0.5rem 0 1rem $black-01);

    @include themify(
      (dark: (
        background-color: $dark-header-nav-background-color,
        border-color: transparent $white-01 $white-01
      )),
      (light: (
        background-color: $light-header-nav-background-color,
        border-color: transparent $black-01 $black-01
      ))
    );

    dt {
      border-width: 0 0 0.1rem;
      border-style: none none solid;

      @include themify(
        (dark: (
          border-color: transparent transparent $white-01
        )),
        (light: (
          border-color: transparent transparent $black-01
        ))
      );
    }
    dd {
      @extend %header_nav_menu_spans_anchors;
      overflow: hidden;

      > a {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 3rem;
        overflow: hidden;
        font-weight: 400 !important;
      }
    }

    @include mq(tablet) {
      position: relative;
      top: unset;
      right: unset;
      border-width: 0;
      border-style: none;
      border-color: transparent;
    }
  }
}

%definition_list_notifications {

  @extend %definition_list_base;

  dl {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 35rem;

    border-width: 0 0.1rem 0.1rem;
    border-style: none solid solid;

    @include boxshadow(0.5rem 0 1rem $black-01);

    @include themify(
      (dark: (
        background-color: $dark-header-nav-background-color,
        border-color: transparent $white-01 $white-01
      )),
      (light: (
        background-color: $light-header-nav-background-color,
        border-color: transparent $black-01 $black-01
      ))
    );

    dt {
      border-width: 0 0 0.1rem;
      border-style: none none solid;

      @include themify(
        (dark: (
          border-color: transparent transparent $white-01
        )),
        (light: (
          border-color: transparent transparent $black-01
        ))
      );
    }
    dd {
      text-align: center;

      &:nth-child(2) {
        @extend %scrollable_list;
      }

      &:nth-child(3) {
        > a {
          @extend .anchor_icon;
          &:after {
            content: $icon-chevron-right;
            vertical-align: middle;
          }
        }
      }
    }

    @include mq(tablet) {
      position: relative;
      top: unset;
      right: unset;
      z-index: unset;
    }
  }
}

%definition_list_user_menu {
  dl {
    left: unset;
    right: 0;
    overflow: hidden;

    border-width: 0 0 0.1rem 0.1rem;
    border-style: none none solid solid;

    @include border-radius(0 0 0 $form_element_border_radius);
    @include boxshadow(0.5rem -0.5rem 1rem $black-02);

    @include themify(
      (dark: (
        border-color: transparent transparent $white-02 $white-02
      )),
      (light: (
        border-color: transparent transparent $black-02 $black-02
      ))
    );
  }
}


dialog {
  dl.tabs {
    > dd {
      overflow-x: hidden;
      overflow-y: auto;
    }
    &.h-20 { > dd { height: 20rem; }}
    &.h-30 { > dd { height: 30rem; }}
    &.h-40 { > dd { height: 40rem; }}
    &.h-50 { > dd { height: 50rem; }}
    &.h-60 { > dd { height: 60rem; }}
  }
}

%dl_table_full {
  margin: 0.2rem 0 0.2rem;
  padding: 0.3rem 0 0.5rem;
  border-width: 0 0 0.1rem;
  border-style: none none solid;
  white-space: nowrap;

  @include themify(
    (dark: (
      border-bottom-color: $white-02
    )),
    (light: (
      border-bottom-color: $black-02
    ))
  );

  > dt, > dd {
    line-height: normal;
    white-space: normal;
  }

  > dt {
    width: 25%;
    font-size: 1.2rem;
    text-transform: uppercase;
    @include themify(
      (dark: (
        color: $white-08
      )),
      (light: (
        color: $black-08
      ))
    );
  }
  > dd {
    font-size: 1.1rem;
    @include themify(
      (dark: (
        color: $white-1
      )),
      (light: (
        color: $black-1
      ))
    );
  }
}


dl {
  &.table {
    @extend %dl_table_base;

    &.full {
      @extend %dl_table_full;
    }
  }
}
