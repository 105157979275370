@mixin iconz {
  font-family: 'font-awesome' !important;
  font-weight: 100;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin mq($media...) {
  @for $i from 1 through length($media) {
    @if nth($media, $i) == desktop {
      @media #{$media-query-desktop} { @content; }
    }
    @else if nth($media, $i) == laptop {
      @media #{$media-query-laptop} { @content; }
    }
    @else if nth($media, $i) == tablet {
      @media #{$media-query-tablet} { @content; }
    }
  }
}

@mixin themify($params...) {

  $selector: null;
  @each $selectors in & {
    @if nth($selectors, 1) == 'html' {
      $selector: nth($selectors, 2);
    } @else {
      $selector: $selectors;
    }
  }
  @each $param in $params {
    @each $type, $map in $param {
      @at-root html.theme-#{$type} {
        #{$selector} {
          @each $prop, $value in $map {
            #{$prop}: $value;
          }
        }
      }
    }
  }
}

@mixin box-shadow-themed($dark, $light, $dark2:null, $light2:null) {
  @if $dark2 & $light2 {
    $dark: $dark, $dark2;
    $light: $light, $light2;
  }
  @include themify(
    (dark: (
      -webkit-box-shadow: $dark,
         -moz-box-shadow: $dark,
              box-shadow: $dark
    )),
    (light: (
      -webkit-box-shadow: $light,
         -moz-box-shadow: $light,
              box-shadow: $light
    ))
  );
}
