%header_form {
  display: none;
  position: absolute;
  top: 0;
  right: 0;

  label {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.5rem;
    margin-right: 1.4rem;

    @include icon-button {
      display: block;
      width: 6rem;
      height: 4rem;

      @include border(1px solid, false);

      @extend %button_icon;
      @extend %button_icon_double;

      @include themify(
        (dark: (
          color: #8c8c8c,
          border-color: #333
        )),
        (light: (
          color: #ccc,
          border-color: #ccc
        ))
      );

      > i {
        display: inline-block;
        vertical-align: middle;

        &:nth-child(1) {
          margin: 0 !important;
          padding: 0;
          overflow: hidden;
          @extend %icon-chevron-right;
          &:before {
            line-height: 1.2em;
            @include transitions(transform);
          }
        }
        &:nth-child(2) {
          font-size: 1.2rem;
          margin-left: 0.4rem;
          line-height: normal;
          @extend %icon-bars;
        }
      }
      &:hover {
        @include themify(
          (dark: (
            background-color: $ux_accent_base_color,
          )),
          (light: (
            background-color: $black-005
          ))
        );
      }
      &.active {
        > i {
          &:nth-child(1) {
            &:before {
              @include transform(rotate(90deg));
            }
          }
        }
      }
      &[disabled] {
        cursor: not-allowed;

        @include themify(
          (dark: (
            color: $white-02,
            border-color: $ux_border_color,
            background-color: transparent
          )),
          (light: (
            color: $black-02,
            border-color: $black-02,
            background-color: transparent
          ))
        );

        &:hover {
          @include themify(
            (dark: (
              color: $white-02,
              border-color: $ux_border_color,
              background-color: transparent
            )),
            (light: (
              color: $black-02,
              border-color: $black-02,
              background-color: transparent
            ))
          );
        }
      }
    }
  }

  @include mq(tablet) {
    display: block;
  }
}
