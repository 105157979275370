

@mixin background-image($src:'', $position:center, $size:cover, $bgcolor:rgba(0,0,0,0)) {
  @if $src == '' {
    $src:'../../../../assets/images/empty.png';
  }
  background-image: url($src);
  background-position: $position;
  background-size: $size;
  background-color: $bgcolor;
  background-repeat: no-repeat;
}

@mixin border($params, $bool:true) {
  border: $params;
  @if $bool {
    outline: $params;
  } @else {
    outline: 0 none transparent;
  }
}

@mixin borders($width:0, $style:none, $color:transparent) {
  border-width: $width;
  border-style: $style;
  border-color: $color;
  outline: 0 none transparent;
}
