@import 'lists';

%calendar_main {
  > div {
    position: absolute;
    top: 3.6rem;
    left: 0;
    z-index: 1;
    margin: 0;
    padding: 1rem 1rem 1rem 0;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    @include border-radius(0.3rem);
    @include boxshadow(0 0 0.3rem $black-05);

    @include transform-origin(top);
    @include transitions(transform, opacity);

    @include themify(
      (dark: (
        background-color: $dark-header-nav-background-color,
        border-color: $dark-form-input-border-color
      )),
      (light: (
        background-color: $light-header-nav-background-color,
        border-color: $light-form-input-border-color
      ))
    );

    &:before, &:after {
      content: '';
      position: absolute;
      left: 0.9rem;
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      border-style: solid;
      border-color: transparent;
    }

    &:before {
      top: -1.4rem;
      z-index: 0;
      border-width: 0.7rem;

      @include themify(
        (dark: (
          border-bottom-color: $dark-form-input-border-color
        )),
        (light: (
          border-bottom-color: $light-form-input-border-color
        ))
      );
    }
    &:after {
      top: -1.2rem;
      z-index: 1;
      border-width: 0.6rem;
      @include themify(
        (dark: (
          border-bottom-color: $dark-header-nav-background-color
        )),
        (light: (
          border-bottom-color: $light-header-nav-background-color
        ))
      );
    }

    > ul {
      @extend %calendar_list_base;
    }

    @include mq(tablet) {
      padding-left: 1rem;
    }
  }

  &:not(.active) {
    > div {
      opacity: 0;
      @include transform(scaleY(0));
    }
  }

  &.active {
    > div {
      opacity: 1;
      @include transform(scaleY(1));
    }
  }
}
