$form_element_size: 3.3rem;
$form_element_font_size: 1.4rem;
$form_element_min_width: 18rem;
$form_element_min_width_half: 9rem;
$form_element_border_radius: 0.3rem;
$form_element_indent: 2.8rem;
$form_element_buffer: 1rem;

$form_fieldset_title_buffer: 30rem;
$form_fieldset_input_width: 40rem;

$form_group_border_radius: 0.5rem;

$input_width_desktop: 30.5rem !default;
$input_width_laptop: 20rem !default;
$input_width_tablet: 28rem !default;

$svg-check: 'M22,10.1L14.3,18c-0.1,0.1-0.3,0.1-0.4,0l-3.5-3.3c-0.1-0.1-0.3-0.1-0.4,0l-1.7,1.7c-0.1,0.1-0.1,0.3,0,0.4l5.6,5.4c0.1,0.1,0.3,0.1,0.4,0l9.8-10.1c0.1-0.1,0.1-0.3,0-0.4l-1.7-1.7C22.3,9.9,22.1,9.9,22,10.1z';
$svg-caret-down: 'M16.5,20.4c-0.3,0-0.6-0.1-0.8-0.4l-4.4-4.4c-0.5-0.5-0.5-1.2,0-1.7c0.5-0.5,1.2-0.5,1.7,0l3.6,3.6l3.6-3.6c0.5-0.5,1.2-0.5,1.7,0s0.5,1.2,0,1.7L17.3,20C17.1,20.2,16.8,20.4,16.5,20.4z';
$svg-caret-up: 'M16.5,13.5c0.3,0,0.6,0.1,0.8,0.4l4.4,4.4c0.5,0.5,0.5,1.2,0,1.7c-0.5,0.5-1.2,0.5-1.7,0l-3.6-3.6L12.9,20c-0.5,0.5-1.2,0.5-1.7,0s-0.5-1.2,0-1.7l4.4-4.4C15.9,13.7,16.2,13.5,16.5,13.5z';
$svg-search: 'M22.2,25.1l-3.1-3.2c-0.1-0.1-0.2-0.3-0.2-0.5v-0.5c-1.1,0.9-2.5,1.4-4.1,1.4c-3.7,0-6.7-3-6.7-6.7s3-6.7,6.7-6.7s6.7,3,6.7,6.7c0,1.5-0.5,3-1.4,4.1h0.5c0.2,0,0.4,0.1,0.5,0.2l3.2,3.2c0.3,0.3,0.3,0.8,0,1.1l-1,0.9C23,25.4,22.5,25.4,22.2,25.1z M18.8,15.6c0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1s1.8,4.1,4.1,4.1S18.8,17.8,18.8,15.6z';
$svg-calendar: 'M9.5,8.2h1.9v-2c0-0.3,0.2-0.5,0.5-0.5h1.5c0.3,0,0.5,0.2,0.5,0.5v2h4.9v-2c0-0.3,0.2-0.5,0.5-0.5h1.5c0.3,0,0.5,0.2,0.5,0.5v2h1.9c1,0,1.9,0.8,1.9,1.9v13.6c0,1-0.8,1.9-1.9,1.9H9.5c-1,0-1.9-0.8-1.9-1.9V10C7.6,9,8.5,8.2,9.5,8.2z M9.7,23.6h13.1c0.1,0,0.2-0.1,0.2-0.2V11.9H9.5v11.5C9.5,23.5,9.6,23.6,9.7,23.6z';
$svg-clock: 'M16.5,25C11.8,25,8,21.2,8,16.5S11.8,8,16.5,8s8.5,3.8,8.5,8.5S21.2,25,16.5,25z M16.5,9.7c-3.8,0-6.8,3.1-6.8,6.8 s3.1,6.8,6.8,6.8s6.8-3.1,6.8-6.8S20.3,9.7,16.5,9.7z M18.6,13.3l-2.9,2.1c-0.1,0.1-0.2,0.2-0.2,0.4v5.6c0,0.3,0.2,0.4,0.4,0.4H17 c0.3,0,0.4-0.2,0.4-0.4v-4.9l2.3-1.7c0.2-0.2,0.3-0.4,0.1-0.5l-0.6-0.9C19.1,13.2,18.8,13.1,18.6,13.3L18.6,13.3z';
$svg-globe: 'M20.2,18.7h4.5c0.2-0.7,0.3-1.4,0.3-2.2c0-0.8-0.1-1.5-0.3-2.2h-4.5C20.4,15.8,20.4,17.3,20.2,18.7z M14.1,13.2c0.4-1.8,1.1-3.9,2.4-5.2c0,0,0,0,0,0s0,0,0,0c1.3,1.4,2,3.4,2.4,5.2H14.1z M20.1,19.8h4.3c-1.1,2.6-3.5,4.6-6.3,5.1C19.1,23.4,19.7,21.6,20.1,19.8L20.1,19.8z M19.1,18.7h-5.2c-0.2-1.4-0.2-3,0-4.4h5.2C19.3,15.7,19.3,17.3,19.1,18.7z M12.9,13.2H8.6c1.1-2.6,3.5-4.6,6.3-5.1C13.9,9.6,13.3,11.4,12.9,13.2z M20.1,13.2c-0.3-1.8-1-3.6-2-5.1c2.9,0.5,5.2,2.5,6.3,5.1H20.1L20.1,13.2z M18.9,19.8c-0.4,1.8-1.1,3.9-2.4,5.2c0,0,0,0,0,0s0,0,0,0c-1.3-1.4-2-3.4-2.4-5.2H18.9z M8.3,18.7C8.1,18,8,17.3,8,16.5c0-0.8,0.1-1.5,0.3-2.2h4.5c-0.2,1.4-0.2,2.9,0,4.4C12.8,18.7,8.3,18.7,8.3,18.7z M12.9,19.8c0.3,1.8,1,3.6,2,5.1c-2.9-0.5-5.2-2.5-6.3-5.1H12.9z';
$svg-phone: 'M22.8,23.5l-3,0.7c-0.3,0.1-0.7-0.1-0.8-0.4l-1.4-3.2c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.4,0.2-0.5l0,0l1.7-1.4c-1-2.2-2.8-4-5.1-5.1l-1.4,1.7c-0.1,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.2,0-0.3-0.1l0,0l-3.2-1.4c-0.3-0.1-0.5-0.5-0.4-0.8l0.7-3c0.1-0.3,0.3-0.5,0.7-0.5c7.3,0,13.2,5.9,13.2,13.2C23.4,23.2,23.1,23.5,22.8,23.5z';
$svg-envelope: 'M9.2,9.8h14.6c0.9,0,1.7,0.8,1.7,1.7v10.1c0,0.9-0.8,1.7-1.7,1.7H9.2c-0.9,0-1.7-0.8-1.7-1.7V11.4C7.5,10.5,8.3,9.8,9.2,9.8z M9.2,11.4v1.4c0.8,0.6,2,1.6,4.7,3.7c0.6,0.5,1.8,1.6,2.6,1.6c0.8,0,2-1.1,2.6-1.6c2.7-2.1,3.9-3.1,4.7-3.7v-1.4H9.2z M23.8,21.6V15c-0.8,0.6-1.9,1.5-3.7,2.9c-0.8,0.6-2.1,1.9-3.6,1.9c-1.5,0-2.8-1.3-3.6-1.9c-1.7-1.4-2.9-2.3-3.7-2.9v6.5L23.8,21.6L23.8,21.6z';
$svg-lock: 'M11.2,15h0.7v-2c0-2.3,1.9-4.1,4.1-4.1s4.1,1.9,4.1,4.1v2h0.7c0.7,0,1.3,0.6,1.3,1.3v5.2c0,0.7-0.6,1.3-1.3,1.3h-9.6c-0.7,0-1.3-0.6-1.3-1.3v-5.2C9.9,15.6,10.5,15,11.2,15z M14,15H18v-2c0-1.1-0.9-2-2-2s-2,0.9-2,2V15z';
$svg-upload: 'M15.4,20.1h2.2c0.4,0,0.7-0.3,0.7-0.7v-4.7h2.5c0.5,0,0.7-0.6,0.4-1l-4.3-4.3c-0.2-0.2-0.6-0.2-0.8,0l-4.3,4.3c-0.4,0.4-0.1,1,0.4,1h2.5v4.7C14.7,19.8,15,20.1,15.4,20.1L15.4,20.1z M9.3,19.9V23c0,0.4,0.3,0.7,0.7,0.7h13c0.4,0,0.7-0.3,0.7-0.7v-3.1c0-0.4-0.3-0.7-0.7-0.7h-3.8v0.2c0,0.9-0.7,1.6-1.6,1.6h-2.2c-0.9,0-1.6-0.7-1.6-1.6v-0.2H10C9.6,19.2,9.3,19.5,9.3,19.9z M12.8,22.3c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6s-0.3,0.6-0.6,0.6S12.8,22.6,12.8,22.3z M11,22.3c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6s-0.3,0.6-0.6,0.6S11,22.6,11,22.3z';
$svg-spinner: 'M22.9,7.4H10.2c-1,0-1.7,0.8-1.7,1.7v15c-0.1,0.9,0.7,1.7,1.7,1.7h12.7c1,0,1.7-0.8,1.7-1.7v-15C24.6,8.1,23.8,7.4,22.9,7.4z M20.7,19.4l-3.7,3.5c-0.2,0.2-0.4,0.3-0.6,0.3s-0.5-0.1-0.6-0.3l-3.5-3.5c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.9,2.9l2.9-2.9c0.4-0.4,1-0.4,1.4,0C21.1,18.4,21.1,19,20.7,19.4z M20.7,15.1c-0.4,0.4-1,0.4-1.4,0l-2.9-2.9l-2.9,2.9c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l3.7-3.5c0.2-0.2,0.4-0.3,0.6-0.3s0.5,0.1,0.6,0.3l3.5,3.5 C21.1,14.2,21.1,14.7,20.7,15.1z';
