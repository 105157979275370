label {
  &.input-label {
    &.countries {
      > dl {
        > dt {
          > button {
            > span {
              display: inline-block;
              &[data-value] {
                background-position: left center !important;
                padding-left: 2.4rem;
              }
            }
          }
        }
        > dd {
          li {
            > button {
              padding-left: $form_element_size !important;
            }
          }
        }

        @include ApplyDataCountryBackgrounds('dd.selected > ol > li > button');
        @include ApplyDataCountryBackgrounds('dd.options > ul > li > button');
        @include ApplyDataCountryBackgrounds('dt > button > span');
      }
    }
  }
}
