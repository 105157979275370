
@mixin highcharts-plot-bg($id) {
  ##{$id} {
    stop {
      stop-color: transparent;
      &[offset="0"] { stop-opacity: 0; }
      &[offset="1"] { stop-opacity: 0; }
    }
  }
}

@mixin highcharts-gradient($colors, $name, $stop0: 0.6, $stop1: 0.1) {
  @for $i from 1 through length($colors) {
    $color: nth($colors, $i);
    ##{$name}-#{$i - 1} {
      stop {
        stop-color: $color;
        &[offset="0"] { stop-opacity: $stop0; }
        &[offset="1"] { stop-opacity: $stop1; }
      }
    }
  }
}

@mixin highcharts-colors($colors) {
  @for $i from 1 through length($colors) {
    $color: nth($colors, $i);
    .highcharts-color-#{$i - 1} {
      stroke: $color;
      fill: transparent;
    }
  }
}

@mixin highcharts-theme-colors($name, $colors) {
  @for $i from 1 through length($colors) {
    $color: nth($colors, $i);
    .#{$name}-#{$i - 1} {
      stroke: $color;
      color: $color;
    }
  }
}

@mixin gallery-overrides($colors) {
  .highcharts-pane {
    @for $i from 1 through length($colors) {
      $color: nth($colors, $i);
      &:nth-child(#{$i}) { fill: as-rgba($color, 0.3); }
    }
  }
  @for $i from 1 through length($colors) {
    $color: nth($colors, $i);
    .highcharts-series-#{$i - 1} { .highcharts-point { fill: $color; } }
  }
}


/**
 * Adapted from
 * ------------
 * @license Highcharts
 * (c) 2009-2016 Torstein Honsi
 * License: www.highcharts.com/license
 */

// Colored, shades of blue by default
$highlight-color-100: #003399 !default; // Drilldown clickable labels, color axis max color.
$highlight-color-80: #335cad !default; // Selection marker, menu hover, button hover, chart border, navigator series.
$highlight-color-60: #6685c2 !default; // Navigator mask fill.
$highlight-color-20: #ccd6eb !default; // Ticks and axis line.
$highlight-color-10: #e6ebf5 !default; // Pressed button, color axis min color.

.highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
}

.highcharts-root {
  display: block;
}

.highcharts-root text {
  stroke-width: 0;
}

.highcharts-strong {
  font-weight: 300;
}

.highcharts-emphasized {
  font-style: italic;
}

.highcharts-anchor {
  cursor: pointer;
}

.highcharts-background {
  fill: none;
}

.highcharts-plot-border,
.highcharts-plot-background {
  fill: none;
}

.highcharts-label-box {
  fill: none;
}

.highcharts-button-box {
  @include themify(
    (dark: (
      fill: $white-005,
      stroke: $white-01
    )),
    (light: (
      fill: $black-005,
      stroke: $black-01
    ))
  );
}

/* Titles */
.highcharts-title, .highcharts-subtitle {
  z-index: -1;
}

.highcharts-title {
  font-family: inherit;
  white-space: nowrap;
  margin-left: 0;
  margin-top: 0;
  font-size: 1.8rem;
  font-weight: 300;
  text-align: left;
  @include themify(
    (dark: (
      color: $white-08,
    )),
    (light: (
      color: $black-08,
    ))
  );
}

.radial-tooltip-wrapper {
  display: block;
  text-align: center;

  @include themify(
    (dark: (
      color: $white-06,
    )),
    (light: (
      color: $black-06,
    ))
  );

  strong {
    @include themify(
      (dark: (
        color: $white-08,
      )),
      (light: (
        color: $black-08,
      ))
    );
  }
}

.highcharts-subtitle {
  @include themify(
    (dark: (
      fill: $white-04
    )),
    (light: (
      fill: $black-04
    ))
  );
}

/* Axes */
.highcharts-axis-line {
  fill: none;
  @include themify(
    (dark: (
      stroke: $white-005
    )),
    (light: (
      stroke: $black-005
    ))
  );
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-axis-title {
  font-size: 1.2rem;
  @include themify(
    (dark: (
      fill: $ux_normal_text_color
    )),
    (light: (
      fill: $black-05
    ))
  );
}

.highcharts-axis-labels {
  cursor: default;
  font-size: 1rem;
  @include themify(
    (dark: (
      fill: $ux_normal_text_color
    )),
    (light: (
      fill: $black-04
    ))
  );
}

.highcharts-grid-line {
  fill: none;
  @include themify(
    (dark: (
      stroke: $white-1
    )),
    (light: (
      stroke: $black-1
    ))
  );
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-tick {
  stroke: $highlight-color-20;
}

.highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}

.highcharts-minor-grid-line {
  @include themify(
    (dark: (
      stroke: $white-005
    )),
    (light: (
      stroke: $black-005
    ))
  );
}

.highcharts-crosshair-thin {
  stroke-width: 1px;
  @include themify(
    (dark: (
      stroke: $white-02
    )),
    (light: (
      stroke: $black-02
    ))
  );
}

.highcharts-crosshair-category {
  stroke: $highlight-color-20;
  stroke-opacity: 0.25;
}


/* Credits */
.highcharts-credits {
  cursor: pointer;
  font-size: 0.7em;
  @include transitions(fill 250ms, font-size 250ms);

  @include themify(
    (dark: (
      fill: $white-04
    )),
    (light: (
      fill: $black-04
    ))
  );
}

.highcharts-credits:hover {
  fill: black;
  font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
  cursor: default;
  font-size: 1.2rem;
  font-weight: 200;
  pointer-events: none;
  white-space: nowrap;
  @include transitions(stroke 150ms);

  @include themify(
    (dark: (
      color: $white-08
    )),
    (light: (
      color: $black-08
    ))
  );
}

.highcharts-tooltip text {
  @include themify(
    (dark: (
      fill: $white-08
    )),
    (light: (
      fill: $black-08
    ))
  );
}

.highcharts-tooltip .highcharts-header {
  font-size: 1.2rem;
}

.highcharts-tooltip-box {
  stroke-width: 1px 1px 1px 6px;

  @include themify(
    (dark: (
      fill: $black-08,
      stroke: $white-05
    )),
    (light: (
      fill: $white-08,
      stroke: $black-05
    ))
  );
}

.highcharts-tooltip-box .highcharts-label-box {
  @include themify(
    (dark: (
      fill: $black-08
    )),
    (light: (
      fill: $white-08
    ))
  );
  fill-opacity: 0.85;
}

.highcharts-selection-marker {
  fill: $highlight-color-80;
  fill-opacity: 0.25;
}

.highcharts-graph {
  fill: none;
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
  stroke-width: 1;
}

.highcharts-state-hover path {
  @include transitions(stroke-width 50); /* quick in */
}

.highcharts-state-normal path {
  @include transitions(stroke-width 250ms); /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
  @include transitions(opacity 250ms);
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}

/* Series options */

/* Default colors */
// .highcharts-series-group {}

.highcharts-area {
  fill-opacity: 0.3;
  stroke-width: 0;
}

.highcharts-markers {
  stroke-width: 1px;
  stroke: transparent;
}

.highcharts-point {
  stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}

.highcharts-data-label {
  font-size: 0.9em;
  font-weight: 500;
}

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-data-label text, text.highcharts-data-label {
  @include themify(
    (dark: (
      fill: $white-08
    )),
    (light: (
      fill: $black-08
    ))
  );
}

.highcharts-data-label-connector {
  fill: none;
}

.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select {
  @include themify(
    (dark: (
      fill: $white-02,
      stroke: $white-1
    )),
    (light: (
      fill: $black-02,
      stroke: $black-1
    ))
  );
}

.highcharts-column-series rect.highcharts-point {
  // rect to prevent stroke on 3D columns
  stroke: transparent;
}

.highcharts-column-series .highcharts-point {
  @include transitions(fill-opacity 250ms);
}

.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  @include transitions(fill-opacity 50ms);
}

.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: transparent;
}

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  @include transitions(fill-opacity 50ms);
}

.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: transparent;
}

.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  @include transitions(fill-opacity 50ms);
}

.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: transparent;
}

.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  @include transitions(fill-opacity 50ms);
}

.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  @include transitions(stroke 250ms, fill 250ms, fill-opacity 250ms);
  @include themify(
    (dark: (
      stroke: $white-01
    )),
    (light: (
      stroke: $black-01
    ))
  );

}

.highcharts-treemap-series .highcharts-point-hover {
  @include transitions(stroke 25ms, fill 25ms, fill-opacity 25ms);
  @include themify(
    (dark: (
      stroke: $white-04
    )),
    (light: (
      stroke: $black-04
    ))
  );
}

.highcharts-treemap-series .highcharts-above-level {
  display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}

/* Legend */
.highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-legend-item text {
  cursor: pointer;
  font-weight: 500;
  stroke-width: 0;
  @include themify(
    (dark: (
      fill: $white-08
    )),
    (light: (
      fill: $black-08
    ))
  );
}

.highcharts-legend-item:hover text {
  @include themify(
    (dark: (
      fill: $white-1
    )),
    (light: (
      fill: $black-1
    ))
  );
}

.highcharts-legend-item-hidden * {
  @include transitions(fill 250ms);
  @include themify(
    (dark: (
      stroke: $white-02,
      fill: $white-02
    )),
    (light: (
      stroke: $black-02,
      fill: $black-02
    ))
  );
}

.highcharts-legend-nav-active {
  fill: $highlight-color-100;
  cursor: pointer;
}

.highcharts-legend-nav-inactive {
  @include themify(
    (dark: (
      fill: $white-02
    )),
    (light: (
      fill: $black-02
    ))
  );
}

.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}

/* Loading */
.highcharts-loading {
  position: absolute;
  background-color: transparent;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  @include transitions(opacity 250ms);
}

.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  @include transitions(opacity 250ms, height 250ms step-end);
}

.highcharts-loading-inner {
  font-weight: 500;
  position: relative;
  top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band, .highcharts-pane {
  visibility: visible;
}

.highcharts-plot-line {
  fill: none;
  stroke-width: 1px;
  @include themify(
    (dark: (
      stroke: $white-04
    )),
    (light: (
      stroke: $black-04
    ))
  );
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
  fill: transparent;
}

.highcharts-boxplot-median {
  stroke-width: 1px;
}

.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
  @include themify(
    (dark: (
      stroke: $white-1
    )),
    (light: (
      stroke: $black-1
    ))
  );
}

.highcharts-gauge-series .highcharts-data-label-box {
  stroke-width: 1px;
  @include themify(
    (dark: (
      stroke: $white-02
    )),
    (light: (
      stroke: $black-02
    ))
  );
}

.highcharts-gauge-series .highcharts-dial {
  stroke-width: 0;
  @include themify(
    (dark: (
      fill: $white-1
    )),
    (light: (
      fill: $black-1
    ))
  );
}

.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
  stroke-dasharray: 1, 3;
  @include themify(
    (dark: (
      stroke: $white-08
    )),
    (light: (
      stroke: $black-08
    ))
  );
}

.highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
  @include transitions(fill 250ms, fill-opacity 250ms);
  fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  @include transitions(fill 50ms, fill-opacity 50ms);
  fill-opacity: 1;
}

/* Highstock */
.highcharts-navigator-mask-outside {
  fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
  cursor: ew-resize;
  @include themify(
    (dark: (
      fill: $white-005
    )),
    (light: (
      fill: $black-005
    ))
  );
}

.highcharts-navigator-outline {
  fill: none;
  @include themify(
    (dark: (
      stroke: $black-04
    )),
    (light: (
      stroke: $white-04
    ))
  );
}

.highcharts-navigator-handle {
  cursor: ew-resize;
  @include themify(
    (dark: (
      fill: $white-02,
      stroke: $black-04
    )),
    (light: (
      fill: $black-02,
      stroke: $white-04
    ))
  );
}

.highcharts-navigator-series {
  @include themify(
    (dark: (
      fill: $white-02,
      stroke: $black-04
    )),
    (light: (
      fill: $black-02,
      stroke: $white-04
    ))
  );
}

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  @include themify(
    (dark: (
      stroke: $white-01
    )),
    (light: (
      stroke: $black-01
    ))
  );
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
  cursor: default;
  font-size: 1rem;
  @include themify(
    (dark: (
      fill: $white-04
    )),
    (light: (
      fill: $black-04
    ))
  );
}

.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-scrollbar-thumb {
  stroke-width: 1px;
  @include themify(
    (dark: (
      fill: $white-02,
      stroke: $black-04
    )),
    (light: (
      fill: $black-02,
      stroke: $white-04
    ))
  );
}

.highcharts-scrollbar-button {
  stroke-width: 1px;
  @include themify(
    (dark: (
      fill: $white-02,
      stroke: $black-04
    )),
    (light: (
      fill: $black-02,
      stroke: $white-04
    ))
  );
}

.highcharts-scrollbar-arrow {
  @include themify(
    (dark: (
      fill: $black-05
    )),
    (light: (
      fill: $white-05
    ))
  );
}

.highcharts-scrollbar-rifles {
  stroke-width: 1px;
  @include themify(
    (dark: (
      stroke: $black-08
    )),
    (light: (
      stroke: $white-08
    ))
  );
}

.highcharts-scrollbar-track {
  stroke-width: 1px;
  @include themify(
    (dark: (
      fill: $white-02,
      stroke: $black-04
    )),
    (light: (
      fill: $black-02,
      stroke: $white-04
    ))
  );
}

.highcharts-button {
  cursor: default;
  stroke-width: 1px;
  @include transitions(fill 250ms);
  @include themify(
    (dark: (
      fill: $white-003,
      stroke: $black-02
    )),
    (light: (
      fill: $black-003,
      stroke: $white-02
    ))
  );
}

.highcharts-button text {
  font-family: $site_font_family;
  @include themify(
    (dark: (
      fill: $white-05
    )),
    (light: (
      fill: $black-05
    ))
  );
}

.highcharts-button-hover {
  @include transitions(fill 0ms);
  @include themify(
    (dark: (
      fill: $white-01,
      stroke: $black-08
    )),
    (light: (
      fill: $black-01,
      stroke: $white-08
    ))
  );
}

.highcharts-button-pressed {
  font-weight: 500;
  stroke: none;
}

.highcharts-button-disabled text {
  @include themify(
    (dark: (
      fill: $white-02
    )),
    (light: (
      fill: $black-02
    ))
  );
}

.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0;
}

.highcharts-range-label rect {
  fill: none;
}

.highcharts-range-label text {
  @include themify(
    (dark: (
      fill: $white-06
    )),
    (light: (
      fill: $black-06
    ))
  );
}

.highcharts-range-input rect {
  fill: none;
}

.highcharts-range-input text {
  @include themify(
    (dark: (
      fill: $white-08
    )),
    (light: (
      fill: $black-08
    ))
  );
}
input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px; /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em; /* #4798 */
}

.highcharts-crosshair-label text {
  fill: transparent;
  font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
  stroke-width: 1px;
  @include themify(
    (dark: (
      stroke: $white-1
    )),
    (light: (
      stroke: $black-1
    ))
  );
}

.highcharts-candlestick-series .highcharts-point-up {
  fill: transparent;
}

.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
  fill: transparent;
  @include transitions(fill 250ms);
  @include themify(
    (dark: (
      stroke: $white-04
    )),
    (light: (
      stroke: $black-04
    ))
  );
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  @include themify(
    (dark: (
      stroke: $white-1,
      fill: $highlight-color-20
    )),
    (light: (
      stroke: $black-1,
      fill: $highlight-color-20
    ))
  );
}

.highcharts-flags-series .highcharts-point text {
  font-size: 0.9em;
  font-weight: 500;
  @include themify(
    (dark: (
      fill: $white-1
    )),
    (light: (
      fill: $black-1
    ))
  );
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
  @include transitions(fill 500ms, fill-opacity 500ms, stroke-width 250ms);
  @include themify(
    (dark: (
      stroke: $white-02
    )),
    (light: (
      stroke: $black-02
    ))
  );
}

.highcharts-map-series .highcharts-point-hover {
  @include transitions(fill 0ms, fill-opacity 0ms);
  fill-opacity: 0.5;
  stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
  fill: none;
}

.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: 300;
  text-align: center;
}

.highcharts-coloraxis {
  stroke-width: 0;
}

.highcharts-coloraxis-marker {
  @include themify(
    (dark: (
      fill: $white-04
    )),
    (light: (
      fill: $black-04
    ))
  );
}

.highcharts-null-point {
  @include themify(
  (dark: (
    fill: $white-003
  )),
  (light: (
    fill: $black-003
  ))
);
}

/* 3d charts */
.highcharts-3d-frame {
  fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
  fill: transparent; /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;

  @include themify(
    (dark: (
      fill: $white-02
    )),
    (light: (
      fill: $black-02
    ))
  );
}

.highcharts-contextbutton:hover {
  @include themify(
    (dark: (
      fill: $white-05,
      stroke: $white-02
    )),
    (light: (
      fill: $black-05,
      stroke: $black-02
    ))
  );
}

.highcharts-button-symbol {
  stroke-width: 3px;
  @include themify(
    (dark: (
      stroke: $white-06
    )),
    (light: (
      stroke: $black-06
    ))
  );
}

.highcharts-menu {
  border: 1px solid;
  padding: 5px 0;
  @include boxshadow(0 0 0.3rem $white-01);
  @include themify(
    (dark: (
      background-color: $ux_page_base_color,
      border-color: $ux_border_color,
      stroke: $white-05
    )),
    (light: (
      background-color: $white-09,
      border-color: $black-01,
      stroke: $black-05
    ))
  );
}

.highcharts-menu-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: transparent;

  @include themify(
    (dark: (
      color: $white-08
    )),
    (light: (
      color: $black-08
    ))
  );

  @include transitions(background-color 250ms, color 250ms);
}

.highcharts-menu-item:hover {
  color: $white-1;
  background-color: $ux_primary_color;
}

/* Drilldown module */
.highcharts-drilldown-point {
  cursor: pointer;
}

.highcharts-drilldown-data-label text, text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: $highlight-color-100;
  font-weight: 500;
  text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
  font-weight: 500;
  font-size: 12px;
  @include themify(
    (dark: (
      fill: $white-06
    )),
    (light: (
      fill: $black-06
    ))
  );
}

/* Drag-panes module */
.highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
  stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
  stroke-width: 1px;
  @include themify(
    (dark: (
      stroke: $white-08
    )),
    (light: (
      stroke: $black-08
    ))
  );
}

.highcharts-lineargauge-target-line {
  stroke-width: 1px;
  @include themify(
    (dark: (
      stroke: $white-08
    )),
    (light: (
      stroke: $black-08
    ))
  );
}

/* Annotations module */
.highcharts-annotation-label-box {
  stroke-width: 1px;
  fill-opacity: 0.75;

  @include themify(
    (dark: (
      stroke: $white-1,
      fill: $white-1
    )),
    (light: (
      stroke: $black-1,
      fill: $black-1
    ))
  );
}

.highcharts-annotation-label text {
  @include themify(
    (dark: (
      fill: $white-01
    )),
    (light: (
      fill: $black-01
    ))
  );
}

.highcharts-grid-line {
  @include themify(
    (dark: (
      stroke: $white-01
    )),
    (light: (
      stroke: $black-01
    ))
  );
}

.highcharts-button {
  &:hover {
    &:not(.highcharts-button-disabled) {
      text {
        @include themify(
          (dark: (
            fill: $white-08
          )),
          (light: (
            fill: $black-08
          ))
        );
      }
      .highcharts-button-box {
        @include themify(
          (dark: (
            fill: $white-04,
            stroke: $white-03
          )),
          (light: (
            fill: $black-04,
            stroke: $black-03
          ))
        );
      }
    }
  }
}

.highcharts-button-pressed {
  text {
    @include themify(
      (dark: (
        fill: $white-08
      )),
      (light: (
        fill: $black-08
      ))
    );
  }
  .highcharts-button-box {
    stroke: none;

    @include themify(
      (dark: (
        fill: $white-03,
      )),
      (light: (
        fill: $black-03,
      ))
    );
  }
}

.highcharts-button-disabled {
  text {
    @include themify(
      (dark: (
        fill: $white-03
      )),
      (light: (
        fill: $black-03
      ))
    );
  }
  .highcharts-button-box {
    @include themify(
      (dark: (
        fill: $white-001,
        stroke: $white-005
      )),
      (light: (
        fill: $black-001,
        stroke: $black-005
      ))
    );
  }
}

.highcharts-legend-item {
  span {
    font-size: 1.2rem;
    font-weight: 300;

    @include themify(
      (dark: (
        color: $white-08
      )),
      (light: (
        color: $black-08
      ))
    );
    &:hover {
      @include themify(
        (dark: (
          color: $white-1
        )),
        (light: (
          color: $black-1
        ))
      );
    }
  }
  &.highcharts-legend-item-hidden {
    span {
      @include themify(
        (dark: (
          color: $white-02
        )),
        (light: (
          color: $black-02
        ))
      );
    }
  }
}

.highcharts-credits {
  display: none;
}

.highcharts-map-container {
  width: auto;
}

.highcharts-table-map-container {
  width: auto;
  height: 26rem;
}
