/* BASE REPONSIVE DIRECTIVES */
html {
  @include mq(desktop) {
    &.debug body:after { content: "DESKTOP"; }
  }

  @include mq(laptop) {
    &.debug body:after { content: "LAPTOP"; }
  }

  @include mq(tablet) {
    &.debug body:after { content: "TABLET"; }
  }

  @media
    only screen and (-webkit-min-device-pixel-ratio: 1),
    only screen and (-o-min-device-pixel-ratio: 10/10),
    only screen and (min-resolution: 96dpi),
    only screen and (min-resolution: 1dppx) {
      font-size: 10px;
      &.debug body:before { content: "1dpi"; }
	}

  @media
    only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (-o-min-device-pixel-ratio: 13/10),
    only screen and (min-resolution: 124.8dpi),
    only screen and (min-resolution: 1.3dppx) {
      font-size: 9.75px;
      &.debug body:before { content: "1.3dpi"; }
	}

  @media
    only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 15/10),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
      font-size: 9.5px;
      &.debug body:before { content: "1.5dpi"; }
	}

  @media
    only screen and (-webkit-min-device-pixel-ratio: 1.7),
    only screen and (-o-min-device-pixel-ratio: 17/10),
    only screen and (min-resolution: 163.2dpi),
    only screen and (min-resolution: 1.6dppx) {
      font-size: 9.25px;
      &.debug body:before { content: "1.7dpi"; }
	}

  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 20/10),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
      font-size: 10.1px;
      &.debug body:before { content: "2dpi"; }
	}

  @media
    only screen and (-webkit-min-device-pixel-ratio: 2.4),
    only screen and (-o-min-device-pixel-ratio: 24/10),
    only screen and (min-resolution: 230.4dpi),
    only screen and (min-resolution: 2.3dppx) {
      font-size: 10.2px;
      &.debug body:before { content: "2.4dpi"; }
	}

  @media
    only screen and (-webkit-min-device-pixel-ratio: 3),
    only screen and (-o-min-device-pixel-ratio: 30/10),
    only screen and (min-resolution: 288dpi),
    only screen and (min-resolution: 3dppx) {
      font-size: 10.3px;
      &.debug body:before { content: "3dpi"; }
	}

  @media
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and ( min-device-pixel-ratio: 1.25),
    only screen and ( min-resolution: 200dpi),
    only screen and ( min-resolution: 1.25dppx) {
      -webkit-font-smoothing: subpixel-antialiased !important;
  }
}
