%body_base {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-family: $site_font_family;
  font-weight: 300;
  font-style: normal;
  overflow: hidden;
  -webkit-font-smoothing: auto;

  @include themify(
    (dark: (
      color: $dark-body-color,
      background-color: $dark-body-background-color
    )),
    (light: (
      color: $light-body-color,
      background-color: $light-body-background-color
    ))
  );

  @include mq(tablet, laptop) {
    overflow-y: hidden;
  }
}

%html_body {
  body {
    @extend %body_base;
    #root {
      @extend %body_base;
      opacity: 1;
      @include transitions(opacity 250ms);
    }
    > div {
      &:not(#root):not(.highcharts-tooltip-container) {
        display: none !important;
        @include no-select();
        pointer-events: none;
        overflow: hidden;
      }
    }
  }
}


p.accent { color: $ux_accent_base_color !important; }
p.info { color: $ux_info_color !important; }
p.danger { color: $ux_danger_color !important; }
p.success { color: $ux_success_color !important; }
p.trivial { color: $ux_neutral_color !important; }
p.warning {
  @include themify(
    (dark: (
      color: $ux_warning_color
    )),
    (light: (
      color: $ux_alert_color
    ))
  );
}

span.accent { color: $ux_accent_base_color !important; }
span.info { color: $ux_info_color !important; }
span.danger { color: $ux_danger_color !important; }
span.success { color: $ux_success_color !important; }
span.trivial { color: $ux_neutral_color !important; }
span.warning {
  @include themify(
    (dark: (
      color: $ux_warning_color
    )),
    (light: (
      color: $ux_alert_color
    ))
  );
}
