%help_var_qmark {
  > var {
    cursor: help;
    position: sticky;
    top: -0.75rem;
    left: -0.75rem;
    z-index: 0;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.4rem;
    text-align: center;
    @include border-radius(0.8rem);
    @include themify(
      (dark: (
        color: $white-08,
        background-color: $white-02,
      )),
      (light: (
        color: $black-08,
        background-color: $black-02,
      ))
    );

    &:hover {
      @include themify(
        (dark: (
          color: $white-1,
          background-color: $white-03,
        )),
        (light: (
          color: $black-1,
          background-color: $black-03,
        ))
      );
    }
  }
}

%help_overlay {
  position: absolute;
  z-index: 1;
  min-width: 28rem;
  max-width: 38rem;
  padding: 0.2rem;
  background-color: $white-1;
  @include border-radius(0.5rem);
  @include border(1px solid $black-08, false);
  @include box-shadow-themed(0 0 0.3rem 0 $white-05, 0 0 0.3rem 0 $black-05);

  &:before {
    @include iconz();
    content: "";
    position: absolute;
    z-index: -2;
    display: block;
    margin: 0;
    padding: 0;
    font-size: 2.8rem;
    overflow: hidden;
  }

  > header {
    padding: 0.6rem 0.6rem 0.6rem 0.8rem;
    background-color: $ux_page_base_color;
    @include border-radius(0.3rem 0.3rem 0 0);
    pointer-events: none;
    @include no-select();
    > h4 {
      margin: 0.6rem 0.2rem !important;
      padding: 0 !important;
      font-size: $site_base_font_size !important;
      font-weight: 400;
      color: $white-1;
      text-align: left !important;
    }
  }
  > p {
    font-size: 1.2rem !important;
  }
  > span {
    margin: 0.8rem 0.4rem 0.8rem 0.8rem;
    font-size: 1.2rem !important;
    color: $black-1;
    text-align: left;
    * {
      color: inherit !important;
    }
    pre {
      max-width: 30rem;
      font-family: $site_font_family;
      white-space: normal;
    }
    code {
      font-size: 1.1rem;
    }
  }
}

%help_overlay_top_left {
  margin: 2.2rem 0 0 -1.2rem;
  &:before {
    content: $icon-caret-up;
    top: -1.0rem;
    left: 0.8rem;
    width: 2rem;
    height: 1.2rem;
    line-height: 0.5em;
    text-align: center;
  }
}

%help_overlay_top_right {
  margin: 2.2rem -2.6rem 0 0;
  &:before {
    content: $icon-caret-up;
    top: -1.0rem;
    right: 0.8rem;
    width: 2rem;
    height: 1.2rem;
    line-height: 0.5em;
    text-align: center;
  }
}

%help_overlay_bottom_right {
  margin: 0 -2.6rem 0.8rem 0;
  &:before {
    content: $icon-caret-down;
    bottom: -1.0rem;
    right: 0.8rem;
    width: 2rem;
    height: 1.2rem;
    line-height: 0.2em;
    text-align: center;
  }
}

%help_overlay_bottom_left {
  margin: 0 0 0.8rem -1.2rem;
  &:before {
    content: $icon-caret-down;
    bottom: -1.0rem;
    left: 0.8rem;
    width: 2rem;
    height: 1.2rem;
    line-height: 0.2em;
    text-align: center;
  }
}

%help_mask_positions {
  &.top-left {
    > span {
      top: 0;
      left: 0;
      > span {
        top: 0;
        left: 0;
        @extend %help_overlay_top_left;
      }
    }
  }
  &.top-right {
    > span {
      top: 0;
      right: 100%;
      > span {
        top: 0;
        right: 0;
        @extend %help_overlay_top_right;
      }
    }
  }
  &.bottom-left {
    > span {
      bottom: 100%;
      left: 0;
      > span {
        bottom: 0;
        left: 0;
        @extend %help_overlay_bottom_left;
      }
    }
  }
  &.bottom-right {
    > span {
      right: 100%;
      bottom: 100%;
      > span {
        right: 0;
        bottom: 0;
        @extend %help_overlay_bottom_right;
      }
    }
  }
}

%help_span_blocks {
  > span {
    display: block;
    > span {
      display: block;
      > span {
        display: block;
      }
    }
  }
}

%help_base {
  position: relative;
  z-index: 999;
  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.3rem;
  padding: 0;
  overflow: hidden;

  @extend %help_var_qmark;
  @extend %help_span_blocks;

  > span {
    position: absolute;
    z-index: 1;
    > span {
      @extend %help_overlay;
      @include transitions(opacity);
      > ul, > ol {
        margin: 0.5rem 0 1.8rem;
        padding: 0 0 0 1.8rem;
      }

    }
  }

  @extend %help_mask_positions;

  &:not(.active) {
    overflow: hidden;
    > span > span {
      opacity: 0;
    }

  }

  &.active {
    overflow: visible;
    > span > span {
      opacity: 1;
    }
  }
}

h2, h3, h4, h5 {
  span.help {
    vertical-align: top;
  }
}
