@import '~@neustar/core-ui/lib/assets/sass/core.scss';

dl.tabs {
  margin-right: 1rem;
  margin-bottom: 1.3rem;
  margin-left: 1rem;

  dd {
    @include themify(
      (dark: (
        border-color: $ux_border_color
      )),
      (light: (
        border-color: $black-03
      ))
    );
  }
}
