%main_section_article_span {
  span {
    font-size: 1.1rem;

    &.pale {
      @include themify(
        (dark: (
          color: $white-06
        )),
        (light: (
          color: $black-06
        ))
      );
    }

    &.truncate {
      display: block;
      max-width: 30rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
