//NEW THEME COLOR PALETTE
$ux_brand_color: #E01F78;
$ux_medium_brand_color: #C41B69;
$ux_dark_brand_color: #901E5F;
 
$ux_primary_color: $ux_brand_color;
$ux_primary_medium_color: $ux_medium_brand_color;
$ux_primary_dark_color: $ux_dark_brand_color;

// CORE-UI SPECIFIC SHADES OF PRIMARY COLOR
$ux_primary_color_80: tint($ux_primary_color, 80%);
$ux_primary_color_70: tint($ux_primary_color, 70%);
$ux_primary_color_60: tint($ux_primary_color, 60%);
$ux_primary_color_50: tint($ux_primary_color, 50%);
$ux_primary_color_40: tint($ux_primary_color, 40%);
$ux_primary_color_20: tint($ux_primary_color, 20%);
$ux_primary_color_10: tint($ux_primary_color, 10%);
// END OF CORE-UI SPECIFIC SHADES OF PRIMARY COLOR

$ux_success_color: #3CE475;
$ux_info_color: #90CCF4;
$ux_alert_color: #FFD646;
$ux_warning_color: #FC8212;
$ux_danger_color: #B71D22;
$ux_neutral_color: #3EA497;
 
$ux_page_base_color: #061023;
$ux_accent_base_color: #192438;
 
$ux_title_color: #FFFFFF;
$ux_highlights_color: #FFFFFF;
$ux_dim_text_color:#A39E9F;
$ux_normal_text_color:#CCC5C7;
$ux_dark_text_color: #061023;
$ux_normal_border_color: #CCC5C7;
$ux_error_text_color: #FF8595;
$ux_warning_text_color: #FF8595;
$ux_brand_link_color: #FF83DC;
$ux_alternate_brand_link_color: #90CCF4;
$ux_line_color: #454B4D;
$ux_border_color: #454B4D;
 
$ux_dark_gray_color: #333333;
$ux_medium_gray_color: #D9D9D9;
$ux_light_gray_color: #EFEFEF;
 
$ux_dark_blue_color: #1A4063;
$ux_light_blue_color: #4E6485;
$ux_white_color: #FFFFFF;
 
//PAGINATION BUTTON COLOR DEFINITION
$ux_primary_pagination_button_background_color: $ux_primary_color;
$ux_primary_pagination_button_text_color: $ux_white_color;
$ux_primary_pagination_button_hover_color: $ux_dark_brand_color;
$ux_primary_pagination_button_hover_text_color: $ux_white_color;
 
//MODAL COLOR DEFINITION
$ux_modal_background_color: $ux_accent_base_color;
$ux_modal_text_color: $ux_normal_text_color;

//TABLE COLOR DEFINITION
$ux_table_background_color: $ux_page_base_color;
$ux_table_border_color: $ux_border_color;
$ux_table_text_color: $ux_normal_text_color;
$ux_table_row_hover_color: $ux_accent_base_color;
$ux_table_row_selected_color: $ux_accent_base_color;
 
//FORM CONTROLS COLOR DEFINITON
$ux_form_control_text_color: $ux_normal_text_color;
$ux_form_control_border_color: $ux_normal_border_color;
$ux_form_control_background_color: $ux_page_base_color;
$ux_form_control_focus_text_color: $ux_white_color;
$ux_form_control_focus_border_color: $ux_white_color;
$ux_form_control_placeholder_text_color: $ux_dim_text_color;
$ux_form_control_hover_background_color: $ux_light_blue_color;
$ux_form_control_hover_text_color: $ux_white_color;
$ux_form_control_active_background_color: $ux_light_blue_color;
$ux_form_control_disabled_background_color: $ux_page_base_color;
$ux_form_control_disabled_border_color: $ux_dim_text_color;
$ux_form_control_disabled_text_color: $ux_dim_text_color;

//TAB NAVIGATION COLOR DEFINITION
$ux_tab_nav_hover_background_color: $ux_accent_base_color;
$ux_tab_nav_text_color: $ux_normal_text_color;
$ux_tab_nav_selected_text_color: $ux_primary_color;
$ux_tab_nav_border_color: $ux_border_color;
$ux_tab_nav_border_radius: 0.25rem;

$packets-total: #a8c87e #567231 #313826;
$packets-clean: #35bda8 #247f71 #18554c;
$bits-aggregate: #506cb9 #384e8d #2f4175;
$bits-mitigated: #cb3e4b #912731;
$bits-clean: #3ac2f8 #08a5e1 #0681b1;
$bits-egress: #b685d6 #9d5dc9 #7b38a8;

$white-001: rgba(255, 255, 255, 0.01);
$white-002: rgba(255, 255, 255, 0.02);
$white-003: rgba(255, 255, 255, 0.03);
$white-004: rgba(255, 255, 255, 0.04);
$white-005: rgba(255, 255, 255, 0.05);
$white-006: rgba(255, 255, 255, 0.06);
$white-007: rgba(255, 255, 255, 0.07);
$white-008: rgba(255, 255, 255, 0.08);
$white-009: rgba(255, 255, 255, 0.09);
$white-01: rgba(255, 255, 255, 0.1);
$white-02: rgba(255, 255, 255, 0.2);
$white-03: rgba(255, 255, 255, 0.3);
$white-04: rgba(255, 255, 255, 0.4);
$white-05: rgba(255, 255, 255, 0.5);
$white-06: rgba(255, 255, 255, 0.6);
$white-07: rgba(255, 255, 255, 0.7);
$white-08: rgba(255, 255, 255, 0.8);
$white-09: rgba(255, 255, 255, 0.9);
$white-1: rgba(255, 255, 255, 1);

$black-001: rgba(0, 0, 0, 0.01);
$black-002: rgba(0, 0, 0, 0.02);
$black-003: rgba(0, 0, 0, 0.03);
$black-004: rgba(0, 0, 0, 0.04);
$black-005: rgba(0, 0, 0, 0.05);
$black-006: rgba(0, 0, 0, 0.06);
$black-007: rgba(0, 0, 0, 0.07);
$black-008: rgba(0, 0, 0, 0.08);
$black-009: rgba(0, 0, 0, 0.09);
$black-01: rgba(0, 0, 0, 0.1);
$black-02: rgba(0, 0, 0, 0.2);
$black-03: rgba(0, 0, 0, 0.3);
$black-04: rgba(0, 0, 0, 0.4);
$black-05: rgba(0, 0, 0, 0.5);
$black-06: rgba(0, 0, 0, 0.6);
$black-07: rgba(0, 0, 0, 0.7);
$black-08: rgba(0, 0, 0, 0.8);
$black-09: rgba(0, 0, 0, 0.9);
$black-1: rgba(0, 0, 0, 1);

%palette_primary {
  color: $white-1;
  background-color: $ux_primary_color;
  border-color: $ux_primary_color;
}

%palette_success {
  color: $white-1;
  background-color: $ux_success_color;
  border-color: $ux_success_color;
}

%palette_info {
  color: $white-1;
  background-color: $ux_info_color;
  border-color: $ux_info_color;
}

%palette_warning {
  color: $white-1;
  background-color: $ux_warning_color;
  border-color: $ux_warning_color;
}

%palette_danger {
  color: $white-1;
  background-color: $ux_danger_color;
  border-color: $ux_danger_color;
}

%palette_accent {
  color: $white-1;
  background-color: $ux_accent_base_color;
  border-color: $ux_accent_base_color;
}

%palette_trivial {
  color: $white-1;
  background-color: $ux_neutral_color;
  border-color: $ux_neutral_color;
}

// these class/color pairs correspond to ~app/shared/modules/attackers/constants.ts
// https://github.com/d3/d3-3.x-api-reference/blob/master/Ordinal-Scales.md
$attack_types: (
  bad_tcp_udp_cksum: #4a4c8a,
  filter_list_0: #5254a3,
  filter_list_1: #6b6ecf,
  filter_list_2: #9c9ede,
  filter_list_8: #637939,
  filter_list_9: #8ca252,
  filter_list_10: #6bb5cf,
  filtered: #b5cf6b,
  rate_exceeded: #cedb9c,
  flex1_rate_exceeded: #8c6d31,
  flex2_rate_exceeded: #bd9e39,
  flex3_rate_exceeded: #9ecbe0,
  http_auth: #e7ba52,
  idle: #e7cb94,
  invalid_tcp_acknum: #843c39,
  malformed: #ad494a,
  oos_auth: #d6616b,
  syn_auth: #e7969c,
  retrans_auth: #7b4173,
  FIELDCONSISTENCY: #a55194,
  CSRF_TAG: #ce6dbd,
  COOKIE: #de9ed6,
  regex: #3284bc,
  invalid_tcp_flags: #6bafd5,
  unknown: #999999,
);

$proxy_theme: #2d99dc #35bda8 #86b34d #7798bf #e66c40 #cb3e4b #5c5c5c #55bf3b #df5353 #7798bf #9d5dc9 #f9d7bb;
