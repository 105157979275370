%table_head_base {
  @include mq(tablet) {
    display: none;
  }

  text-align: left;
  border-width: 0 0 0.1rem;
  border-style: none none solid;

  @include themify(
    (dark: (
      border-color: $ux_border_color
    )),
    (light: (
      border-color: $black-005
    ))
  );

  tr {
    th {
      &.hide {
        display: none;
      }
      white-space: nowrap;

      &:not(.sortable) {
        @include themify(
          (dark: (
            color: $white-05
          )),
          (light: (
            color: $black-05
          ))
        );
      }

      &.sortable {
        cursor: pointer;
        @include themify(
          (dark: (
            color: $white-1
          )),
          (light: (
            color: $black-1
          ))
        );

        > span {
          &.ascending,
          &.descending,
          &.unsorted {
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            margin: 0 1.4rem 0 .3rem;
            width: 0.8rem;
            height: 1.6rem;
            opacity: 0.3;

            &:before {
              @extend .icon;
              display: inline-block;
              vertical-align: top;
              font-size: 110%;
              text-align: center;
              color: inherit;
            }

            &.compact {
              margin-right: 0.8rem !important;
            }
          }

          &.ascending { @extend %icon-sort-up; }
          &.descending { @extend %icon-sort-down; }
          &.unsorted { @extend %icon-sort; }
        }

        &:hover {
          > span {
            opacity: 1;
          }

          @include themify(
            (dark: (
              background-color: $ux_accent_base_color,
            )),
            (light: (
              background-color: $black-005
            ))
          );

        }
      }
    }
  }
}

%table_head_super {
  tr {
    th {
      border-left: 0.1rem solid;
      text-transform: unset;
      @include themify(
        (dark: (
          color: $white-05,
          border-color: $ux_border_color,
          background-color: $black-01
        )),
        (light: (
          color: $black-05,
          border-color: $black-005,
          background-color: $white-001
        ))
      );
    }
  }
}
