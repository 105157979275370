@import 'breadcrumbs';

%main_section_header_base {
  margin-top: 0;
  padding: 1.5rem 0 1.5rem 3rem;

  @include no-select();
  @include transitions(padding, font-size);

  @include mq(tablet) {
    padding: 1rem 0 1rem 1.5rem;
  }

  h2, nav, li {
    display: inline-block;
    vertical-align: middle;
  }

  h2, nav {
    @include mq(tablet) {
      display: block;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 300;
    margin: 0 !important;

    @include themify(
      (dark: (
        color: $white-1
      )),
      (light: (
        color: $black-1
      ))
    );

    @include mq(tablet) {
      font-size: 1.4rem;
    }
  }

  @extend %main_section_header_breadcrumbs;
}

@include AddHeaderIcon(home, home);
@include AddHeaderIcon(accounts, clipboard);
@include AddHeaderIcon(siteprotect, shield-alt);

.main-section-header {
  margin-bottom: 3rem;
  @include themify(
    (light: (
      background-color: $black-003
    ))
  );
  > h2 {
    margin: 2rem 0 2.6rem;
    padding: 0;
    font-size: 2.6rem;
  }
}
