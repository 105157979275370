%main_aside_mask {
  > div.mask {
    display: none;
    &:after {
      content: 'tap to close';
      position: absolute;
      top: 5rem;
      right: -3rem;
      display: block;
      padding: 0.2rem 1.2rem 0.6rem;
      font-size: 1rem;
      font-weight: 200;
      border-width: 0.1rem 0.1rem 0 0.1rem;
      border-style: solid solid none solid;

      @include transform(rotate(-90deg) translateY(0));
      @include border-radius(0.5rem 0.5rem 0 0);

      @include themify(
        (dark: (
          color: $white-05,
          background-color: $black-02,
          border-color: $ux_border_color
        )),
        (light: (
          color: $black-05,
          background-color: $white-02,
          border-color: $black-02
        ))
      );
    }
  }
}
