$images_path: '../../../../images/';

%custom_label_base {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  @include border(1px solid, false);
  @include border-radius($form_element_border_radius);

  @include box-shadow-themed(
    0 0 0.3rem 0.1rem $black-05 inset,
    0 0 0.3rem 0.1rem $black-005 inset,
  );

  @include themify(
    (dark: (
      border-color: $ux_border_color
    )),
    (light: (
      border-color: $black-03
    ))
  );

  > input {
    @include border-radius(0);
    display: inline-block;
    vertical-align: middle;
    border-top: 0 none transparent;
    border-bottom: 0 none transparent;
  }
  > span {
    display: inline-block;
    vertical-align: middle;
    @include no-select();
    pointer-events: none;
  }
}

%checkbox_radio_label_base {
  @extend %custom_label_base;
  @include transitions(color, border-color, background-color);

  min-width: $form_element_min_width_half - $form_element_buffer;
  padding: 0;

  @include themify(
    (dark: (
      background-color: $dark-form-input-background-color
    )),
    (light: (
      background-color: $light-form-input-background-color
    ))
  );

  > input {
    @include transitions(color, border-color, background-color);
    &:before {
      @include transitions(color, border-color, background-color);
    }

    @include themify(
      (dark: (
        background-color: $dark-main-background-color
      )),
      (light: (
        background-color: $light-main-background-color
      ))
    );

  }
  > span {
    height: $form_element_size - 0.2rem;
    min-width: 2rem;
    line-height: $form_element_size - 0.2rem;
  }
}

%checkbox_label_enabled {
  > label {
    > input {
      &:checked {
        &:before {
          border-color: tint($ux_primary_color, 70);
        }
      }
    }

    > span {
      @include themify(
        (dark: (
          color: $white-08
        )),
        (light: (
          color: $black-08
        ))
      );
    }

    &.checked {
      > span {
        @include themify(
          (dark: (
            color: $white-1
          )),
          (light: (
            color: $black-1
          ))
        );
      }
    }
  }

  &:hover {
    label {
      background-color: $ux_primary_color;

      > input {
        border-right-color: wash($ux_primary_color, 70%);
      }

      > span {
        color: $white-1 !important;
      }
    }
  }
}

%checkbox_label_disabled {
  > label {
    > span {
      @include themify(
        (dark: (
          color: $white-05
        )),
        (light: (
          color: $black-05
        ))
      );
    }
  }
}

%checkbox_input_label {
  > label {
    @extend %checkbox_radio_label_base;
    margin: 0;

    > input {
      width: 3.1rem !important;
      height: 3.1rem !important;
      margin: 0 1.2rem 0 0;
      border-left: 0 none transparent;
    }
    > span {
      padding-right: 1.6rem;
      text-align: left;
    }
  }

  @include input-element-widths(label);

  &:not(.disabled) {
    @extend %checkbox_label_enabled;
  }
  &.disabled {
    @extend %checkbox_label_disabled;
  }

  &.as-micro-buttons {
    @extend %as_micro_buttons;
  }
  &.as-mini-buttons {
    @extend %as_checkbox_mini_buttons;
  }
}

%radio_input_label {
  margin: 0 !important;

  > label {
    @extend %checkbox_radio_label_base;
    margin: 0 $form_element_buffer $form_element_buffer 0;
    text-align: right;

    > input {
      width: 3.1rem !important;
      height: 3.1rem !important;
      margin: 0 0 0 1.5rem;
      border-right: 0 none transparent;
    }

    > span {
      padding-left: 1rem;
      text-align: left;
    }

    &:hover {
      > span {
        color: $white-1;
      }
    }

    &.checked {
      > input:before {
        border-color: $ux_primary_color_70;
      }
    }
  }

  &:not(.disabled) {
    @extend %radio_label_enabled;
  }

  &.disabled {
    @extend %radio_label_disabled;
  }

  &.as-micro-buttons {
    @extend %as_micro_buttons;
  }

  &.as-mini-buttons {
    @extend %as_radio_mini_buttons;
  }
}

%radio_label_enabled {
  visibility: visible;
  > label {
    &:hover {
      background-color:$ux_primary_dark_color;

      > input {
        border-left-color: wash($ux_primary_color, 70%);
      }
    }
  }
}

%radio_label_disabled {
  > label {
    > span {
      @include themify(
        (dark: (
          color: $white-05
        )),
        (light: (
          color: $black-05
        ))
      );
    }
  }
}

%color_input_label {
  > label {
    @extend %custom_label_base;
    cursor: pointer;
    height: $form_element_size;
    min-width: $form_element_min_width;
    overflow: hidden;

    > input {
      margin: 0 1rem 0 0;
      padding: 0;
      border-top: 0 none transparent;
      border-bottom: 0 none transparent;
      border-left: 0 none transparent;
      @include border-radius($form_element_border_radius 0 0 $form_element_border_radius !important);
    }

    > span {
      @include themify(
        (dark: (
          color: $white-08
        )),
        (light: (
          color: $black-08
        ))
      );
    }

    &:before {
      content: '';
      position: absolute;
      top: 0.9rem;
      left: 0.9rem;
      z-index: 10;
      width: 1.2rem;
      height: 1.2rem;
      background-repeat: no-repeat;
      background-size: 1.2rem;
      background-position: cover;
      background-image: url('#{$images_path}color-wheel.png');
      pointer-events: none;
      @include no-select();
      @include border-radius(0.7rem);
      @include box-shadow(0 0 0.2rem 0.1rem $black-05);
    }
  }

  &:hover {
    > label {
      > span {
        @include themify(
          (dark: (
            color: $white-1
          )),
          (light: (
            color: $black-1
          ))
        );
      }
    }
  }
}

%hidden_input_label {
  input[type="hidden"] {
    height: unset;
    background-color: unset; 
  }
}


%file_input_label {
  > label {
    @extend %custom_label_base;
    cursor: pointer;
    height: $form_element_size;
    min-width: $form_element_min_width;
    overflow: hidden;

    > input {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    input::file-selector-button {
      border-top: 0 none transparent;
      border-bottom: 0 none transparent;
      border-left: 0 none transparent;
      @include border-radius(0);
      @include themify(
        (dark: (
          border-color: $ux_border_color
        )),
        (light: (
          border-color: $black-03
        ))
      );
    }

    input::-webkit-file-upload-button {
      border-top: 0 none transparent;
      border-bottom: 0 none transparent;
      border-left: 0 none transparent;
      @include border-radius(0);
      @include themify(
        (dark: (
          border-color: $ux_border_color
        )),
        (light: (
          border-color: $black-03
        ))
      );
    }

    > span {
      @include themify(
        (dark: (
          color: $white-08
        )),
        (light: (
          color: $black-08
        ))
      );
    }
  }

  &:hover {
    > label {
      > span {
        @include themify(
          (dark: (
            color: $white-1
          )),
          (light: (
            color: $black-1
          ))
        );
      }
    }
  }
}

%range_input_label {
  %range_common {
    position: absolute;
    top: 0.1rem;
    display: inline-block;
    vertical-align: middle;
    padding: 0.2rem 0 0;
    font-size: 1.1rem;
    font-style: normal;
    pointer-events: none;
    @include no-select();
  }

  label[data-min][data-max] {
    position: relative;
    z-index: 0;
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: transparent;

    > input {
      z-index: 0;
    }

    @include border-radius($form_element_border_radius);

    > var {
      @extend %range_common;
      left: 3.6rem;
      right: 0;
      z-index: 2;
      font-size: 1.2rem;
      font-weight: 400;
      text-align: center;
      @include themify(
        (dark: (
          color: $white-07,
        )),
        (light: (
          color: $black-07
        ))
      );
    }

    &:before {
      content: attr(data-min);
      @extend %range_common;
      left: 3.6rem;
      z-index: 1;
      text-align: left;
      @include themify(
        (dark: (
          color: $ux_dim_text_color
        )),
        (light: (
          color: $black-04
        ))
      );
    }

    &:after {
      content: attr(data-max);
      @extend %range_common;
      right: 0.6rem;
      z-index: 1;
      text-align: right;
      @include themify(
        (dark: (
          color: $ux_dim_text_color
        )),
        (light: (
          color: $black-04
        ))
      );
    }
  }
}

%button_input_label {
  min-width: unset !important;
}

%as_mini_buttons_base {
  > label {
    overflow: hidden;
    > span {
      height: 2.4rem;
      line-height: 2.4rem;
    }
    > input {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

%as_checkbox_mini_buttons {
  @extend %as_mini_buttons_base;
  > label {
    > span {
      font-size: 1.2rem;
    }
    > input {
      width: 2.4rem !important;
      height: 2.4rem !important;

      &:checked {
        border-right-color: tint($ux_primary_color, 70%);
      }
      &:not(:checked):hover {
        border-right-color: $ux_primary_color;
      }
      &:before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include border(0 none transparent, false);
        @include border-radius(0);
      }
    }
    &:hover {
      > input {
        &:not(:checked) {
          border-right-color: wash($ux_primary_color, 70%);
        }
      }
    }
  }
}

%as_radio_mini_buttons {
  @extend %as_mini_buttons_base;
  > label {
    min-width: 5rem;
    > span {
      font-size: 1.2rem;
    }
    > input {
      width: 2.4rem !important;
      height: 2.4rem !important;
      float: right;
      border-right-width: 0;
      border-right-style: none;
      border-left-width: 1px;
      border-left-style: solid;
      @include transitions(border-color);
      &:checked {
        border-left-color: tint($ux_primary_color, 70%);
      }
      &:before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include border(0 none transparent, false);
        @include border-radius(0);
      }
    }

    &:hover {
      > input {
        &:not(:checked) {
          border-left-color: wash($ux_primary_color, 70%);
        }
      }
    }
  }
}

%as_micro_buttons {
  margin: 0;
  padding: 0;

  > label {
    min-width: 3.4rem;
    height: 1.5rem;
    margin: 0 0.2rem 0.2rem 0;
    padding: 0;
    text-align: center;
    overflow: hidden;
    > span {
      display: block;
      height: 1.5rem;
      margin: 0;
      padding: 0;
      font-size: 1.1rem;
      line-height: 1.1rem;
      text-align: center;
    }
    > input {
      position: absolute;
      top: -1px;
      left: -1px;
      width: 1px !important;
      height: 1px !important;
      margin: 0;
      padding: 0;
      background: none !important;
      @include border(0 none transparent !important, false);
      &:before {
        display: none;
      }
    }
    &.checked {
      border-color: $ux_primary_color_70;
      > span {
        color: $white-1;
        background-color: $ux_primary_color;
      }
    }
  }
}
