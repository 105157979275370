%header_nav_menu_div_base {
  > div {
    opacity: 0;
    @include transitions(opacity);
    @include transform-origin(top);
  }

  &:not(.active) {
    > div {
      @include transform(scaleY(0));
      @include animation(do-vertical-collapse 500ms ease forwards);
    }
  }

  &.active {
    > div {
      opacity: 1;
      @include animation(do-vertical-expand 500ms ease forwards);
    }
  }

  @include mq(tablet) {
    > div {
      height: 0;
      @include transitions(opacity, height);
    }

    &.active {
      > div {
        height: auto;
      }
    }
  }
}
