$list_item_width: 23rem;
$list_item_button_size: 2.4rem;
$list_item_button_size_half: 1.2rem; // half of $list_item_button_size
$list_item_button_size_quarter: 0.6rem; // half of $list_item_button_size_half
$list_item_buffer: 0.8rem;

%dt_span {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: normal;
  -ms-flex-item-align: normal;
  align-self: normal;
  display: block;
  margin: 0;
  padding: 0.5rem 0.5rem 0 1rem;
  line-height: normal;

  &:after {
    @extend .icon;
    display: inline-block;
    vertical-align: middle;
    width: 1.4rem;
    height: 1.2rem;
    margin: 0 0.7rem;
    padding: 0.2rem 0 0;
    text-align: center;
    @include border-radius(0.2rem);
    @include themify(
      (dark: (
        color: $white-07,
        background-color: $white-02
      )),
      (light: (
        color: $black-07,
        background-color: $black-02
      ))
    );
  }

  &.customer {
    &:after {
      content: $icon-user;
      font-size: 0.7rem;
    }
  }
  &.reseller {
    &:after {
      content: $icon-umbrella;
      font-size: 0.8rem;
    }
  }
}

%dt_button_toggle {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;

  margin: 0;
  border-width: 0 0.1rem 0 0;
  border-style: none solid none none;

  @include transitions(transform);
  @include themify(
    (dark: (
      border-color: $ux_border_color
    )),
    (light: (
      border-color: $black-02
    ))
  );

  &:before {
    content: $icon-chevron-right;
    @include themify(
      (dark: (
        color: $white-06
      )),
      (light: (
        color: $black-06
      ))
    );
  }

  &:hover {
    &:before {
      @include themify(
        (dark: (
          color: $white-1
        )),
        (light: (
          color: $black-1
        ))
      );
    }
  }

  &:disabled {
    cursor: not-allowed;
    &:before {
      @include themify(
        (dark: (
          color: $white-01
        )),
        (light: (
          color: $black-01
        ))
      );
    }
    &:hover {
      &:before {
        @include themify(
          (dark: (
            color: $white-01
          )),
          (light: (
            color: $black-01
          ))
        );
      }
    }
  }
}

%dt_button_select {
  width: 2.4rem;
  height: 2.4rem;
  text-align: center;
  -webkit-box-ordinal-group: 3;
  -moz-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  &:before {
    content: $icon-check-circle;
    @include themify(
      (dark: (
        color: $white-04
      )),
      (light: (
        color: $black-04
      ))
    );
  }
  &:hover {
    &:before {
      color: $ux_primary_color !important;
    }
  }
  &:disabled {
    cursor: not-allowed;
    &:before {
      @include themify(
        (dark: (
          color: $white-01
        )),
        (light: (
          color: $black-01
        ))
      );
    }
    &:hover {
      &:before {
        @include themify(
          (dark: (
            color: $white-01
          )),
          (light: (
            color: $black-01
          ))
        );
      }
    }
  }
}

%dt_button {
  @include empty-button();
  @include border-radius(0);
  @include transitions(transform, color, background-color);
  position: relative;
  width: $list_item_button_size;
  height: $list_item_button_size;
  padding: 0;
  color: inherit;
  text-align: left;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex: 0 0 $list_item_button_size;
  -ms-flex: 0 0 $list_item_button_size;
  flex: 0 0 $list_item_button_size;
  -webkit-align-self: normal;
  -ms-flex-item-align: normal;
  align-self: normal;

  &:before {
    @include iconz();
    position: absolute;
    display: block;
    top: $list_item_button_size_quarter;
    left: $list_item_button_size_quarter;
    width: $list_item_button_size_half;
    height: $list_item_button_size_half;
    font-size: 1.1rem;
    font-weight: 100 !important;
    text-align: center;
    overflow: visible;
  }

  &.toggle {
    @extend %dt_button_toggle;
  }

  &.select {
    @extend %dt_button_select;
  }
}

%tree_container_dt {
  @extend %flex-container-horizontal;
  margin: 0;
  padding: 0;
  border-width: 1px 0 0;
  border-style: solid none none;

  @include themify(
    (dark: (
      border-color: $ux_border_color
    )),
    (light: (
      border-color: $black-02
    ))
  );

  > span {
    @extend %dt_span;
  }

  > button {
    @extend %dt_button;
  }

  &:hover {
    @extend %tree_container_dt_hover;
  }
  &.active {
    @extend %tree_container_dt_active;
  }
}

%tree_container_dt_hover {
  background-color: $white-01;
}

%tree_container_dt_active {
  > button.toggle {
    background-color: $ux_info_color;
    &:before {
      @include transform(rotate(90deg));
      color: $white-1 !important;
    }
  }
}

%tree_container_dd {
  margin: 0;
  padding: 0 0 0 $list_item_buffer;
  @include y-scale-element();
  &.active {
    background-color: $white-01;
  }
}

%tree_container_base {
  position: relative;
  z-index: 1;
  text-align: left;
  padding: 0;

  dl {
    display: block;
    margin: 0;
    padding: 0;
    border-width: 1px 0 1px 1px;
    border-style: solid none solid solid;
    @include boxshadow(0.2rem 0.2rem 0.3rem $black-05);
    @include border-radius($form_element_border_radius);
    @include themify(
      (dark: (
        border-color: $ux_border_color,
        background-color: $dark-header-nav-background-color
      )),
      (light: (
        border-color: $black-02,
        background-color: $light-header-nav-background-color
      ))
    );

    dt {
      @extend %tree_container_dt;
    }

    dd {
      @extend %tree_container_dd;
    }
  }

  > dl {
    @include y-scale-element();
    position: absolute;
    top: 100%;
    right: $list_item_button_size * 2 + 1.2rem;
    min-width: $list_item_width;
    height: 20rem;
    margin: 0.1rem 0 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-right-width: 0.1rem;
    border-right-style: solid;
    > dt {
      &:last-of-type {
        border-bottom-width: 0.1rem !important;
        border-bottom-style: solid !important;
      }
    }

    figure {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 !important;

      svg {
        position: relative;
        top: 30%;
        display: block;
        width: 3rem;
        margin: 0 auto;
        text-align: center;
      }

      span, label {
        position: relative;
        top: 30%;
        display: block;
        margin: 0 auto;
        text-align: center;
      }

      figcaption {
        position: relative;
        top: 40%;
        display: block;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  .icon {
    &.check {
      &:not(:disabled) {
        background-color: $ux_primary_color_60;
        &:hover {
          background-color: $ux_primary_color;
        }
      }
    }
  }
}


