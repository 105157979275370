%nouns_base {
  $default-noun-color: $white-1 !default;
  $default-noun-border-color: $ux_border_color !default;
  $default-noun-background-color: $white-01 !default;

  &.noun {

    display: inline-block;
    vertical-align: middle;
    margin: 0.2rem;
    padding: 0.1rem 0.6rem 0.2rem;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: normal;
    white-space: nowrap;

    @include border(1px solid, false);
    @include border-radius(0.3rem);

    border-color: $default-noun-border-color;

    &:not(.noun-outline) {
      color: $default-noun-color;
      background-color: $default-noun-background-color;
    }

    &.noun-outline {
      color: $white-1;
      &:hover {
        background-color: mix(transparent, $default-noun-background-color, 10%);
      }
    }
    &.noun-success {
      border-color: $ux_success_color;
      color: $ux_page_base_color;
      &:not(.noun-outline) {
        background-color: $ux_success_color;
      }
      &.noun-outline {
        @include themify(
          (dark: (
            color: $ux_success_color
          )),
          (light: (
            color: tint($ux_success_color, 50%)
          ))
        );
        &:hover {
          background-color: mix(transparent, $ux_success_color, 50%);
        }
      }
    }
    &.noun-danger {
      border-color: $ux_danger_color;
      &:not(.noun-outline) {
        background-color: $ux_danger_color;
      }
      &.noun-outline {
        @include themify(
          (dark: (
            color: $ux_warning_text_color,
            border-color: $ux_warning_text_color
          )),
          (light: (
            color: $ux_danger_color
          ))
        );
        &:hover {
          color: white;
          background-color: mix(transparent, $ux_danger_color, 50%);
        }
      }
    }
    &.noun-warning {
      border-color: $ux_warning_color;
      &:not(.noun-outline) {
        color: $ux_page_base_color;
        background-color: $ux_warning_color;
      }
      &.noun-outline {
        @include themify(
          (dark: (
            color: $ux_warning_color
          )),
          (light: (
            color: tint($ux_warning_color, 50%)
          ))
        );
        &:hover {
          background-color: mix(transparent, $ux_warning_color, 50%);
        }
      }
    }
    &.noun-info {
      border-color: $ux_info_color;
      color: $ux_page_base_color;
      &:not(.noun-outline) {
        background-color: $ux_info_color;
      }
      &.noun-outline {
        @include themify(
          (dark: (
            color: $ux_info_color
          )),
          (light: (
            color: tint($ux_info_color, 50%)
          ))
        );
        &:hover {
          color: white;
          background-color: mix(transparent, $ux_info_color, 50%);
        }
      }
    }
    &.noun-trivial {
      border-color: $ux_neutral_color;
      color: $ux_page_base_color;
      &:not(.noun-outline) {
        background-color: $ux_neutral_color;
      }
      &.noun-outline {
        @include themify(
          (dark: (
            color: $ux_neutral_color
          )),
          (light: (
            color: tint($ux_neutral_color, 50%)
          ))
        );
        &:hover {
          background-color: mix(transparent, $ux_neutral_color, 50%);
        }
      }
    }
  }
}
