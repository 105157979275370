%elements_base {
  cursor: text;
  display: block;
  width: 100%;
  margin: 0;
  font-family: $site_font_family;
  font-size: $form_element_font_size;
  line-height: $form_element_font_size;
  @include border-radius($form_element_border_radius);
  @include border(1px solid, false);
  @extend %elements_themed;
}

%elements_not_input {
  @extend %elements_themed;
  &:not(.as-input) {
    &:not([type="checkbox"]):not([type="radio"]):not([type="color"]):not([type="button"]) {
      @include mq(desktop) {
        width: $input_width_desktop;
      }
      @include mq(laptop) {
        width: $input_width_laptop;
      }
      @include mq(tablet) {
        width: $input_width_tablet;
      }
    }

    &:invalid {
      @include box-shadow-themed(
        $checkable_box_shadow_invalid_1,
        $checkable_box_shadow_invalid_2
      );
    }
  }
}

%elements_themed {
  @include transitions(box-shadow);

  &:not(:disabled):not([readonly]) {
    &:not(:invalid) {
      @include box-shadow-themed(
        $checkable_box_shadow_1,
        $checkable_box_shadow_2
      );
    }

    @include themify(
      (dark: (
        color: $ux_dim_text_color,
        border-color: $dark-form-input-border-color,
        background-color: $dark-form-input-background-color
      )),
      (light: (
        color: $black-08,
        border-color: $light-form-input-border-color,
        background-color: $light-form-input-background-color
      ))
    );

    &:hover {
      @include themify(
        (dark: (
          color: $white-09,
          border-color: wash($dark-form-input-border-color, 80),
          background-color: wash($dark-form-input-background-color, 95)
        )),
        (light: (
          color: $black-09,
          border-color: tint($light-form-input-border-color, 80),
          background-color: wash($light-form-input-background-color, 95)
        ))
      );
    }

    &:focus {
      @include themify(
        (dark: (
          color: $white-1,
          border-color: wash($dark-form-input-border-color, 80)
        )),
        (light: (
          color: $black-1,
          border-color: tint($light-form-input-border-color, 90)
        ))
      );
    }
  }

  &[readonly] {
    cursor: auto;
    @include themify(
      (dark: (
        color: $ux_dim_text_color,
        border-color: tint($dark-form-input-border-color, 70),
        background-color: tint($dark-form-input-background-color, 70)
      )),
      (light: (
        color: $black-04,
        border-color: wash($light-form-input-border-color, 70),
        background-color: wash($light-form-input-background-color, 70)
      ))
    );
  }

  &:disabled {
    cursor: not-allowed;
    @include themify(
      (dark: (
        color: $ux_dim_text_color,
        border-color: tint($dark-form-input-border-color, 80),
        background-color: tint($dark-form-input-background-color, 80)
      )),
      (light: (
        color: $black-04,
        border-color: tint($light-form-input-border-color, 80),
        background-color: tint($light-form-input-background-color, 80)
      ))
    );
  }

  @extend %elements_not_input;
}

%element_icon_base {
  @extend .icon;
  display: block;
  width: inherit;
  height: inherit;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2.3em;
  text-align: center;
  color: transparent;
  pointer-events: none;
  @include no-select();
}

%input_icon_elements {
  padding-left: 3.4rem !important;
}

%checkbox_radio_elements {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  min-width: unset !important;
  width: $checkable_size;
  margin: 0;
  padding: 0;
  @include appearance(none);
  @include border(1px solid, false);
  @include border-radius($form_element_border_radius);

  @include themify(
    (dark: (
      border-color: $ux_border_color
    )),
    (light: (
      border-color: $black-05
    ))
  );

  &:before {
    content: '';
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    bottom: 0.6rem;
    left: 0.6rem;
    z-index: 0;

    @extend %checkable_base;
  }

  &:disabled {
    cursor: not-allowed;
    &:before {
      @extend %checkable_disabled_background;
    }
    &:checked {
      &:before {
        @extend %checkable_disabled_checked_background;
      }
    }
  }

  &:not(:disabled) {
    &:not(:invalid) {
      @include box-shadow-themed(
        0 0 0.3rem 0.1rem $black-03 inset,
        0 0 0.3rem 0.1rem $black-01 inset,
      );
    }

    &:checked {
      &:before {
        @extend %checkable_not_disabled_checked;
      }
    }

    &:not(:checked) {
      &:hover {
        &:before {
          @extend %checkable_not_disabled_not_checked_hover;
        }
      }
    }
  }

  @extend %elements_not_input;
}
