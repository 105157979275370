.relative {
  position: relative;
  z-index: 0;

  .abs {
    position: absolute;
    z-index: 1;
    display: block;

    &.top { top: 0; }
    &.right { right: 0; }
    &.bottom { bottom: 0; }
    &.left { left: 0; }

    &.mid {
      top: 50%;
      > button {
        margin-top: -1.65rem;
      }
    }
  }
}
