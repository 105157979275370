%main_aside_nav_anchors_hover {
  @include themify(
    (dark: (
      color: $white-08,
      background-color: $ux_accent_base_color
    )),
    (light: (
      color: $black-08,
      background-color: #dfdfdf
    ))
  );
  text-decoration: none;
}

%main_aside_nav_anchors_active {
  border-left-color: $ux_primary_color;
  @extend %main_aside_nav_anchors_hover;

  .theme-dark & {
    @include boxshadow(0 -0.1rem 0 0 $ux_page_base_color inset, 0 0.1rem 0 0 transparent inset);
  }
  .theme-light & {
    @include boxshadow(0 -0.1rem 0 0 #ccc inset, 0 0.1rem 0 0 transparent inset);
  }
}

%main_aside_anchors_base {
  a {
    position: relative;
    display: block;
    padding-right: 0;
    line-height: normal;
    text-transform: capitalize;
    border-width: 0 0 0 0.3rem;
    border-style: none none none solid;
    border-color: transparent;
    overflow: hidden;

    @include no-select();

    @include themify(
      (dark: (
        color: $ux_normal_text_color
      )),
      (light: (
        color: #8c8c8c
      ))
    );

    &:hover {
      @extend %main_aside_nav_anchors_hover;
    }

    &.active {
      &:not(.expanded) {
        @extend %main_aside_nav_anchors_active;
      }
    }

    > span {
      cursor: inherit;
      @include no-select();
    }
  }
}
