$icon-px: "\e900";
$icon-accessible-icon: "\e901";
$icon-accusoft: "\e902";
$icon-adn: "\e903";
$icon-adversal: "\e904";
$icon-affiliatetheme: "\e905";
$icon-algolia: "\e906";
$icon-amazon-pay: "\e907";
$icon-amazon: "\e908";
$icon-amilia: "\e909";
$icon-android: "\e90a";
$icon-angellist: "\e90b";
$icon-angrycreative: "\e90c";
$icon-angular: "\e90d";
$icon-app-store-ios: "\e90e";
$icon-app-store: "\e90f";
$icon-apper: "\e910";
$icon-apple-pay: "\e911";
$icon-apple: "\e912";
$icon-asymmetrik: "\e913";
$icon-audible: "\e914";
$icon-autoprefixer: "\e915";
$icon-avianex: "\e916";
$icon-aviato: "\e917";
$icon-aws: "\e918";
$icon-bandcamp: "\e919";
$icon-behance-square: "\e91a";
$icon-behance: "\e91b";
$icon-bimobject: "\e91c";
$icon-bitbucket: "\e91d";
$icon-bitcoin: "\e91e";
$icon-bity: "\e91f";
$icon-black-tie: "\e920";
$icon-blackberry: "\e921";
$icon-blogger-b: "\e922";
$icon-blogger: "\e923";
$icon-bluetooth-b: "\e924";
$icon-bluetooth: "\e925";
$icon-btc: "\e926";
$icon-buromobelexperte: "\e927";
$icon-buysellads: "\e928";
$icon-cc-amazon-pay: "\e929";
$icon-cc-amex: "\e92a";
$icon-cc-apple-pay: "\e92b";
$icon-cc-diners-club: "\e92c";
$icon-cc-discover: "\e92d";
$icon-cc-jcb: "\e92e";
$icon-cc-mastercard: "\e92f";
$icon-cc-paypal: "\e930";
$icon-cc-stripe: "\e931";
$icon-cc-visa: "\e932";
$icon-centercode: "\e933";
$icon-chrome: "\e934";
$icon-cloudscale: "\e935";
$icon-cloudsmith: "\e936";
$icon-cloudversify: "\e937";
$icon-codepen: "\e938";
$icon-codiepie: "\e939";
$icon-connectdevelop: "\e93a";
$icon-contao: "\e93b";
$icon-cpanel: "\e93c";
$icon-creative-commons: "\e93d";
$icon-css3-alt: "\e93e";
$icon-css3: "\e93f";
$icon-cuttlefish: "\e940";
$icon-d-and-d: "\e941";
$icon-dashcube: "\e942";
$icon-delicious: "\e943";
$icon-deploydog: "\e944";
$icon-deskpro: "\e945";
$icon-deviantart: "\e946";
$icon-digg: "\e947";
$icon-digital-ocean: "\e948";
$icon-discord: "\e949";
$icon-discourse: "\e94a";
$icon-dochub: "\e94b";
$icon-docker: "\e94c";
$icon-draft2digital: "\e94d";
$icon-dribbble-square: "\e94e";
$icon-dribbble: "\e94f";
$icon-dropbox: "\e950";
$icon-drupal: "\e951";
$icon-dyalog: "\e952";
$icon-earlybirds: "\e953";
$icon-edge: "\e954";
$icon-elementor: "\e955";
$icon-ember: "\e956";
$icon-empire: "\e957";
$icon-envira: "\e958";
$icon-erlang: "\e959";
$icon-ethereum: "\e95a";
$icon-etsy: "\e95b";
$icon-expeditedssl: "\e95c";
$icon-facebook-f: "\e95d";
$icon-facebook-messenger: "\e95e";
$icon-facebook-square: "\e95f";
$icon-facebook: "\e960";
$icon-firefox: "\e961";
$icon-first-order: "\e962";
$icon-firstdraft: "\e963";
$icon-flickr: "\e964";
$icon-flipboard: "\e965";
$icon-fly: "\e966";
$icon-font-awesome-alt: "\e967";
$icon-font-awesome-flag: "\e968";
$icon-font-awesome: "\e969";
$icon-fonticons-fi: "\e96a";
$icon-fonticons: "\e96b";
$icon-fort-awesome-alt: "\e96c";
$icon-fort-awesome: "\e96d";
$icon-forumbee: "\e96e";
$icon-foursquare: "\e96f";
$icon-free-code-camp: "\e970";
$icon-freebsd: "\e971";
$icon-get-pocket: "\e972";
$icon-gg-circle: "\e973";
$icon-gg: "\e974";
$icon-git-square: "\e975";
$icon-git: "\e976";
$icon-github-alt: "\e977";
$icon-github-square: "\e978";
$icon-github: "\e979";
$icon-gitkraken: "\e97a";
$icon-gitlab: "\e97b";
$icon-gitter: "\e97c";
$icon-glide-g: "\e97d";
$icon-glide: "\e97e";
$icon-gofore: "\e97f";
$icon-goodreads-g: "\e980";
$icon-goodreads: "\e981";
$icon-google-drive: "\e982";
$icon-google-play: "\e983";
$icon-google-plus-g: "\e984";
$icon-google-plus-square: "\e985";
$icon-google-plus: "\e986";
$icon-google-wallet: "\e987";
$icon-google: "\e988";
$icon-gratipay: "\e989";
$icon-grav: "\e98a";
$icon-gripfire: "\e98b";
$icon-grunt: "\e98c";
$icon-gulp: "\e98d";
$icon-hacker-news-square: "\e98e";
$icon-hacker-news: "\e98f";
$icon-hips: "\e990";
$icon-hire-a-helper: "\e991";
$icon-hooli: "\e992";
$icon-hotjar: "\e993";
$icon-houzz: "\e994";
$icon-html5: "\e995";
$icon-hubspot: "\e996";
$icon-imdb: "\e997";
$icon-instagram: "\e998";
$icon-internet-explorer: "\e999";
$icon-ioxhost: "\e99a";
$icon-itunes-note: "\e99b";
$icon-itunes: "\e99c";
$icon-jenkins: "\e99d";
$icon-joget: "\e99e";
$icon-joomla: "\e99f";
$icon-js-square: "\e9a0";
$icon-js: "\e9a1";
$icon-jsfiddle: "\e9a2";
$icon-keycdn: "\e9a3";
$icon-kickstarter-k: "\e9a4";
$icon-kickstarter: "\e9a5";
$icon-korvue: "\e9a6";
$icon-laravel: "\e9a7";
$icon-lastfm-square: "\e9a8";
$icon-lastfm: "\e9a9";
$icon-leanpub: "\e9aa";
$icon-less: "\e9ab";
$icon-line: "\e9ac";
$icon-linkedin-in: "\e9ad";
$icon-linkedin: "\e9ae";
$icon-linode: "\e9af";
$icon-linux: "\e9b0";
$icon-lyft: "\e9b1";
$icon-magento: "\e9b2";
$icon-maxcdn: "\e9b3";
$icon-medapps: "\e9b4";
$icon-medium-m: "\e9b5";
$icon-medium: "\e9b6";
$icon-medrt: "\e9b7";
$icon-meetup: "\e9b8";
$icon-microsoft: "\e9b9";
$icon-mix: "\e9ba";
$icon-mixcloud: "\e9bb";
$icon-mizuni: "\e9bc";
$icon-modx: "\e9bd";
$icon-monero: "\e9be";
$icon-napster: "\e9bf";
$icon-nintendo-switch: "\e9c0";
$icon-node-js: "\e9c1";
$icon-node: "\e9c2";
$icon-npm: "\e9c3";
$icon-ns8: "\e9c4";
$icon-nutritionix: "\e9c5";
$icon-odnoklassniki-square: "\e9c6";
$icon-odnoklassniki: "\e9c7";
$icon-opencart: "\e9c8";
$icon-openid: "\e9c9";
$icon-opera: "\e9ca";
$icon-optin-monster: "\e9cb";
$icon-osi: "\e9cc";
$icon-page4: "\e9cd";
$icon-pagelines: "\e9ce";
$icon-palfed: "\e9cf";
$icon-patreon: "\e9d0";
$icon-paypal: "\e9d1";
$icon-periscope: "\e9d2";
$icon-phabricator: "\e9d3";
$icon-phoenix-framework: "\e9d4";
$icon-php: "\e9d5";
$icon-pied-piper-alt: "\e9d6";
$icon-pied-piper-pp: "\e9d7";
$icon-pied-piper: "\e9d8";
$icon-pinterest-p: "\e9d9";
$icon-pinterest-square: "\e9da";
$icon-pinterest: "\e9db";
$icon-playstation: "\e9dc";
$icon-product-hunt: "\e9dd";
$icon-pushed: "\e9de";
$icon-python: "\e9df";
$icon-qq: "\e9e0";
$icon-quinscape: "\e9e1";
$icon-quora: "\e9e2";
$icon-ravelry: "\e9e3";
$icon-react: "\e9e4";
$icon-rebel: "\e9e5";
$icon-red-river: "\e9e6";
$icon-reddit-alien: "\e9e7";
$icon-reddit-square: "\e9e8";
$icon-reddit: "\e9e9";
$icon-rendact: "\e9ea";
$icon-renren: "\e9eb";
$icon-replyd: "\e9ec";
$icon-resolving: "\e9ed";
$icon-rocketchat: "\e9ee";
$icon-rockrms: "\e9ef";
$icon-safari: "\e9f0";
$icon-sass: "\e9f1";
$icon-schlix: "\e9f2";
$icon-scribd: "\e9f3";
$icon-searchengine: "\e9f4";
$icon-sellcast: "\e9f5";
$icon-sellsy: "\e9f6";
$icon-servicestack: "\e9f7";
$icon-shirtsinbulk: "\e9f8";
$icon-simplybuilt: "\e9f9";
$icon-sistrix: "\e9fa";
$icon-skyatlas: "\e9fb";
$icon-skype: "\e9fc";
$icon-slack-hash: "\e9fd";
$icon-slack: "\e9fe";
$icon-slideshare: "\e9ff";
$icon-snapchat-ghost: "\ea00";
$icon-snapchat-square: "\ea01";
$icon-snapchat: "\ea02";
$icon-soundcloud: "\ea03";
$icon-speakap: "\ea04";
$icon-spotify: "\ea05";
$icon-stack-exchange: "\ea06";
$icon-stack-overflow: "\ea07";
$icon-staylinked: "\ea08";
$icon-steam-square: "\ea09";
$icon-steam-symbol: "\ea0a";
$icon-steam: "\ea0b";
$icon-sticker-mule: "\ea0c";
$icon-strava: "\ea0d";
$icon-stripe-s: "\ea0e";
$icon-stripe: "\ea0f";
$icon-studiovinari: "\ea10";
$icon-stumbleupon-circle: "\ea11";
$icon-stumbleupon: "\ea12";
$icon-superpowers: "\ea13";
$icon-supple: "\ea14";
$icon-telegram-plane: "\ea15";
$icon-telegram: "\ea16";
$icon-tencent-weibo: "\ea17";
$icon-themeisle: "\ea18";
$icon-trello: "\ea19";
$icon-tripadvisor: "\ea1a";
$icon-tumblr-square: "\ea1b";
$icon-tumblr: "\ea1c";
$icon-twitch: "\ea1d";
$icon-twitter-square: "\ea1e";
$icon-twitter: "\ea1f";
$icon-typo3: "\ea20";
$icon-uber: "\ea21";
$icon-uikit: "\ea22";
$icon-uniregistry: "\ea23";
$icon-untappd: "\ea24";
$icon-usb: "\ea25";
$icon-ussunnah: "\ea26";
$icon-vaadin: "\ea27";
$icon-viacoin: "\ea28";
$icon-viadeo-square: "\ea29";
$icon-viadeo: "\ea2a";
$icon-viber: "\ea2b";
$icon-vimeo-square: "\ea2c";
$icon-vimeo-v: "\ea2d";
$icon-vimeo: "\ea2e";
$icon-vine: "\ea2f";
$icon-vk: "\ea30";
$icon-vnv: "\ea31";
$icon-vuejs: "\ea32";
$icon-weibo: "\ea33";
$icon-weixin: "\ea34";
$icon-whatsapp-square: "\ea35";
$icon-whatsapp: "\ea36";
$icon-whmcs: "\ea37";
$icon-wikipedia-w: "\ea38";
$icon-windows: "\ea39";
$icon-wordpress-simple: "\ea3a";
$icon-wordpress: "\ea3b";
$icon-wpbeginner: "\ea3c";
$icon-wpexplorer: "\ea3d";
$icon-wpforms: "\ea3e";
$icon-xbox: "\ea3f";
$icon-xing-square: "\ea40";
$icon-xing: "\ea41";
$icon-y-combinator: "\ea42";
$icon-yahoo: "\ea43";
$icon-yandex-international: "\ea44";
$icon-yandex: "\ea45";
$icon-yelp: "\ea46";
$icon-yoast: "\ea47";
$icon-youtube-square: "\ea48";
$icon-youtube: "\ea49";
$icon-address-book: "\ea4a";
$icon-address-card: "\ea4b";
$icon-adjust: "\ea4c";
$icon-align-center: "\ea4d";
$icon-align-justify: "\ea4e";
$icon-align-left: "\ea4f";
$icon-align-right: "\ea50";
$icon-ambulance: "\ea51";
$icon-american-sign-language-interpreting: "\ea52";
$icon-anchor: "\ea53";
$icon-angle-double-down: "\ea54";
$icon-angle-double-left: "\ea55";
$icon-angle-double-right: "\ea56";
$icon-angle-double-up: "\ea57";
$icon-angle-down: "\ea58";
$icon-angle-left: "\ea59";
$icon-angle-right: "\ea5a";
$icon-angle-up: "\ea5b";
$icon-archive: "\ea5c";
$icon-arrow-alt-circle-down: "\ea5d";
$icon-arrow-alt-circle-left: "\ea5e";
$icon-arrow-alt-circle-right: "\ea5f";
$icon-arrow-alt-circle-up: "\ea60";
$icon-arrow-circle-down: "\ea61";
$icon-arrow-circle-left: "\ea62";
$icon-arrow-circle-right: "\ea63";
$icon-arrow-circle-up: "\ea64";
$icon-arrow-down: "\ea65";
$icon-arrow-left: "\ea66";
$icon-arrow-right: "\ea67";
$icon-arrow-up: "\ea68";
$icon-arrows-alt-h: "\ea69";
$icon-arrows-alt-v: "\ea6a";
$icon-arrows-alt: "\ea6b";
$icon-assistive-listening-systems: "\ea6c";
$icon-asterisk: "\ea6d";
$icon-at: "\ea6e";
$icon-audio-description: "\ea6f";
$icon-backward: "\ea70";
$icon-balance-scale: "\ea71";
$icon-ban: "\ea72";
$icon-barcode: "\ea73";
$icon-bars: "\ea74";
$icon-baseball-ball: "\ea75";
$icon-basketball-ball: "\ea76";
$icon-bath: "\ea77";
$icon-battery-empty: "\ea78";
$icon-battery-full: "\ea79";
$icon-battery-half: "\ea7a";
$icon-battery-quarter: "\ea7b";
$icon-battery-three-quarters: "\ea7c";
$icon-bed: "\ea7d";
$icon-beer: "\ea7e";
$icon-bell-slash: "\ea7f";
$icon-bell: "\ea80";
$icon-bicycle: "\ea81";
$icon-binoculars: "\ea82";
$icon-birthday-cake: "\ea83";
$icon-blind: "\ea84";
$icon-bold: "\ea85";
$icon-bolt: "\ea86";
$icon-bomb: "\ea87";
$icon-book: "\ea88";
$icon-bookmark: "\ea89";
$icon-bowling-ball: "\ea8a";
$icon-braille: "\ea8b";
$icon-briefcase: "\ea8c";
$icon-bug: "\ea8d";
$icon-building: "\ea8e";
$icon-bullhorn: "\ea8f";
$icon-bullseye: "\ea90";
$icon-bus: "\ea91";
$icon-calculator: "\ea92";
$icon-calendar-alt: "\ea93";
$icon-calendar-check: "\ea94";
$icon-calendar-minus: "\ea95";
$icon-calendar-plus: "\ea96";
$icon-calendar-times: "\ea97";
$icon-calendar: "\ea98";
$icon-camera-retro: "\ea99";
$icon-camera: "\ea9a";
$icon-car: "\ea9b";
$icon-caret-down: "\ea9c";
$icon-caret-left: "\ea9d";
$icon-caret-right: "\ea9e";
$icon-caret-square-down: "\ea9f";
$icon-caret-square-left: "\eaa0";
$icon-caret-square-right: "\eaa1";
$icon-caret-square-up: "\eaa2";
$icon-caret-up: "\eaa3";
$icon-cart-arrow-down: "\eaa4";
$icon-cart-plus: "\eaa5";
$icon-certificate: "\eaa6";
$icon-chart-area: "\eaa7";
$icon-chart-bar: "\eaa8";
$icon-chart-line: "\eaa9";
$icon-chart-pie: "\eaaa";
$icon-check-circle: "\eaab";
$icon-check-square: "\eaac";
$icon-check: "\eaad";
$icon-chess-bishop: "\eaae";
$icon-chess-board: "\eaaf";
$icon-chess-king: "\eab0";
$icon-chess-knight: "\eab1";
$icon-chess-pawn: "\eab2";
$icon-chess-queen: "\eab3";
$icon-chess-rook: "\eab4";
$icon-chess: "\eab5";
$icon-chevron-circle-down: "\eab6";
$icon-chevron-circle-left: "\eab7";
$icon-chevron-circle-right: "\eab8";
$icon-chevron-circle-up: "\eab9";
$icon-chevron-down: "\eaba";
$icon-chevron-left: "\eabb";
$icon-chevron-right: "\eabc";
$icon-chevron-up: "\eabd";
$icon-child: "\eabe";
$icon-circle-notch: "\eabf";
$icon-circle: "\eac0";
$icon-clipboard: "\eac1";
$icon-clock: "\eac2";
$icon-clone: "\eac3";
$icon-closed-captioning: "\eac4";
$icon-cloud-download-alt: "\eac5";
$icon-cloud-upload-alt: "\eac6";
$icon-cloud: "\eac7";
$icon-code-branch: "\eac8";
$icon-code: "\eac9";
$icon-coffee: "\eaca";
$icon-cog: "\eacb";
$icon-cogs: "\eacc";
$icon-columns: "\eacd";
$icon-comment-alt: "\eace";
$icon-comment: "\eacf";
$icon-comments: "\ead0";
$icon-compass: "\ead1";
$icon-compress: "\ead2";
$icon-copy: "\ead3";
$icon-copyright: "\ead4";
$icon-credit-card: "\ead5";
$icon-crop: "\ead6";
$icon-crosshairs: "\ead7";
$icon-cube: "\ead8";
$icon-cubes: "\ead9";
$icon-cut: "\eada";
$icon-database: "\eadb";
$icon-deaf: "\eadc";
$icon-desktop: "\eadd";
$icon-dollar-sign: "\eade";
$icon-dot-circle: "\eadf";
$icon-download: "\eae0";
$icon-edit: "\eae1";
$icon-eject: "\eae2";
$icon-ellipsis-h: "\eae3";
$icon-ellipsis-v: "\eae4";
$icon-envelope-open: "\eae5";
$icon-envelope-square: "\eae6";
$icon-envelope: "\eae7";
$icon-eraser: "\eae8";
$icon-euro-sign: "\eae9";
$icon-exchange-alt: "\eaea";
$icon-exclamation-circle: "\eaeb";
$icon-exclamation-triangle: "\eaec";
$icon-exclamation: "\eaed";
$icon-expand-arrows-alt: "\eaee";
$icon-expand: "\eaef";
$icon-external-link-alt: "\eaf0";
$icon-external-link-square-alt: "\eaf1";
$icon-eye-dropper: "\eaf2";
$icon-eye-slash: "\eaf3";
$icon-eye: "\eaf4";
$icon-fast-backward: "\eaf5";
$icon-fast-forward: "\eaf6";
$icon-fax: "\eaf7";
$icon-female: "\eaf8";
$icon-fighter-jet: "\eaf9";
$icon-file-alt: "\eafa";
$icon-file-archive: "\eafb";
$icon-file-audio: "\eafc";
$icon-file-code: "\eafd";
$icon-file-excel: "\eafe";
$icon-file-image: "\eaff";
$icon-file-pdf: "\eb00";
$icon-file-powerpoint: "\eb01";
$icon-file-video: "\eb02";
$icon-file-word: "\eb03";
$icon-file: "\eb04";
$icon-film: "\eb05";
$icon-filter: "\eb06";
$icon-fire-extinguisher: "\eb07";
$icon-fire: "\eb08";
$icon-flag-checkered: "\eb09";
$icon-flag: "\eb0a";
$icon-flask: "\eb0b";
$icon-folder-open: "\eb0c";
$icon-folder: "\eb0d";
$icon-font: "\eb0e";
$icon-football-ball: "\eb0f";
$icon-forward: "\eb10";
$icon-frown: "\eb11";
$icon-futbol: "\eb12";
$icon-gamepad: "\eb13";
$icon-gavel: "\eb14";
$icon-gem: "\eb15";
$icon-genderless: "\eb16";
$icon-gift: "\eb17";
$icon-glass-martini: "\eb18";
$icon-globe: "\eb19";
$icon-golf-ball: "\eb1a";
$icon-graduation-cap: "\eb1b";
$icon-h-square: "\eb1c";
$icon-hand-lizard: "\eb1d";
$icon-hand-paper: "\eb1e";
$icon-hand-peace: "\eb1f";
$icon-hand-point-down: "\eb20";
$icon-hand-point-left: "\eb21";
$icon-hand-point-right: "\eb22";
$icon-hand-point-up: "\eb23";
$icon-hand-pointer: "\eb24";
$icon-hand-rock: "\eb25";
$icon-hand-scissors: "\eb26";
$icon-hand-spock: "\eb27";
$icon-handshake: "\eb28";
$icon-hashtag: "\eb29";
$icon-hdd: "\eb2a";
$icon-heading: "\eb2b";
$icon-headphones: "\eb2c";
$icon-heart: "\eb2d";
$icon-heartbeat: "\eb2e";
$icon-history: "\eb2f";
$icon-hockey-puck: "\eb30";
$icon-home: "\eb31";
$icon-hospital: "\eb32";
$icon-hourglass-end: "\eb33";
$icon-hourglass-half: "\eb34";
$icon-hourglass-start: "\eb35";
$icon-hourglass: "\eb36";
$icon-i-cursor: "\eb37";
$icon-id-badge: "\eb38";
$icon-id-card: "\eb39";
$icon-image: "\eb3a";
$icon-images: "\eb3b";
$icon-inbox: "\eb3c";
$icon-indent: "\eb3d";
$icon-industry: "\eb3e";
$icon-info-circle: "\eb3f";
$icon-info: "\eb40";
$icon-italic: "\eb41";
$icon-key: "\eb42";
$icon-keyboard: "\eb43";
$icon-language: "\eb44";
$icon-laptop: "\eb45";
$icon-leaf: "\eb46";
$icon-lemon: "\eb47";
$icon-level-down-alt: "\eb48";
$icon-level-up-alt: "\eb49";
$icon-life-ring: "\eb4a";
$icon-lightbulb: "\eb4b";
$icon-link: "\eb4c";
$icon-lira-sign: "\eb4d";
$icon-list-alt: "\eb4e";
$icon-list-ol: "\eb4f";
$icon-list-ul: "\eb50";
$icon-list: "\eb51";
$icon-location-arrow: "\eb52";
$icon-lock-open: "\eb53";
$icon-lock: "\eb54";
$icon-long-arrow-alt-down: "\eb55";
$icon-long-arrow-alt-left: "\eb56";
$icon-long-arrow-alt-right: "\eb57";
$icon-long-arrow-alt-up: "\eb58";
$icon-low-vision: "\eb59";
$icon-magic: "\eb5a";
$icon-magnet: "\eb5b";
$icon-male: "\eb5c";
$icon-map-marker-alt: "\eb5d";
$icon-map-marker: "\eb5e";
$icon-map-pin: "\eb5f";
$icon-map-signs: "\eb60";
$icon-map: "\eb61";
$icon-mars-double: "\eb62";
$icon-mars-stroke-h: "\eb63";
$icon-mars-stroke-v: "\eb64";
$icon-mars-stroke: "\eb65";
$icon-mars: "\eb66";
$icon-medkit: "\eb67";
$icon-meh: "\eb68";
$icon-mercury: "\eb69";
$icon-microchip: "\eb6a";
$icon-microphone-slash: "\eb6b";
$icon-microphone: "\eb6c";
$icon-minus-circle: "\eb6d";
$icon-minus-square: "\eb6e";
$icon-minus: "\eb6f";
$icon-mobile-alt: "\eb70";
$icon-mobile: "\eb71";
$icon-money-bill-alt: "\eb72";
$icon-moon: "\eb73";
$icon-motorcycle: "\eb74";
$icon-mouse-pointer: "\eb75";
$icon-music: "\eb76";
$icon-neuter: "\eb77";
$icon-newspaper: "\eb78";
$icon-object-group: "\eb79";
$icon-object-ungroup: "\eb7a";
$icon-outdent: "\eb7b";
$icon-paint-brush: "\eb7c";
$icon-paper-plane: "\eb7d";
$icon-paperclip: "\eb7e";
$icon-paragraph: "\eb7f";
$icon-paste: "\eb80";
$icon-pause-circle: "\eb81";
$icon-pause: "\eb82";
$icon-paw: "\eb83";
$icon-pen-square: "\eb84";
$icon-pencil-alt: "\eb85";
$icon-percent: "\eb86";
$icon-phone-square: "\eb87";
$icon-phone-volume: "\eb88";
$icon-phone: "\eb89";
$icon-plane: "\eb8a";
$icon-play-circle: "\eb8b";
$icon-play: "\eb8c";
$icon-plug: "\eb8d";
$icon-plus-circle: "\eb8e";
$icon-plus-square: "\eb8f";
$icon-plus: "\eb90";
$icon-podcast: "\eb91";
$icon-pound-sign: "\eb92";
$icon-power-off: "\eb93";
$icon-print: "\eb94";
$icon-puzzle-piece: "\eb95";
$icon-qrcode: "\eb96";
$icon-question-circle: "\eb97";
$icon-question: "\eb98";
$icon-quidditch: "\eb99";
$icon-quote-left: "\eb9a";
$icon-quote-right: "\eb9b";
$icon-random: "\eb9c";
$icon-recycle: "\eb9d";
$icon-redo-alt: "\eb9e";
$icon-redo: "\eb9f";
$icon-registered: "\eba0";
$icon-reply-all: "\eba1";
$icon-reply: "\eba2";
$icon-retweet: "\eba3";
$icon-road: "\eba4";
$icon-rocket: "\eba5";
$icon-rss-square: "\eba6";
$icon-rss: "\eba7";
$icon-ruble-sign: "\eba8";
$icon-rupee-sign: "\eba9";
$icon-save: "\ebaa";
$icon-search-minus: "\ebab";
$icon-search-plus: "\ebac";
$icon-search: "\ebad";
$icon-server: "\ebae";
$icon-share-alt-square: "\ebaf";
$icon-share-alt: "\ebb0";
$icon-share-square: "\ebb1";
$icon-share: "\ebb2";
$icon-shekel-sign: "\ebb3";
$icon-shield-alt: "\ebb4";
$icon-ship: "\ebb5";
$icon-shopping-bag: "\ebb6";
$icon-shopping-basket: "\ebb7";
$icon-shopping-cart: "\ebb8";
$icon-shower: "\ebb9";
$icon-sign-in-alt: "\ebba";
$icon-sign-language: "\ebbb";
$icon-sign-out-alt: "\ebbc";
$icon-signal: "\ebbd";
$icon-sitemap: "\ebbe";
$icon-sliders-h: "\ebbf";
$icon-smile: "\ebc0";
$icon-snowflake: "\ebc1";
$icon-sort-alpha-down: "\ebc2";
$icon-sort-alpha-up: "\ebc3";
$icon-sort-amount-down: "\ebc4";
$icon-sort-amount-up: "\ebc5";
$icon-sort-down: "\ebc6";
$icon-sort-numeric-down: "\ebc7";
$icon-sort-numeric-up: "\ebc8";
$icon-sort-up: "\ebc9";
$icon-sort: "\ebca";
$icon-space-shuttle: "\ebcb";
$icon-spinner: "\ebcc";
$icon-square-full: "\ebcd";
$icon-square: "\ebce";
$icon-star-half: "\ebcf";
$icon-star: "\ebd0";
$icon-step-backward: "\ebd1";
$icon-step-forward: "\ebd2";
$icon-stethoscope: "\ebd3";
$icon-sticky-note: "\ebd4";
$icon-stop-circle: "\ebd5";
$icon-stop: "\ebd6";
$icon-stopwatch: "\ebd7";
$icon-street-view: "\ebd8";
$icon-strikethrough: "\ebd9";
$icon-subscript: "\ebda";
$icon-subway: "\ebdb";
$icon-suitcase: "\ebdc";
$icon-sun: "\ebdd";
$icon-superscript: "\ebde";
$icon-sync-alt: "\ebdf";
$icon-sync: "\ebe0";
$icon-table-tennis: "\ebe1";
$icon-table: "\ebe2";
$icon-tablet-alt: "\ebe3";
$icon-tablet: "\ebe4";
$icon-tachometer-alt: "\ebe5";
$icon-tag: "\ebe6";
$icon-tags: "\ebe7";
$icon-tasks: "\ebe8";
$icon-taxi: "\ebe9";
$icon-terminal: "\ebea";
$icon-text-height: "\ebeb";
$icon-text-width: "\ebec";
$icon-th-large: "\ebed";
$icon-th-list: "\ebee";
$icon-th: "\ebef";
$icon-thermometer-empty: "\ebf0";
$icon-thermometer-full: "\ebf1";
$icon-thermometer-half: "\ebf2";
$icon-thermometer-quarter: "\ebf3";
$icon-thermometer-three-quarters: "\ebf4";
$icon-thumbs-down: "\ebf5";
$icon-thumbs-up: "\ebf6";
$icon-thumbtack: "\ebf7";
$icon-ticket-alt: "\ebf8";
$icon-times-circle: "\ebf9";
$icon-times: "\ebfa";
$icon-tint: "\ebfb";
$icon-toggle-off: "\ebfc";
$icon-toggle-on: "\ebfd";
$icon-trademark: "\ebfe";
$icon-train: "\ebff";
$icon-transgender-alt: "\ec00";
$icon-transgender: "\ec01";
$icon-trash-alt: "\ec02";
$icon-trash: "\ec03";
$icon-tree: "\ec04";
$icon-trophy: "\ec05";
$icon-truck: "\ec06";
$icon-tty: "\ec07";
$icon-tv: "\ec08";
$icon-umbrella: "\ec09";
$icon-underline: "\ec0a";
$icon-undo-alt: "\ec0b";
$icon-undo: "\ec0c";
$icon-universal-access: "\ec0d";
$icon-university: "\ec0e";
$icon-unlink: "\ec0f";
$icon-unlock-alt: "\ec10";
$icon-unlock: "\ec11";
$icon-upload: "\ec12";
$icon-user-circle: "\ec13";
$icon-user-md: "\ec14";
$icon-user-plus: "\ec15";
$icon-user-secret: "\ec16";
$icon-user-times: "\ec17";
$icon-user: "\ec18";
$icon-users: "\ec19";
$icon-utensil-spoon: "\ec1a";
$icon-utensils: "\ec1b";
$icon-venus-double: "\ec1c";
$icon-venus-mars: "\ec1d";
$icon-venus: "\ec1e";
$icon-video: "\ec1f";
$icon-volleyball-ball: "\ec20";
$icon-volume-down: "\ec21";
$icon-volume-off: "\ec22";
$icon-volume-up: "\ec23";
$icon-wheelchair: "\ec24";
$icon-wifi: "\ec25";
$icon-window-close: "\ec26";
$icon-window-maximize: "\ec27";
$icon-window-minimize: "\ec28";
$icon-window-restore: "\ec29";
$icon-won-sign: "\ec2a";
$icon-wrench: "\ec2b";
$icon-yen-sign: "\ec2c";
$icon-address-book2: "\ec2d";
$icon-address-card2: "\ec2e";
$icon-arrow-alt-circle-down2: "\ec2f";
$icon-arrow-alt-circle-left2: "\ec30";
$icon-arrow-alt-circle-right2: "\ec31";
$icon-arrow-alt-circle-up2: "\ec32";
$icon-bell-slash2: "\ec33";
$icon-bell2: "\ec34";
$icon-bookmark2: "\ec35";
$icon-building2: "\ec36";
$icon-calendar-alt2: "\ec37";
$icon-calendar-check2: "\ec38";
$icon-calendar-minus2: "\ec39";
$icon-calendar-plus2: "\ec3a";
$icon-calendar-times2: "\ec3b";
$icon-calendar2: "\ec3c";
$icon-caret-square-down2: "\ec3d";
$icon-caret-square-left2: "\ec3e";
$icon-caret-square-right2: "\ec3f";
$icon-caret-square-up2: "\ec40";
$icon-chart-bar2: "\ec41";
$icon-check-circle2: "\ec42";
$icon-check-square2: "\ec43";
$icon-circle2: "\ec44";
$icon-clipboard2: "\ec45";
$icon-clock2: "\ec46";
$icon-clone2: "\ec47";
$icon-closed-captioning2: "\ec48";
$icon-comment-alt2: "\ec49";
$icon-comment2: "\ec4a";
$icon-comments2: "\ec4b";
$icon-compass2: "\ec4c";
$icon-copy2: "\ec4d";
$icon-copyright2: "\ec4e";
$icon-credit-card2: "\ec4f";
$icon-dot-circle2: "\ec50";
$icon-edit2: "\ec51";
$icon-envelope-open2: "\ec52";
$icon-envelope2: "\ec53";
$icon-eye-slash2: "\ec54";
$icon-file-alt2: "\ec55";
$icon-file-archive2: "\ec56";
$icon-file-audio2: "\ec57";
$icon-file-code2: "\ec58";
$icon-file-excel2: "\ec59";
$icon-file-image2: "\ec5a";
$icon-file-pdf2: "\ec5b";
$icon-file-powerpoint2: "\ec5c";
$icon-file-video2: "\ec5d";
$icon-file-word2: "\ec5e";
$icon-file2: "\ec5f";
$icon-flag2: "\ec60";
$icon-folder-open2: "\ec61";
$icon-folder2: "\ec62";
$icon-frown2: "\ec63";
$icon-futbol2: "\ec64";
$icon-gem2: "\ec65";
$icon-hand-lizard2: "\ec66";
$icon-hand-paper2: "\ec67";
$icon-hand-peace2: "\ec68";
$icon-hand-point-down2: "\ec69";
$icon-hand-point-left2: "\ec6a";
$icon-hand-point-right2: "\ec6b";
$icon-hand-point-up2: "\ec6c";
$icon-hand-pointer2: "\ec6d";
$icon-hand-rock2: "\ec6e";
$icon-hand-scissors2: "\ec6f";
$icon-hand-spock2: "\ec70";
$icon-handshake2: "\ec71";
$icon-hdd2: "\ec72";
$icon-heart2: "\ec73";
$icon-hospital2: "\ec74";
$icon-hourglass2: "\ec75";
$icon-id-badge2: "\ec76";
$icon-id-card2: "\ec77";
$icon-image2: "\ec78";
$icon-images2: "\ec79";
$icon-keyboard2: "\ec7a";
$icon-lemon2: "\ec7b";
$icon-life-ring2: "\ec7c";
$icon-lightbulb2: "\ec7d";
$icon-list-alt2: "\ec7e";
$icon-map2: "\ec7f";
$icon-meh2: "\ec80";
$icon-minus-square2: "\ec81";
$icon-money-bill-alt2: "\ec82";
$icon-moon2: "\ec83";
$icon-newspaper2: "\ec84";
$icon-object-group2: "\ec85";
$icon-object-ungroup2: "\ec86";
$icon-paper-plane2: "\ec87";
$icon-pause-circle2: "\ec88";
$icon-play-circle2: "\ec89";
$icon-plus-square2: "\ec8a";
$icon-question-circle2: "\ec8b";
$icon-registered2: "\ec8c";
$icon-save2: "\ec8d";
$icon-share-square2: "\ec8e";
$icon-smile2: "\ec8f";
$icon-snowflake2: "\ec90";
$icon-square2: "\ec91";
$icon-star-half2: "\ec92";
$icon-star2: "\ec93";
$icon-sticky-note2: "\ec94";
$icon-stop-circle2: "\ec95";
$icon-sun2: "\ec96";
$icon-thumbs-down2: "\ec97";
$icon-thumbs-up2: "\ec98";
$icon-times-circle2: "\ec99";
$icon-trash-alt2: "\ec9a";
$icon-user-circle2: "\ec9b";
$icon-user2: "\ec9c";
$icon-window-close2: "\ec9d";
$icon-window-maximize2: "\ec9e";
$icon-window-minimize2: "\ec9f";
$icon-window-restore2: "\eca0";
