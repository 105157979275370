.dialog-transition-open {
  @include transition-property(opacity, top, right, bottom, left);
  @include transition-duration(0s);
  @include transition-delay(0s);
  @include transition-timing-function(ease-in-out);
}

.dialog-transition-closed {
  @include transition-property(opacity, top, right, bottom, left);
  @include transition-duration(0s);
  @include transition-delay(250ms);
  @include transition-timing-function(ease-in-out);
}

.dialog-transition-open-article {
  @include transition-property(opacity, transform);
  @include transition-duration(250ms);
  @include transition-delay(250ms);
  @include transition-timing-function(ease, cubic-bezier(0.175, 0.885, 0.32, 1.275));
}

.dialog-transition-closed-article {
  @include transition-property(opacity, transform);
  @include transition-duration(250ms);
  @include transition-delay(0s);
  @include transition-timing-function(ease, cubic-bezier(0.175, 0.885, 0.32, 1.275));
}

%dialog_base {
  @extend %flex-center-container;
  position: fixed;
  padding: 0;
  border: none;
  text-align: center;
  background-color: transparent;

  > article {
    @extend %flex_center_box;
    width: auto;
    margin: 2rem;
    padding: 0;
    text-align: left;
    opacity: inherit;

    border-width: 1px;
    border-style: solid;

    @include border-radius(0.3rem);

    @include boxshadow(0 0 0.3rem $black-01);

    @include themify(
      (dark: (
        color: $ux_modal_text_color,
        background-color: $ux_modal_background_color
      )),
      (light: (
        color: $black-1,
        background-color: $white-1
      ))
    );

    > header {
      cursor: default;
      position: relative;
      padding: 1rem 2rem;
      @include no-select();

      h4, h5 {
        margin: 0;
        padding: 0;
        line-height: normal;
      }

      h4 {
        position: relative;
        margin: 1.5rem 0 1rem;
        font-size: 1.6rem;
        font-weight: 300;
      }

      h5 {
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-size: 1rem;
        font-weight: 200;

        @include themify(
          (dark: (
            color: $ux_modal_text_color
          )),
          (light: (
            color: $black-05
          ))
        );
      }
    }

    section {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;

      > div {
        padding: 0 2rem 0 3rem;
        @include no-select();

        > label {
          display: block;
          margin: 2rem 0;
        }

        > h4 {
          margin: 0 0 1rem;
          padding: 0;
          font-size: 1.4rem;
          font-weight: 300;
        }
        > h5 {
          margin: 0;
          padding: 0;
          font-size: 1.2rem;
          font-weight: 200;
          @include themify(
            (dark: (
              color: $ux_modal_text_color
            )),
            (light: (
              color: $black-05
            ))
          );
        }

        > .forms-module > .form-group.well {
          width: 100%;
        }

        blockquote {
          margin: 0 0 1rem;
          padding: 0.3rem 0 0.2rem 2rem;
          border-width: 0.1rem 0.1rem 0.1rem 0.2rem;
          border-style: solid;

          @include border-radius(0.2rem);

          @include themify(
            (dark: (
              border-color: $ux_border_color,
              background-color: $white-008
            )),
            (light: (
              border-color: $black-01,
              background-color: $black-008
            ))
          );

          &.success { border-left-color: $ux_success_color !important; }
          &.info { border-left-color: $ux_info_color !important; }
          &.warning { border-left-color: $ux_warning_color !important; }
          &.danger { border-left-color: $ux_danger_color !important; }
          &.accent { border-left-color: $ux_accent_base_color !important; }
          &.trivial { border-left-color: $ux_neutral_color !important; }

          > h3, h4, h5 {
            margin: 0.8rem 0 0.5rem;
          }

          a {
            color: $ux_info_color;
          }

          ul, ol {
            margin: 0.5rem 1.6rem 0.7rem 0;
            padding: 0 0 0 1.4rem;
          }

          > p {
            margin: 0.3rem 1.6rem 0.4rem 0;
            padding: 0;
            font-size: 1.2rem;
            > * {
              font-size: inherit;
            }
            em {

              @include themify(
                (dark: (
                  color: $ux_modal_text_color
                )),
                (light: (
                  color: $black-09
                ))
              );
            }
          }

        }

        p {
          margin: 0;
          padding: 1rem 0;
          font-size: 1.2rem;
          font-weight: 200;
          line-height: 1.8;

          @include themify(
            (dark: (
              color: $ux_modal_text_color
            )),
            (light: (
              color: $black-08
            ))
          );
        }
      }

      footer {
        margin: 2rem 0 0;
        padding: 2rem;
        text-align: right;
        border-width: 1px 0 0;
        border-style: solid none none;

        @include themify(
          (dark: (
            border-color: $ux_border_color
          )),
          (light: (
            border-color: $black-02
          ))
        );

        label {
          display: inline-block;
          margin: 0 0 0 0.3rem;
          > button {
            height: $form_element_size;
          }
        }
      }

    }

    &.mode-default {
      @include themify(
        (dark: (
          border-color: $ux_border_color
        )),
        (light: (
          border-color: $black-03
        ))
      );
      > header {
        padding-left: 3rem;
      }
    }

    &.mode-success {
      border-color: $ux_success_color;
      blockquote { border-left-color: $ux_success_color !important; }
      input[type="submit"] { @extend .success; }
      > header {
        padding-left: 3rem;
      }
    }

    &.mode-info {
      border-color: $ux_info_color;
      header {
        h4 {
          &:before { content: $icon-info; background-color: $ux_info_color; }
        }
      }
      blockquote { border-left-color: $ux_info_color !important; }
      input[type="submit"] { @extend .info; }
    }

    &.mode-warning {
      border-color: $ux_warning_color;
      header {
        h4 {
          &:before { content: $icon-exclamation; background-color: rgba($ux_warning_color, 0.5); }
        }
      }
      blockquote { border-left-color: $ux_warning_color !important; }
      input[type="submit"] { @extend .warning; }
    }

    &.mode-danger {
      border-color: $ux_danger_color;
      header {
        h4 {
          &:before {
            content: $icon-minus;
            line-height: 1.7em;
            background-color: $ux_danger_color;
          }
        }
      }
      blockquote { border-left-color: $ux_danger_color !important; }
      input[type="submit"] { @extend .danger; }
    }

    &.mode-accent {
      border-color: $ux_accent_base_color;
      blockquote { border-left-color: $ux_accent_base_color !important; }
      input[type="submit"] { @extend .accent; }
    }

    &.mode-trivial {
      border-color: $ux_neutral_color;
      blockquote { border-left-color: $ux_neutral_color !important; }
      input[type="submit"] { @extend .trivial; }
    }

    &.mode-info,
    &.mode-warning,
    &.mode-danger {
      header {
        margin-left: 5rem;
        h4 {
          &:before {
            @extend .icon;
            position: absolute;
            top: 0rem;
            left: -4.8rem;
            display: block;
            width: 2.5rem;
            height: 2.5rem;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1.5em;
            text-align: center;
            color: $white-06;
            @include border-radius(1.3rem);
          }
        }
      }
      section {
        > * {
          margin-left: 7rem;
          padding-left: 0;
        }
      }
    }

    @include mq(desktop, laptop) {
      min-width: 80rem;
    }

    @include mq(tablet) {
      min-width: 28rem;
      max-width: 45rem;
    }

    .tabs {
      > dd {
        @include themify(
          (dark: (
            background-color: $ux_accent_base_color,
          )),
          (light: (
            background-color: $black-005
          ))
        );
      }
    }
  }

  > mask,
  > div.mask {
    cursor: not-allowed;
    position: fixed;
    z-index: -1;
    @include no-select();
  }

  &:not([open]) {
    @extend .dialog-transition-closed;
    z-index: -1;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    width: 1px;
    opacity: 0;
    background-color: transparent;

    @include no-select();
    pointer-events: none;

    > article {
      @extend .dialog-transition-closed-article;
      transform: scale(0.1);
    }

    > mask,
    > div.mask  {
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      background-color: transparent;
    }
  }

  &[open] {
    @extend .dialog-transition-open;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    @include auto-select();
    pointer-events: auto;

    > article {
      @extend .dialog-transition-open-article;
      transform: scale(1);
    }

    > mask,
    > div.mask  {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      @include themify(
        (dark: (
          background-color: $black-05
        )),
        (light: (
          background-color: $white-05
        ))
      );
    }
  }
}
