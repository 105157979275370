%breadcrumbs_base {
  &:before {
    display: inline-block;
    vertical-align: middle;
    margin: 0 1.5rem 0 0;
    padding: 0.3rem;

  }
}

%breadcrumbs_icon {
  @extend %breadcrumbs_base;
  &:before {
    @extend .icon;
    line-height: 1.15;
    color: $ux_primary_color;
    @include border(0.2rem solid $ux_primary_color, false);
    @include border-radius(0.8rem);
  }
}

%breadcrumbs_image {
  @extend %breadcrumbs_base;
  &:before {
    @include background-image();
    width: 4rem;
    height: 4rem;
  }

  @include mq(tablet) {
    &:before {
      width: 2rem;
      height: 2rem;
    }
  }
}

%main_section_header_breadcrumbs {
  nav {
    margin-left: 3rem;
    font-size: 1.2rem;
    font-weight: 200;

    @include mq(tablet) {
      margin: 2rem 0 1rem 0;
    }

    ol {
      list-style: none;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      li {
        > a, span {
          font-size: 1.2rem;
          font-weight: 200;
          @include themify(
            (dark: (
              color: $white-05
            )),
            (light: (
              color: $black-05
            ))
          );
        }
        &:before {
          content: "/";
          margin: 0 0.8rem 0 1rem;
          opacity: 0.8;
        }
        &:first-child {
          &:before {
            content: "";
            margin: 0;
          }
          > a {
            &:before {
              @extend .icon;
              content: $icon-home;
              color: $ux_primary_color;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
