@media only print {
  #root > header,
  #root > footer,
  #root > dialogs,
  #root > activity,
  #root > main > aside,
  #root > main > section > header {
    display: none;
  }

  #root > main > section > * {
    margin: 0 !important;
    padding: 0 !important;
  }

  #root > main > section {
    a,
    form,
    select,
    input,
    textarea,
    tfoot {
      display: none !important;
    }

    th > span {
      display: none !important;
    }

    * {
      font-size: 90% !important;
      color: black !important;
      &:before,
      &:after {
        color: black !important;
      }
    }
  }
}
