@import 'anchors';
@import 'dl';
@import 'div';
@import 'menu';

%header_nav {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  @include no-select();

  > ul {
    display: inline-block;
    vertical-align: middle;
    height: $site_header_height;
    white-space: nowrap;

    > li {
      position: relative;
      display: inline-block;
      vertical-align: top;
      white-space: unset;
      margin: 0 ;
      padding: 0;
      font-size: $site_base_font_size;
      font-weight: 300;

      > a {
        cursor: pointer;
        display: block;
        height: $site_header_height;
        padding: 0 2rem 0 1.5rem;
        font-weight: 400;
        line-height: $site_header_height;
        text-decoration: none;
        overflow: hidden;

        @include themify(
          (dark: (
            color: $ux_normal_text_color
          )),
          (light: (
            color: $black-08
          ))
        );

        &:hover {
          @include themify(
            (dark: (
              color: $white-1,
              background-color: $ux_accent_base_color,

            )),
            (light: (
              color: $black-1,
              background-color: $black-005
            ))
          );
        }

        &[href^="http"] {
          &:hover {
            background-color: $ux_primary_color;
          }
        }
      }

      @extend %header_nav_menu_dl_base;
      @extend %header_nav_menu_div_base;

      &.notifications {
        @extend %header_nav_menu_notifications;
      }
      &.tools {
        @extend %header_nav_menu_tools;
      }
      &.divider {
        @extend %header_nav_menu_divider;
      }
      &.user {
        @extend %header_nav_menu_user;
        @extend %definition_list_sub_nav;
        @extend %definition_list_user_menu;
      }

      &.active {
        > a {
          @include themify(
            (dark: (
              color: $white-1,
              background-color: $ux_accent_base_color
            )),
            (light: (
              color: $black-08,
              background-color: mix(white, $black-05, 60%)
            ))
          );
        }
      }
    }
  }

  @include mq(tablet) {
    top: 100%;
    left: 0;
    border-width: 1px 0 0;
    border-style: solid none none;
    overflow-x: hidden;
    overflow-y: hidden;

    @include transitions(opacity);
    @include transform-origin(top);

    @include themify(
      (dark: (
        color: $dark-header-color,
        background-color: $dark-header-background-color,
        border-color: #000
      )),
      (light: (
        color: $light-header-color,
        background-color: $light-header-background-color,
        border-color: #ccc
      ))
    );

    > ul {
      width: 100%;
      > li {
        display: list-item;
        > a {
          width: 100%;
          padding-right: 0;
        }
      }
      &.masquerade {
        margin-top: 5.2rem;
      }
    }

    &:not(.active) {
      height: 0;
      opacity: 0;
      @include transform(scaleY(0));
      @include animation(do-vertical-collapse 500ms ease forwards);
    }

    &.active {
      height: 40rem;
      opacity: 1;
      overflow-y: auto;
      @include animation(do-vertical-expand 500ms ease forwards);
    }
  }
}
