@mixin AddTableActionMiniIcon($selector, $icon) {
  td.cell-menu {
    > div {
      ul, ol {
        > li {
          > button {
            @include empty-button();
            &.#{$selector} {
              @extend %icon-#{$icon};
            }
            &:before {
              @extend .icon;
              display: block;
            }
          }
        }
      }
    }
  }
}

@mixin AddHeaderIcon($selector, $icon) {
  main > section > header > h2 {
    &.#{$selector} {
      &:before {
        @include iconz();
        display: inline-block;
        vertical-align: middle;
        width: 4.5rem;
        height: 4.5rem;
        margin: 0 1.5rem 0 0;
        padding: 0.3rem;
        font-size: 3rem;
        line-height: 1.15;
        text-align: center;
        color: $ux_primary_color;
        @include border(0.2rem solid $ux_primary_color, false);
        @include border-radius(0.8rem);
      }
      @extend %icon-#{$icon};
    }
  }
}

@mixin AddHeaderMenuIcon($selector, $icon) {
  header > nav.header > ul > li {
    &.#{$selector} {
      > a {
        position: relative;

        @extend %icon-#{$icon};
        &:before,
        &:after {
          @extend .icon;
          display: inline-block;
          vertical-align: top;
        }

        &:before {
          padding-right: 0.5rem;
        }
      }
    }
  }
}

@mixin AddIcon($selector, $icon) {
  button {
     &.#{$selector} {
      &:before {
        @include iconz();
      }
      @extend %icon-#{$icon};
    }
  }
}

@mixin AddAnchorIcon($selector, $icon) {
  a {
    &.#{$selector} {
      &:before {
        @include iconz();
        margin: 0 1.4rem 0 0;
        font-size: 110%;
        text-align: center;
      }
      @extend %icon-#{$icon};
    }
  }
}

@mixin AddNavIcon($selector, $icon) {
  main > aside > nav ul > li {
    @include AddAnchorIcon($selector, $icon);
  }
}

@mixin ApplySolidGaugeColors($selector, $colors...) {
  .solid-gauge {
    &.#{$selector} {
      .highcharts-series-group {
        @for $i from 1 through length($colors) {
          $color: nth($colors, $i);
          .highcharts-series-#{$i - 1} {
            > path {
              fill: $color !important;
            }
          }
        }
      }
      .highcharts-pane-group {
        path {
          @for $i from 1 through length($colors) {
            $color: nth($colors, $i);
            &:nth-child(#{$i}) {
              @include themify(
                (dark: (
                  fill: tint($color, 30)
                )),
                (light: (
                  fill: wash($color, 30)
                ))
              );
            }
          }
        }
      }
      .highcharts-tooltip {
        @for $i from 1 through length($colors) {
          $color: nth($colors, $i);
          i.highcharts-marker-#{$i - 1} {
            background-color: $color;
          }
        }
      }
    }
  }
}

@mixin ApplyPieGaugeColors($selector, $colors...) {
  .pie-gauge {
    &.#{$selector} {
      .highcharts-pie-series {
        @for $i from 1 through length($colors) {
          $color: nth($colors, $i);
          path.highcharts-point.highcharts-color-#{$i - 1} {
            fill: $color !important;
          }
        }
      }
      .highcharts-data-labels {
        @for $i from 1 through length($colors) {
          $color: nth($colors, $i);
          path.highcharts-data-label-connector.highcharts-color-#{$i - 1} {
            stroke: $color !important;
            stroke-width: 2;
          }
        }
      }
    }

    .highcharts-subtitle {
      font-size: 1.4rem;
    }

    .pie-data-labels {
      margin: 0.2rem;
      padding: 0.2rem 0.5rem;
      font-size: 1.2rem;
      font-weight: 300;
      white-space: nowrap;
      @include border(1px solid, false);
      @include border-radius(0.2rem);

      @include themify(
        (dark: (
          color: $white-09,
          border-color: $ux_border_color,
          background-color: $ux_page_base_color
        )),
        (light: (
          color: $black-09,
          border-color: $black-02,
          background-color: $white-09
        ))
      );
    }
  }
}

@mixin ApplyInputIcon($icon, $type) {
  input[type=#{$type}] {
    background-repeat: no-repeat;
    background-position-x: $form_element_icon_background_position_x;
    background-position-y: $form_element_icon_background_position_y;
    @extend %input_icon_elements;
    @include themify(
      (dark: (
        background-image: url(svg-encode(svg-factory($icon, $white-02)))
      )),
      (light: (
        background-image: url(svg-encode(svg-factory($icon, $black-02)))
      ))
    );

    &:disabled {
      @include themify(
        (dark: (
          background-image: url(svg-encode(svg-factory($icon, $white-01)))
        )),
        (light: (
          background-image: url(svg-encode(svg-factory($icon, $black-01)))
        ))
      );
    }

    &:not(:disabled) {
      &:hover {
        @include themify(
          (dark: (
            background-image: url(svg-encode(svg-factory($icon, $white-04)))
          )),
          (light: (
            background-image: url(svg-encode(svg-factory($icon, $black-04)))
          ))
        );
      }
      &:focus {
        @include themify(
          (dark: (
            background-image: url(svg-encode(svg-factory($icon, $white-04)))
          )),
          (light: (
            background-image: url(svg-encode(svg-factory($icon, $black-04)))
          ))
        );
      }
    }
  }
}

@mixin ApplyCountryCursors($parent) {
  @include make-flags($parent);
}

@mixin ApplyCountryBackgrounds($parent) {
  @include make-flag-backgrounds($parent);
}

@mixin ApplyDataCountryBackgrounds($parent) {
  @include make-data-flag-backgrounds($parent);
}

@mixin RemoveAvatarImage($selector){
  header > nav.header > ul > li {
    &.#{$selector} {
      > a > i {
        display:none;
      }
    }
  }
}