
%tooltip_base {
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-style: normal;
  padding-right: 1.2rem;

  > var {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 9999;
    display: block;
    width: 1px;
    height: 1px;
    overflow: visible;

    &:before {
      @include iconz();
      content: $icon-info-circle;
      position: absolute;
      top: -0.4rem;
      left: -0.45rem;
      z-index: 2;
      width: 1rem;
      margin: 0;
      padding: 0;
      font-size: 1rem;
      font-weight: 300;
      line-height: normal;
      text-align: center;
      color: $ux_info_color;
      @include transitions(color);
    }

    &:after {
      content: attr(data-tooltip);
      position: absolute;
      top: -0.9rem;
      left: -1rem;
      z-index: 1;
      width: 0;
      opacity: 0;
      padding-right: 0;
      padding-left: 0;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      font-size: 1.1rem;
      font-weight: 300;
      line-height: inherit;
      text-align: center;
      white-space: nowrap;
      pointer-events: none;
      @include no-select();
      @include transitions(opacity, width, padding-right, padding-left);
      @include border(1px solid, false);
      @include border-radius(0.3rem);
      @include box-shadow-themed(0 0 0.5rem 0 $black-06, 0 0 0.5rem 0 $black-03);

      @include themify(
        (dark: (
          color: $white-08,
          background-color: $dark-form-input-background-color,
          border-color: $ux_border_color
        )),
        (light: (
          color: $black-08,
          background-color: $light-form-input-background-color,
          border-color: $black-01
        ))
      );
    }
  }

  &:hover {
    > var {
      &:before {
        @include themify(
          (dark: (
            color: $white-01
          )),
          (light: (
            color: $black-01
          ))
        );
      }

      &:after {
        width: auto;
        opacity: 1;
        padding-right: 0.7rem;
        padding-left: 2rem;
      }
    }
  }
}

h2, h3, h4 {
  .tooltip {
    > var {
      top: -0.1rem;
      right: -0.3rem;
    }
  }
}

.tooltip {
  > button {
    + var {
      top: 0rem;
      right: -0.1rem;
    }
  }
}

button {
  > .tooltip {
    height: unset !important;
    padding-right: 0.6rem !important;
    var {
      top: 0;
      right: 0;
    }
  }
}

p {
  > .tooltip {
    padding-right: 1.2rem !important;
    text-decoration: underline dotted;
    @include themify(
      (dark: (
        text-decoration-color: $white-05
      )),
      (light: (
        text-decoration-color: $black-05
      ))
    );
    var {
      top: 0rem;
      right: 0.6rem;
    }
  }
}
