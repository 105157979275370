%main_section_article_code {
  code {
    font-size: 1.4rem;
    &.code {
      color: deeppink;
    }
  }

  small {
    code {
      font-size: inherit;
    }
  }
}
