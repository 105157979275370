%calendar_label {
  > label {
    position: relative;
    z-index: 0;
    display: inline-block;
    vertical-align: middle;
    min-width: 24rem;
    margin: 0;
    padding-top: 0.7rem;
    border-width: 1px;
    border-style: solid;

    @include border-radius($form_element_border_radius);

    @include themify(
      (dark: (
        color: $white-1,
        background-color: $dark-form-input-background-color,
        border-color: $dark-form-input-border-color
      )),
      (light: (
        color: $black-1,
        background-color: $light-form-input-background-color ,
        border-color: $light-form-input-border-color
      ))
    );

    &:before {
      @extend .icon;
      content: $icon-caret-down;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 3.3rem;
      height: 3.3rem;
      margin: 0;
      font-size: 1.6rem;
      line-height: 2em;
      text-align: center;
      pointer-events: none;
      @include no-select();
      @include transitions(transform);
      @include border-radius(1.7rem);

      @include themify(
        (dark: (
          color: $white-03
        )),
        (light: (
          color: $black-03
        ))
      );
    }

    &:hover {
      &:before {
        @include themify(
          (dark: (
            color: $white-06
          )),
          (light: (
            color: $black-06
          ))
        );
      }
    }
  }

  &:not(.active) {
    > label {
      &:before {
        @include transform(rotate(90deg));
      }
    }
  }

  &.active {
    > label {
      &:before {
        @include transform(rotate(0deg));
      }
    }
  }
}
