%error_output_base {
  position: relative;
  z-index: 1;
  display: none;
  vertical-align: middle;
  margin: 0.1rem 0 0 0;
  padding: 0.1rem 0.5rem 0.1rem 0.6rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: $ux_danger_color;
  background-color: rgba($ux_danger_color, 0.2);
  border-color: rgba($ux_danger_color, 0.6);
  @include border(1px solid, false);
  @include border-radius($form_element_border_radius);
  @include no-select();
  pointer-events: none;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &:before {
    @include themify(
      (dark: (
        background-color: $white-05
      )),
      (light: (
        background-color: $black-05
      ))
    );
  }
  &:after {
    @include themify(
      (dark: (
        background-color: $black-08
      )),
      (light: (
        background-color: $white-08
      ))
    );
  }
}

input + output, 
span.as-input + output {
  @extend %error_output_base;
}
textarea + output {
  @extend %error_output_base;
}
input:invalid + output {
  display: inline-block;
}
select:invalid + output {
  display: inline-block;
}
textarea:invalid + output {
  display: inline-block;
}
