
%calendar_list_presets {
  > li {
    display: list-item;
    margin: 0.4rem 0 0;
    padding: 0;

    > button {
      width: 100%;
      height: 3.3rem;
      margin: 0;
      padding: 0 3rem;
      font-size: 1.2rem;
      font-weight: 200;
      text-align: center;
      background-color: transparent;
      @include border(1px solid transparent, false);
      @include border-radius($form_element_border_radius);

      @include themify(
        (dark: (
          color: $white-08,
          border-color: $ux_border_color
        )),
        (light: (
          color: $black-08,
          border-color: $black-02
        ))
      );

      &:hover {
        @include themify(
          (dark: (
            color: $white-1,
            border-color: $ux_info_color
          )),
          (light: (
            color: $white-1,
            border-color: $ux_info_color
          ))
        );
      }

      &.active {
        @include themify(
          (dark: (
            color: $white-1,
            background-color: $ux_info_color,
            border-color: mix(white, $ux_info_color, 20%)
          )),
          (light: (
            color: $white-1,
            background-color: $ux_info_color,
            border-color: mix(black, $ux_info_color, 20%)
          ))
        );
      }


    }

    &:first-child {
      margin-top: -0.1rem;
    }
  }
}

%calendar_list_custom {
  > li {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 1rem;

    &:first-child {
      margin-left: 0;
    }
    @include mq(tablet) {
      display: block;
      margin-top: 1rem;
      margin-left: 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
  > h5 {
    margin: 0.5rem auto 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    @include themify(
      (dark: (
        color: $white-03
      )),
      (light: (
        color: $black-03
      ))
    );
  }
}

%calendar_list_base {
  list-style: none;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 1rem;
  padding: 0;

  &.presets {
    @extend %calendar_list_presets;
  }
  &.custom {
    @extend %calendar_list_custom;
  }

  @include mq(tablet) {
    display: block;
    margin: 1rem 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
}
