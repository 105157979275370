%icon_success {
  &:before {
    color: $ux_success_color;
  }
}

%content_icons {
  position: relative;
  display: inline-block;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  font-style: normal;
  line-height: 1em;
  text-align: center;

  &:before {
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    text-align: center;
  }
}

i {
  cursor: default;
  &.home,
  &.chart-area {
    @extend .icon;
    margin: 0 0.5rem;
  }

  &.home { @extend %icon-home; }
  &.chart-area {
    @extend %icon-chart-area;
    &.active {
      @extend %icon_success;
    }
  }
}

a {
  > i {
    &.home,
    &.chart-area {
      cursor: pointer;
    }
  }
  &:hover {
    > i {
      &.home,
      &.chart-area {
        @extend %icon_success;
      }
    }
  }

  &.home,
  &.chart-area {
    text-decoration: none;
    > i {
      @extend .icon;
      margin: 0 0.5rem;
    }
    &:hover {
      text-decoration: none;
      > i {
        @extend %icon_success;
      }
    }
  }
  &.home { > i { @extend %icon-home; } }
  &.chart-area { > i { @extend %icon-chart-area; } }
}


@include root {
  > main {
    > section {
      > article {
        i {
          @extend %content_icons;
        }
      }
    }
  }
}
