@import 'body';
@import 'debug';

html {
  height: 100%;
  overflow: hidden;
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  body {
    // Themify does not correctly build the body colors inside of a mixin
    // TODO: rollup coreui and update to dart sass to fix this
    @include themify(
      (dark: (
        color: $dark-body-color,
        background-color: $dark-body-background-color
      )),
      (light: (
        color: $light-body-color,
        background-color: $light-body-background-color
      ))
    );
  }

  @extend %html_body;
  @extend %html_debug;
}

b, strong {
  font-weight: 600;
}

.no-wrap {
  white-space: nowrap;
  > * {
    white-space: normal;
  }
}

.text-align {
  display: block;
  &.left {
    text-align: left !important;
  }
  &.right {
    text-align: right !important;
  }
  &.center {
    text-align: center !important;
  }
}

.inline {
  white-space: nowrap;
  > * {
    white-space: normal;
  }

  > *:not(table):not(thead):not(tbody):not(tfoot) {
    display: inline-block;
    vertical-align: middle;
  }
  > label {
    &:first-child {
      margin-left: 0;
    }
  }
  &.inline-top {
    > * {
      vertical-align: top;
    }
  }

  &.inline-half {
    > * {
      width: 48%;
      margin-left: 4%;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  @include mq(tablet) {
    &.inline-half {
      > * {
        display: block;
        width: 96%;
        margin-right: 2%;
        margin-left: 2%;
        &:first-child {
          margin-left: 2%;
        }
      }
    }
  }
}

.capitalize {
  text-transform: capitalize !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.float-right {
  float: right;
}

*,
:after,
:before {
  @include box-sizing(border-box);
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
}

progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

progress:not([value]) {
  -webkit-appearance: none;
  -moz-appearance: none;
}
