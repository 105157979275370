
input:autofill { background-color: transparent; }

// IE
::-ms-clear { display: none; width: 0; height: 0; }
::-ms-reveal { display: none; width: 0; height: 0; }
input:-ms-input-placeholder { @include no-select(); }
select:-ms-input-placeholder { @include no-select(); }
select::-ms-expand { display: none; }

// firefox
::-moz-clear { display: none; }
::-moz-focus-inner { outline: none; border: none; }
::-moz-color-swatch { border: 0 none transparent; outline: none; @include border-radius($form_element_border_radius); }

input[type=range]::-moz-range-track {
  height: 1.1rem;
  overflow: hidden;
  @include themify(
    (dark: (
      background-color: $ux_accent_base_color,
    )),
    (light: (
      background-color: $black-005
    ))
  );
}
input[type=range]::-moz-range-thumb {
  @include border(0 none transparent, false);

  @include themify(
    (dark: (
      background-color: $white-05
    )),
    (light: (
      background-color: $black-05
    ))
  );
}

input[type=color]:focus::-moz-color-swatch { border: 0 none transparent; outline: none; }

input[type=file]::file-selector-button {
  cursor: pointer;
  position: relative;
  z-index: -1;
  width: $form_element_size;
  height: $form_element_size;
  margin: 0 1rem 0 0;
  padding: 0;
  overflow: hidden;
  color: transparent;
  background: transparent;
  @include border(1px solid, false);
  @include themify(
    (dark: (
      border-color: $ux_border_color,
      background-color: $black-01
    )),
    (light: (
      border-color: $black-05,
      background-color: $white-01
    ))
  );
}

input::-moz-placeholder { @include no-select(); }
select::-moz-placeholder { @include no-select(); }
textarea::-moz-placeholder { @include no-select(); }

select:-moz-select-list-box {
  // overflow-x: hidden;
  overflow: hidden !important;
  @include border-radius($form_element_border_radius);
}

// webkit
::-webkit-search-decoration { display: none; width: 0; height: 0; }
::-webkit-search-cancel-button { display: none; width: 0; height: 0; }
::-webkit-search-results-button { display: none; width: 0; height: 0; }
::-webkit-search-results-decoration { display: none; width: 0; height: 0; }
::-webkit-credentials-auto-fill-button { opacity: 0; width: 0; height: 0; }

@supports (-webkit-hyphens: none) {
  ::-webkit-datetime-edit {
    font-family: $site_font_family;
    margin: 0.5rem 0 0;
    line-height: normal;
  }
}

::-webkit-color-swatch-wrapper { padding: 0; border: 0 none transparent; }
::-webkit-color-swatch { border: 0 none transparent; }
::-webkit-calendar-picker-indicator {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 2.4rem;
  height: 3.3rem;
  opacity: 0;
  @include no-select();
}

input[type=time]::-webkit-calendar-picker-indicator { left: -0.8rem; }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  background-clip: initial;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
input[type=number] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}

input[type=range]::-webkit-slider-runnable-track {
  height: 1.2rem;
  @include themify(
    (dark: (
      background-color: $ux_accent_base_color,
    )),
    (light: (
      background-color: $black-005
    ))
  );
}

input[type=range]::-webkit-slider-thumb {
  width: 1.2rem;
  height: 1.2rem;
  -webkit-appearance: none;
  @include border(0 none transparent, false);
  @include border-radius(0.65rem);
  @include themify(
    (dark: (
      background-color: $white-05
    )),
    (light: (
      background-color: $black-05
    ))
  );
}

input[type=file]::-webkit-file-upload-button {
  width: $form_element_size;
  height: $form_element_size;
  margin: 0 1rem 0 0;
  padding: 0;
  overflow: hidden;
  color: transparent;
  background: transparent;
  @include border(1px solid, false);
  @include border-radius($form_element_border_radius);
  @include themify(
    (dark: (
      border-color: $ux_border_color,
      background-color: $black-01
    )),
    (light: (
      border-color: $black-05,
      background-color: $white-01
    ))
  );
}

input::-webkit-input-placeholder { @include no-select(); }
select::-webkit-input-placeholder { @include no-select(); }
