%input_label_kbd {
  kbd {
    position: absolute;
    display: inline-block;
    right: 0.5rem;
    padding: 0.25rem 0.4rem;
    font-family: $site_font_family;
    font-size: 1.2rem;
    font-weight: 300;
    font-style: normal;
    text-transform: uppercase;
    line-height: normal;
    pointer-events: none;
    @include no-select();
    @include border-radius(0.2rem);
    @include border(1px solid, false);
    @include themify(
      (dark: (
        color: $white-03,
        border-color: $ux_border_color,
        background-color: $ux_accent_base_color,
      )),
      (light: (
        color: $black-03,
        border-color: $black-01,
        background-color: $black-005
      ))
    );

    > span.icon {
      &:before {
        @extend .icon;
        font-size: 1.1rem;
        font-weight: 100;
        line-height: 1em;
        color: inherit;
        text-align: center;
      }

      &.icon-ban { @extend %icon-ban; }
    }
  }

  input ~ kbd, 
  span.as-input ~ kbd {
    margin-top: -2.7rem;
  }

  &.kbd {
    input {
      padding-right: 3rem !important;
    }
  }
}

%input_label_responsive {
  @include mq(desktop) {
    min-width: $input_width_desktop;
  }

  @include mq(laptop) {
    min-width: $input_width_laptop;
  }

  @include mq(tablet) {
    min-width: $input_width_tablet;
  }
}

%input_label_base_input_select_textarea {
  font-size: $form_element_font_size;
  @include themify(
    (dark: (
      background-color: $dark-form-input-background-color
    )),
    (light: (
      background-color: $light-form-input-background-color
    ))
  );
}

%input_label_dfn {
  > dfn {
    display: block;
    margin: 0 0 0.3rem;
    font-size: 1.3rem;
    font-style: normal !important;

    @include themify(
      (dark: (
        font-weight: 300,
        color: $white-07
      )),
      (light: (
        font-weight: 400,
        color: $black-07
      ))
    );
  }
}


%input_label_base {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  height: auto;
  margin: 0 0 $form_element_buffer;
  padding: 0.6rem 0 0;
  font-size: $form_element_font_size;
  text-align: left;
  overflow: visible;

  > label {
    span {
      font-size: $form_element_font_size;
    }
  }

  &:not(.disabled) {
    > label {
      cursor: pointer;
    }
  }

  &.disabled {
    > label {
      cursor: not-allowed;
    }
  }

  &.button {
    @extend %button_input_label;
  }

  &.checkbox {
    @extend %checkbox_input_label;
    > label {
      @extend %input_label_responsive;
    }
  }

  &.color {
    @extend %color_input_label;
    > label {
      @extend %input_label_responsive;
    }
  }

  &.file {
    @extend %file_input_label;
    > label {
      @extend %input_label_responsive;
    }
  }

  &.radio {
    @extend %radio_input_label;
  }

  &.hidden {
    @extend %hidden_input_label;
  }

  &.range {
    @extend %range_input_label;
    > label {
      @extend %input_label_responsive;
    }
  }

  &:not(.checkbox):not(.radio) {
    @extend %input_label_responsive;
  }

  input:not([required]):invalid {
    @include box-shadow-themed(
      0 0 0.3rem 0.1rem $ux_danger_color inset,
      0 0 0.3rem 0.1rem $ux_danger_color inset,
    );
  }

  textarea:not([required]):invalid {
    @include box-shadow-themed(
      0 0 0.3rem 0.1rem $ux_danger_color inset,
      0 0 0.3rem 0.1rem $ux_danger_color inset,
    );
  }

  &.dirty {
    input[required]:invalid {
      @include box-shadow-themed(
        0 0 0.3rem 0.1rem $ux_danger_color inset,
        0 0 0.3rem 0.1rem $ux_danger_color inset,
      );
    }
  }

  input {
    @extend %input_label_base_input_select_textarea;
  }
  select {
    @extend %input_label_base_input_select_textarea;
  }
  textarea {
    @extend %input_label_base_input_select_textarea;
  }
}

%input_label_widths {
  @include input-element-widths();
  &.auto-width {
    textarea {
      width: unset !important;
    }
    &.checkbox {
      > label {
        min-width: unset !important;
      }
    }
  }
}

%input_label_help {
  + span.help {
    display: inline-block;
    vertical-align: top;
    margin-top: -0.8rem 0.3rem 0 0.5rem;
  }
  > span.help {
    position: absolute;
    top: -0.2rem;
    right: -0.8rem;
    margin: 0;
  }
  > dfn {
    > span.help {
      margin-top: -0.5rem !important;
      margin-left: 0.5rem !important;
    }
  }
}

%input_label_tooltip {
  i.tooltip {
    position: absolute !important;
    right: -0.5rem;
    height: unset !important;
    z-index: 999;
  }
}

%input_label_button {
  &.button {
    cursor: unset;
    &:not(.labelled) {
      > button {
        &.labelled {
          margin-top: 2.4rem;
        }

        &.icon {
          &.labelled {
            margin-top: 2.5rem;
          }
        }
      }
    }
  }
}


label {
  &.input-label {
    @extend %input_label_base;
    @extend %input_label_widths;
    @extend %input_label_dfn;
    @extend %input_label_kbd;
    @extend %input_label_help;
    @extend %input_label_tooltip;
    @extend %input_label_button;
  }
}

// establish layers
form {
  position: relative;
  z-index: 0;
  label.input-label {
    @include zindex-children(100, 50, true);
  }
}
table {
  .float-right {
    label.input-label {
      i.tooltip {
        left: -0.5rem;
        right: unset;
      }
    }
  }
}
