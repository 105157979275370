select {
  position: relative;
  cursor: pointer;

  @extend %elements_base;

  @include input-element-widths();

  @include border(1px solid, false);
  @include border-radius($form_element_border_radius);

  @include themify(
    (dark: (
      background-color: $dark-form-input-background-color
    )),
    (light: (
      background-color: $light-form-input-background-color
    ))
  );

  > option {
    cursor: pointer;
    display: block;
    height: 2.4rem;
    padding: 0.7rem 0.7rem 0.6rem 1rem;
    @include appearance(none);
  }
}

// regular select menus
select:not([multiple]) {
  height: $form_element_size;
  padding: 0.4rem 0.7rem;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;

  @include appearance(none);

  @include transitions(background-color);
  @include themify(
    (dark: (
      background-image: url(svg-encode(svg-factory($svg-caret-down, $white-05)))
    )),
    (light: (
      background-image: url(svg-encode(svg-factory($svg-caret-down, $black-05)))
    ))
  );

  &:hover {
    background-color: $ux_info_color;
    @include themify(
      (dark: (
        background-image: url(svg-encode(svg-factory($svg-caret-down, $white-08)))
      )),
      (light: (
        background-image: url(svg-encode(svg-factory($svg-caret-down, $black-08)))
      ))
    );
  }

  > option {
    display: block;

    @include themify(
      (dark: (
        color: $white-08,
        background-color: $dark-form-input-background-color
      )),
      (light: (
        color: $black-08,
        background-color: $light-form-input-background-color
      ))
    );
  }
}

// multiple select menus
select[multiple] {
  @include appearance(none);

  > option {
    color: inherit;
    border-color: inherit;
    &::selection {
      @include themify(
        (dark: (
          color: $black-08,
          background-color: $white-01
        )),
        (light: (
          color: $white-08,
          background-color: $black-01
        ))
      );
    }
  }

}

// Safari select menus
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    select[multiple] {
      padding: 0.7rem 0.4rem 0.6rem 0.6rem;
    }
  }
}

