%auth_wrapper {
  form.auth-form-wrapper {
    padding: 0 2rem;

    @include border(1px solid, false);
    @include border-radius(0.3rem);

    @include themify(
      (dark: (
        color: $white-08,
        border-color: $ux_border_color,
        background-color: $white-01
      )),
      (light: (
        color: $black-08,
        border-color: $black-02,
        background-color: $black-01
      ))
    );

    > header {
      h4 {
        font-size: 2.4rem;
      }
    }
    > section {
      h4 {
        position: relative;
        z-index: 999;
        margin: 0 0 0.4rem;
        font-size: 1.2rem;
        line-height: normal;
      }
    }
    &.activate,
    &.reset {
      > header {
        margin-bottom: 2rem;
      }
      > footer {
        padding: 2rem 0 2.2rem;
      }
    }
    &.forgot {
      > footer {
        padding: 0 0 2.2rem;
        text-align: right;
      }
    }

    input[type=email] {
      background-image: none !important;
    }
  }
}
