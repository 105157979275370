@mixin input-field {
  input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]), textarea { @content; }
}

@mixin empty-button() {
  cursor: pointer;
  min-width: unset;
  margin: 0;
  padding: 0;
  background: none;
  text-transform: none;
  @include border(0 none transparent, false);
  @include border-radius($form_element_border_radius);
}

@mixin icon-button {
	button {
    @include empty-button();
    &:before {
      @extend .icon;
      width: inherit;
      height: inherit;
      color: inherit;
      text-align: center;
    }
    @content;
  }
}

@mixin icon-button-direct {
	> button {
    @include empty-button();
    &:before {
      @extend .icon;
      width: inherit;
      height: inherit;
      color: inherit;
      text-align: center;
    }
    @content;
  }
}

@mixin input-placeholder {
  input:-moz-placeholder { @content; }
  input::-moz-placeholder { @content; }
  input:-ms-input-placeholder { @content; }
  input::-webkit-input-placeholder { @content; }
}
