%date_range_picker {
  position: relative;
  margin-bottom: 0;

  > div {
    position: absolute;
    left: 0.5rem;
    z-index: -1;
    width: auto;

    @include transitions(padding, opacity, background-color, border-color);
    @include border(1px solid, false);
    @include border-radius($form_element_border_radius);
    @include boxshadow(0.5rem 0.5rem 1rem 0 $black-08);

    .form-group {
      padding: 0;
      margin-bottom: 1rem;
    }
    .form-group-inline > .form-group {
      &:last-child {
        margin-right: 0;
      }
    }

    > .btn {
      position: absolute;
      top: 1.1rem;
      right: 1.1rem;
      bottom: 1.1rem;
      padding: 0 0.5rem !important;
      margin-bottom: 0 !important;

      @include transitions(opacity, background-color, border-color);

      &:before {
        @extend .icon;
        content: $icon-check;
      }

      &:disabled {
        @include themify(
          (dark: (
            background-color: $white-01
          )),
          (light: (
            background-color: $black-01
          ))
        );
      }

      &:not(:disabled) {
        border-color: $ux_primary_color !important;
        background-color: $ux_primary_color !important;

        &:hover {
          border-color: $ux_primary_dark_color !important;
          background-color: $ux_primary_dark_color !important;
        }
      }
    }
  }

  &:not(.labelled) {
    > div {
      top: 4rem;
    }
  }

  &.labelled {
    > div {
      top: 5.8rem;
    }
  }

  &:not(.active) {
    > div {
      padding: 0;
      opacity: 0;
      @include no-select();
      pointer-events: none;
      background-color: transparent;
      border-color: transparent;
      > .btn {
        opacity: 0;
      }
    }
  }

  &.active {
    > div {
      z-index: 999;
      padding: 1rem 4.3rem 0 1rem;
      opacity: 1;

      @include themify(
        (dark: (
          border-color: $ux_border_color,
          background-color: $dark-footer-background-color
        )),
        (light: (
          border-color: $black-01,
          background-color: $light-footer-background-color
        ))
      );

      > .btn {
        opacity: 1;
      }
    }
  }
}

.date-range-picker {
  @extend %date_range_picker;
}

.date-time-range-picker {
  @extend %date_range_picker;

  > div {
    .form-group-inline {
      white-space: nowrap;
      > .form-group {
        margin-left: 0;
      }
    }
  }
}
