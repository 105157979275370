%main_aside_desktop_base {
  @include mq(desktop) {
    > nav {
      width: $site_main_nav_width;

      > mask,
      > div.mask {
        display: none;
      }

      ul {
        > li > ol > li {
          > a {
            padding-left: 5rem;
          }
          > ol > li {
            > a {
              padding-left: 7rem;
            }
            > ol > li > a {
              padding-left: 9rem;
            }
          }
        }
      }

      ol {
        > li {
          > a {
            @include transform-origin(top);
            @include transition-property(height opacity padding background-color);
            @include transition-duration(240ms);
            @include transition-delay(0s);
            @include transition-timing-function(linear);
          }
        }
      }

      a {
        &:not(.expanded) {
          ~ ol {
            > li {
              > a {
                height: 0;
                opacity: 0;
                padding-top: 0;
                padding-bottom: 0;
                @include animation(do-vertical-collapse 250ms linear forwards);
              }
            }
          }
        }

        &.expanded {
          @include themify(
            (dark: (
              color: $white-08
            )),
            (light: (
              color: $black-08
            ))
          );

          border-left-color: $ux_primary_color_40;

          ~ ol {
            > li {
              > a {
                height: $site_nav_anchor_height;
                opacity: 1;
                padding-top: $site_nav_anchor_padding;
                padding-bottom: $site_nav_anchor_padding;
                @include animation(do-vertical-expand 250ms linear forwards);
              }
            }
          }
        }

        &.expandable {
          &:after {
            @include iconz();
            content: $icon-angle-left;
            position: absolute;
            top: $site_nav_anchor_padding + 0.3rem;
            right: 2rem;
            @include transitions(transform);
          }

          &.expanded {
            &:after {
              @include transform(rotate(-90deg));
            }
          }
        }
      }
    }

    + section {
      text-align: left;

      > header {
        max-width: unset;
        margin-right: 0;
        margin-left: 0;
      }

      > *:not(header) {
        display: block;
        max-width: 111.2rem;
        margin-left: 3rem !important;
      }
    }
  }
}
