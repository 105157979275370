%header_nav_menu_spans_anchors {
  > span, > a {
    display: block;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }

  > a {
    font-size: $site_base_font_size;
    font-weight: 300;

    @include themify(
      (dark: (
        color: $ux_normal_text_color
      )),
      (light: (
        color: #8c8c8c
      ))
    );

    &:before {
      @include iconz();
      margin-right: 0.5rem;
    }

    &:hover {
      @include themify(
        (dark: (
          color: $white-1,
          background-color: $ux_accent_base_color
        )),
        (light: (
          color: $black-08,
          background-color: #dfdfdf
        ))
      );
    }
  }
}
