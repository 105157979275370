label {
  &.input-label {
    &.select {
      @extend %select_base;
    }
    &.multiple {
      @extend %select_base;
    }
  }
}

%select_button_auto_width {
  display: block;
  max-width: calc(100% - 3.3rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%select_widths_lists {
  dl {
    dt {
      > button {
        > span {
          @extend %select_button_auto_width;
        }
      }
    }
    dd {
      ul {
        > li {
          > button {
            > span {
              @extend %select_button_auto_width;
            }
            &:hover {
              > span {
                overflow: unset !important;
                text-overflow: unset !important;
              }
            }
          }
        }
      }
      ol {
        > li {
          > button {
            > span {
              @extend %select_button_auto_width;
            }
          }
        }
      }
    }
  }
}

%select_base {
  select {
    @extend %dim_base;
    > option { @extend %dim; }
  }

  dl {
    position: relative;
    z-index: 0;
    display: block;
    height: $form_element_size;
    min-width: 10rem;
    margin: 0;
    padding: 0;
    pointer-events: none;
    @include no-select();

    @include y-scale-selector("dd.options", 300ms);
    @include y-scale-selector-opposite-hover("dd.selected", 300ms);
    @include y-scale-selector(ul, 400ms);
    @include y-scale-selector(label, 250ms);

    > dt, > dd {
      margin: 0;
      padding: 0;
      @include auto-select();
      pointer-events: initial;
    }

    @extend %menu_selector;
    @extend %menu_base;
    @extend %menu_selected;
    @extend %menu_options;
  }

  &[class*="width-"] {
    @extend %select_widths_lists;
  }

  &.auto-width {
    @extend %select_widths_lists;
  }
}

%menu_selected {
  > dd.selected {
    z-index: 2;
    @extend %menu_list_selected;
  }
}

%menu_options {
  > dd.options {
    @extend %menu_list_base;
    @extend %menu_list_filter;
    @extend %menu_list_options;
  }
}

%menu_base {
  > dd {
    position: relative;
    z-index: 1;
    @include border(1px solid, false);
    @include border-radius(0 0 $form_element_border_radius $form_element_border_radius);
    @include box-shadow-themed(0.5rem 0.5rem 0.5rem 0 $black-03, 0.5rem 0.5rem 0.5rem 0 $black-01);
    border-top-width: 0;
    border-top-style: none;
    @include themify(
      (dark: (
        border-color: $ux_border_color,
        background-color: $dark-form-input-background-color
      )),
      (light: (
        border-color: $black-03,
        background-color: $light-form-input-background-color
      ))
    );
  }
}

%menu_list_base {
  > label {
    display: block;
    margin: 0;
    padding: 0;
    > button {
      @extend %menu_button_base;
    }
  }
}

%menu_list_filter {
  label.filter {
    input {
      width: 100% !important;
      min-width: unset;
      border: 0 none transparent !important;
      @include border-radius(0 !important);
    }
  }
}

%menu_list_selected {
  > ol {
    max-height: 20rem;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-style: solid none none;
    border-width: 1px 0 0;
    border-color: transparent;
    @include themify(
      (dark: (
        border-top-color: $white-02,
      )),
      (light: (
        border-top-color: $black-02
      ))
    );

    > li {
      margin: 0;
      padding: 0;
      &:first-child {
        > button {
          border-top-width: 0;
          border-top-style: none;
        }
      }
      > button {
        @extend %menu_button_base;

        &:before {
          @extend .icon;
          content: $icon-minus-circle;
          position: absolute;
          top: 1.1rem;
          right: 1rem;
          @include transitions(color);
        }
        &:not(:hover) {
          &:before {
            @include themify(
              (dark: (
                color: $ux_dim_text_color
              )),
              (light: (
                color: $black-04
              ))
            );
          }
        }
        &:hover {
          &:not(:disabled) {
            &:before {
              color: $ux_danger_color;
            }
          }
        }
      }
    }
  }
}

%menu_list_options {
  top: 0;
  > ul {
    max-height: 19rem;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;

    @include themify(
      (dark: (
        border-top-color: $white-02,
      )),
      (light: (
        border-top-color: $black-02
      ))
    );

    > li {
      margin: 0;
      padding: 0;
      overflow: hidden;
      > button {
        @extend %menu_button_base;
      }

      &:first-child {
        > button {
          border-top: 0 none transparent;
        }
      }
    }
  }

  .filter + ul {
    max-height: 17rem;
  }

  .all-or-none {
    border-width: 0 0 1px;
    border-style: none none solid;

    @include themify(
      (dark: (
        border-color: $ux_border_color
      )),
      (light: (
        border-color: $black-02
      ))
    );
  }
  
  .all-or-none + ul {
    max-height: 17rem;
  }
  .filter + .all-or-none + ul {
    max-height: 15rem;
  }
}

%menu_button_base {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  height: $form_element_size;
  margin: 0;
  padding: 0 1rem 0 1.2rem;
  font-size: $form_element_font_size;
  line-height: normal;
  text-align: left;
  border-style: solid none none;
  border-width: 1px 0 0;
  border-color: transparent;
  white-space: nowrap;
  &:not(.selected) {
    @include themify(
      (dark: (
        color: $white-06,
        border-top-color: $white-02,
        background-color: $ux_page_base_color,
      )),
      (light: (
        color: $black-06,
        border-top-color: $black-02,
        background-color: $black-005
      ))
    );
    &:hover {
      @include themify(
        (dark: (
          color: $white-09,
          border-top-color: $white-03,
          background-color: $ux_accent_base_color
        )),
        (light: (
          color: $black-09,
          border-top-color: $black-03,
          background-color: $black-01
        ))
      );
    }
  }

  &.selected {
    &:not(:disabled) {
      color: $white-1;
      background-color: $ux_primary_color;
    }
  }
}

%dim_base {
  visibility: hidden;
  position: absolute;
  top: -1rem;
  left: -1rem;
  @include no-select();
  pointer-events: none;
  @extend %dim;
}

%dim {
  display: inline;
  width: 1px !important;
  height: 1px !important;
  min-width: unset !important;
  min-height: unset !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  border: 0 none transparent !important;
  background: none !important;
  @include box-shadow(none !important);
  @include border-radius(0 !important);
}

%menu_selector {
  > dt {
    > button {
      position: relative;
      cursor: pointer;
      height: $form_element_size;
      padding: 0 0 0 1rem;
      text-align: left;
      @extend %elements_base;

      &:before, &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: $form_element_size;
        margin: 0;
        @include no-select();
        pointer-events: none;
      }

      &:before {
        content: '';
        z-index: 0;
        outline: 0 none transparent;
        background-color: transparent;
        border-width: 0 0 0 0.1rem;
        border-style: none none none solid;
        @include transitions(border-color, background-color);

        @include themify(
          (dark: (
            border-color: $ux_border_color
          )),
          (light: (
            border-color: $black-02
          ))
        );
      }

      &:after {
        @extend .icon;
        content: $icon-angle-left;
        z-index: 1;
        padding: 0.7rem 0 0;
        font-size: 1.6rem;
        text-align: center;
        line-height: normal;
        @include transform(rotate(0deg));
        @include transitions(transform, color);

        @include themify(
          (dark: (
            color: $white-05
          )),
          (light: (
            color: $black-05
          ))
        );
      }

      &:hover {
        &:before {
          background-color: $ux_primary_dark_color;
          @include themify(
            (dark: (
              border-color: $ux_border_color
            )),
            (light: (
              border-color: $black-04
            ))
          );
        }
        &:after {
          @include themify(
            (dark: (
              color: $white-08
            )),
            (light: (
              color: $black-08
            ))
          );
        }
      }
    }
  }

  &.active {
    > dt {
      > button {
        @include border-radius($form_element_border_radius $form_element_border_radius 0 0);

        &:before {
          @include themify(
            (dark: (
              border-color: $ux_border_color,
              background-color: $ux_accent_base_color,
            )),
            (light: (
              border-color: $black-04,
              background-color: $black-005
            ))
          );
        }
        &:after {
          @include transform(rotate(-90deg));
        }
      }
    }
  }
}
